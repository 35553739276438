import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { FilterMenuGroup } from '../../models/filter-menu-group.model';
import { FilterMenuGroupOption } from '../../models/filter-menu-group-option.model';
import { MatAccordion } from '@angular/material/expansion';

@Component({
  selector: 'app-filter-menu',
  templateUrl: './filter-menu.component.html',
  styleUrls: ['./filter-menu.component.scss'],
})
export class FilterMenuComponent {
  @Output() applyFilters = new EventEmitter<FilterMenuGroup[]>();
  @Input() trigger!: MatMenuTrigger;
  @Input() filterGroups!: FilterMenuGroup[];

  @ViewChild(MatAccordion, { static: false }) accordion!: MatAccordion;

  groupsFiltersSelectedCount?: Map<string, number>;
  filtersSelectedCount: number = 0;

  filterOptionToggle(option: FilterMenuGroupOption) {
    option.selected = !option.selected;
    this.buildFiltersCounters();
  }

  applyFiltersClick() {
    const groupsFiltersWithSelection = this.filterGroups.filter(
      (group) => group.options.filter((option) => option.selected).length > 0
    );
    this.applyFilters.emit(groupsFiltersWithSelection);
    this.close();
  }

  clearFilter() {
    this.filterGroups.forEach((group) => {
      group.options.forEach((option) => (option.selected = false));
    });
    this.buildFiltersCounters();
    this.applyFiltersClick();
  }

  close() {
    this.trigger.closeMenu();
  }

  closeAllAccordions() {
    this.accordion.closeAll();
  }

  private buildFiltersCounters() {
    if (!this.filterGroups) {
      return;
    }

    this.filtersSelectedCount = 0;
    this.groupsFiltersSelectedCount = new Map<string, number>();

    this.filterGroups.forEach((filterGroup) => {
      const groupFilterSelectedCount = filterGroup.options.filter(
        (option) => option.selected
      ).length;
      this.groupsFiltersSelectedCount!.set(
        filterGroup.title,
        groupFilterSelectedCount
      );
      this.filtersSelectedCount += groupFilterSelectedCount;
    });
  }
}
