import { Component, Input } from '@angular/core';
import { FormControlState } from 'ngrx-forms';

@Component({
  selector: 'app-input-autocomplete',
  templateUrl: './input-autocomplete.component.html',
  styleUrls: ['./input-autocomplete.component.scss'],
})
export class InputAutocompleteComponent {
  @Input() label!: string;
  @Input() id!: string;
  @Input() isRequired: boolean = false;
  @Input() placeholder: string = '';
  @Input() info!: string;
  @Input() maxLength!: number;
  @Input() autoCompleteOptions!: string[];

  @Input('form-control-state') set formControlState(
    formStateControl: FormControlState<any>
  ) {
    this.formStateControl = formStateControl;
    this.filterOptions(formStateControl.value);
  }

  formStateControl!: FormControlState<any>;
  filteredOptions!: string[];

  private filterOptions(value: any) {
    const filterValue = (value ?? '').toLowerCase();
    this.filteredOptions = this.autoCompleteOptions.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }
}
