const defaultStopWords = [
  '-',
  '&',
  '/',
  '.',
  'a',
  'about',
  'actually',
  'almost',
  'also',
  'although',
  'always',
  'am',
  'an',
  'and',
  'any',
  'are',
  'as',
  'at',
  'be',
  'became',
  'become',
  'but',
  'by',
  'can',
  'could',
  'did',
  'do',
  'does',
  'each',
  'either',
  'else',
  'for',
  'from',
  'had',
  'has',
  'have',
  'hence',
  'how',
  'i',
  'if',
  'in',
  'is',
  'it',
  'its',
  'just',
  'may',
  'maybe',
  'me',
  'might',
  'mine',
  'must',
  'my',
  'neither',
  'nor',
  'not',
  'of',
  'oh',
  'ok',
  'the',
  'when',
  'whenever',
  'where',
  'whereas',
  'wherever',
  'whether',
  'which',
  'while',
  'who',
  'whoever',
  'whom',
  'whose',
  'why',
  'will',
  'with',
  'within',
  'without',
  'would',
  'yes',
  'yet',
  'you',
  'your',
];

const defaultFilterOutCharacters = [',', '(', ')', ';'];

export function stopWordsFilter(
  value: string,
  separator: string = ' ',
  stopWords: string[] = defaultStopWords,
  filterOutChars: string[] = defaultFilterOutCharacters
): string[] {
  const filteredValue = value
    .split(separator)
    .map((x) =>
      [...x]
        .filter((char) => !filterOutChars.includes(char))
        .join('')
        .trim()
    )
    .filter((tag) => tag !== '' && !stopWords.includes(tag.toLowerCase()));

  return filteredValue;
}
