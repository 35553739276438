import { Component, Inject } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { ColDef } from 'ag-grid-community';
import { Observable } from 'rxjs/internal/Observable';
import { map, take } from 'rxjs/operators';
import { ExportableColDefWithTitle } from 'src/app/shared/ag-grid-config/store/ag-grid-config/ag-grid-config.reducer';
import { AgGridCheckboxRendererComponent } from 'src/app/shared/components/ag-grid-checkbox-renderer/ag-grid-checkbox-renderer.component';
import {
  AgGridCheckboxRendererActions,
  AgGridCheckboxRendererModel,
} from 'src/app/shared/models/ag-grid-checkbox-renderer.model';
import {
  IDADeliverableBaseViewModel,
  IProjectDeliverableViewModel,
  ProjectDADeliverableOneOnOneMappingViewModel,
} from 'src/app/shared/models/autogenerated';
import {
  AdapCellClassRules,
  AdapColumnTypes,
} from 'src/app/shared/utils/ag-grid-utils';
import { nameof } from 'src/app/shared/utils/nameof';
import { DaAndProjectDeliverablesMatched } from '../../models/deliverable-manager-overview.model';
import { createProjectDaDeliverableOneOnOneMapping } from '../../store/screening.actions';
import { ScreeningState } from '../../store/screening.reducer';

@Component({
  selector: 'one-on-one-match',
  templateUrl: './one-on-one-match.component.html',
  styleUrls: ['./one-on-one-match.component.scss'],
})
export class OneOnOneMatchComponent {
  configStoreTableName: string = 'matchedDeliverables';
  configStoreRouteIdName: string = 'phaseId';
  oneOnOneMatches$: Observable<any[]>;
  adapColumnTypes = AdapColumnTypes;

  components = {
    agGridCheckbox: AgGridCheckboxRendererComponent,
  };

  get noneSelected(): boolean {
    return (this.selectedMappings?.size ?? 0) < 1;
  }

  private selectedMappings: Map<
    string,
    ProjectDADeliverableOneOnOneMappingViewModel
  > = new Map();
  private checkboxActions: AgGridCheckboxRendererActions[] = [];
  private headerCheckboxActions?: AgGridCheckboxRendererActions = undefined;
  private totalCount: number = 0;

  defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    filterParams: { newRowsAction: 'keep' },
    resizable: false,
    cellClassRules: AdapCellClassRules,
  };

  columnDefs: ExportableColDefWithTitle[] = [
    {
      headerComponent: 'agGridCheckbox',
      headerComponentParams: {
        onInit: this.onInitHeaderCheckbox.bind(this),
        onCheckChanged: this.onHeaderSelectionChanged.bind(this),
      },
      cellRenderer: 'agGridCheckbox',
      cellRendererParams: {
        onInit: this.onInitCheckbox.bind(this),
        onCheckChanged: this.onSelectionChanged.bind(this),
      } as AgGridCheckboxRendererModel<ProjectDADeliverableOneOnOneMappingViewModel>,
      minWidth: 56,
      maxWidth: 56,
      resizable: false,
      type: 'centerAligned',
      pinned: 'left',
      ignoreExport: true,
      lockVisible: true,
    },
    {
      field: nameof<IDADeliverableBaseViewModel>('title'),
      headerName: 'Title',
      resizable: true,
      tooltipField: nameof<IDADeliverableBaseViewModel>('title'),
      pinned: 'left',
    },
    {
      field: nameof<IDADeliverableBaseViewModel>('docType'),
      headerName: '[DA] Type',
      resizable: true,
      tooltipField: nameof<IDADeliverableBaseViewModel>('docType'),
    },
    {
      field: nameof<IDADeliverableBaseViewModel>('deliverableDiscipline'),
      headerName: '[DA] Discipline',
      resizable: true,
      tooltipField: nameof<IDADeliverableBaseViewModel>(
        'deliverableDiscipline'
      ),
    },
    {
      field: nameof<IProjectDeliverableViewModel>('type'),
      headerName: '[PROJECT] Type',
      resizable: true,
      tooltipField: nameof<IProjectDeliverableViewModel>('type'),
    },
    {
      field: nameof<IProjectDeliverableViewModel>('discipline'),
      headerName: '[PROJECT] Discipline',
      resizable: true,
      tooltipField: nameof<IProjectDeliverableViewModel>('discipline'),
    },
  ];
  constructor(
    private store: Store<ScreeningState>,
    private dialogRef: MatDialogRef<OneOnOneMatchComponent>,
    @Inject(MAT_DIALOG_DATA)
    data: { oneOnOneMatches$: Observable<DaAndProjectDeliverablesMatched[]> }
  ) {
    this.oneOnOneMatches$ = data.oneOnOneMatches$.pipe(
      map((x) => {
        this.totalCount = x?.length ?? 0;
        return (
          x?.map((val) => {
            return {
              projectDeliverableId: val.projectDeliverable.id,
              daDeliverableId: val.daDeliverable.id,
              ...(val.projectDeliverable as Omit<
                IProjectDeliverableViewModel,
                'id'
              >),
              ...(val.daDeliverable as Omit<IDADeliverableBaseViewModel, 'id'>),
            };
          }) ?? []
        );
      })
    );
  }

  closeWindow(): void {
    this.dialogRef.close();
  }

  save() {
    this.store.dispatch(
      createProjectDaDeliverableOneOnOneMapping({
        oneOnOneMappings: [...this.selectedMappings.values()],
      })
    );
    this.dialogRef.close();
  }

  private onInitHeaderCheckbox(
    data: ProjectDADeliverableOneOnOneMappingViewModel,
    actions: AgGridCheckboxRendererActions
  ) {
    actions.setChecked(true);
    this.headerCheckboxActions = actions;
  }

  private onHeaderSelectionChanged(
    isChecked: boolean,
    data: ProjectDADeliverableOneOnOneMappingViewModel,
    actions: AgGridCheckboxRendererActions
  ) {
    this.oneOnOneMatches$.pipe(take(1)).subscribe((x) => {
      x.forEach((matchData: ProjectDADeliverableOneOnOneMappingViewModel) => {
        this.onSelectionChanged(isChecked, matchData);
      });
      this.checkboxActions.forEach((checkBoxAction) =>
        checkBoxAction.setChecked(isChecked)
      );
    });
  }

  private onInitCheckbox(
    data: ProjectDADeliverableOneOnOneMappingViewModel,
    actions: AgGridCheckboxRendererActions
  ) {
    this.checkboxActions.push(actions);
    actions.setChecked(true);
    this.onSelectionChanged(true, data, actions);
  }

  private onSelectionChanged(
    isChecked: boolean,
    data: ProjectDADeliverableOneOnOneMappingViewModel,
    actions?: AgGridCheckboxRendererActions
  ) {
    const selectionId = `${data.projectDeliverableId}${data.daDeliverableId}`;

    if (isChecked) {
      this.selectedMappings.set(selectionId, data);
    } else {
      if (this.selectedMappings.has(selectionId)) {
        this.selectedMappings.delete(selectionId);
      }
    }

    this.headerCheckboxActions?.setChecked(
      this.selectedMappings.size === this.totalCount
    );
  }
}
