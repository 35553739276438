import { Component } from '@angular/core';
import {
  AgGridIconButtonRendererActions,
  AgGridIconButtonRendererModel,
  AgGridIconButtonRendererProperties,
} from '../../models/ag-grid-icon-button-renderer.model';

@Component({
  selector: 'ag-grid-icon-button-renderer',
  templateUrl: './ag-grid-icon-button-renderer.component.html',
  styleUrls: ['./ag-grid-icon-button-renderer.component.scss'],
})
export class AgGridIconButtonRenderer {
  props!: AgGridIconButtonRendererProperties<any>;
  data: any;
  private actions: AgGridIconButtonRendererActions;
  constructor() {
    this.actions = {
      setProps: (props) => (this.props = props),
    };
  }

  agInit(params: AgGridIconButtonRendererModel): void {
    this.data = params.data;
    params.onInit(params.data, this.actions);
  }
}
