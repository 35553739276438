export interface ResponseWithExpirationDate {
  response: Response;
  expirationDate: number;
}

export abstract class IRequestCacheService {
  // Expiration default to 12 hours
  public static readonly EXPIRATION_DATE_HEADER = 'EXPIRATION_DATE';
  public static readonly DEFAULT_EXPIRATION_TIME_MIN = 720;

  /**
   *
   * @param request Returns, if exists the cached request
   * @param cacheName
   */
  abstract get(request: RequestInfo): Promise<Response | undefined>;

  /**
   *
   * @param request Adds or updates an existing request response to the cache
   * @param response
   * @param cacheName
   * @param expirationInMin
   */
  abstract put(
    request: RequestInfo,
    response: Response,
    expirationDate: number
  ): Promise<void>;

  /**
   *
   * @param request Removes, if existing, the cached-request-response
   * @param cacheName
   * @param expirationInMin
   */
  abstract delete(request: RequestInfo): Promise<void>;
}
