import { Directive, forwardRef } from '@angular/core';
import { FormViewAdapter, NGRX_FORM_VIEW_ADAPTER } from 'ngrx-forms';
import { MultiUsersSearchComponent } from '../components/multi-users-search/multi-users-search.component';

@Directive({
  selector: 'app-multi-users-search[ngrxFormControlState]',
  providers: [
    {
      provide: NGRX_FORM_VIEW_ADAPTER,
      useExisting: forwardRef(() => MultiUsersSearchAdapterDirective),
      multi: true,
    },
  ],
})
export class MultiUsersSearchAdapterDirective implements FormViewAdapter {
  constructor(private multiUsersSearchComponent: MultiUsersSearchComponent) {}

  setViewValue(value: any): void {
    if (value) {
      this.multiUsersSearchComponent.setValue(value);
    }
  }

  setOnChangeCallback(fn: (value: any) => void): void {
    this.multiUsersSearchComponent.selectionChanged.subscribe((usersIds) =>
      fn(usersIds)
    );
  }

  setOnTouchedCallback(fn: any): void {}

  setIsDisabled(isDisabled: boolean) {
    // we handle this in the component itself because of expression changed error
  }
}
