<ag-grid-angular
  columnHoverHighlight
  rowHoverHighlight
  class="ag-theme-adap documents-table"
  [components]="components"
  [columnDefs]="columnDefs"
  [defaultColDef]="defaultColDef"
  [rowData]="rows"
  [animateRows]="true"
  domLayout="normal"
  rowClass="ag-no-border"
  configStore
  [configStoreTableName]="configStoreTableName"
  [configStoreRouteIdName]="configStoreRouteIdName"
  columnSizeStrategy="sizeColumnsToFit"
  suppressScrollOnNewData
  columnSizeStrategy="sizeColumnsToFit"
>
</ag-grid-angular>
