<ng-container *ngIf="IPIMSDomain; else noDomainSelected">
  <div class="documents-quick-filter">
    <div class="quick-filter-with-results">
      <app-input-filter
        class="quick-filter-search"
        (filterChanged)="search($event)"
        [placeholder]="placeholder"
      >
      </app-input-filter>
      <span> ({{ resultsCount }}) results</span>
    </div>
    <div class="quick-filter-selection">
      <span> ({{ selection.size }}) selected</span>
      <button
        [disabled]="selection.size < 1"
        class="adap-btn-outline"
        (click)="clearSelection()"
      >
        clear
      </button>
    </div>
  </div>

  <div class="results-sub-header">
    <span class="results-sub-header-text"
      >results ( domain: {{ IPIMSDomain }} )</span
    >
  </div>
  <span class="limit-reached" *ngIf="resultsCount! > resultLimit!">
    Search is not specific enough, results exceed allowed limit:
    {{ resultLimit }}
  </span>

  <div class="table-container">
    <ng-container *ngIf="rows">
      <app-ag-grid-options
        class="grid-options"
        [showQuickFilter]="false"
        [configStoreTableName]="observationDocumentsLoad"
        [configStoreRouteIdName]="projectDeliverableId"
      ></app-ag-grid-options>

      <app-ipims-common-table
        class="table-component"
        [columnDefs]="IPIMsDocumentsColumns"
        [rows]="rows"
        [configStoreTableName]="observationDocumentsLoad"
        [configStoreRouteIdName]="projectDeliverableId"
        (rowsRendered)="onRowsRendered()"
      ></app-ipims-common-table>
      <mat-progress-spinner
        *ngIf="searchSpinner"
        class="search-spinner"
        diameter="50"
        mode="indeterminate"
      >
      </mat-progress-spinner>
    </ng-container>
  </div>
</ng-container>

<ng-template #noDomainSelected>
  <app-placeholder
    class="placeholder"
    icon="assets/images/no-systems.svg"
    title="no domain for document search, please choose a domain"
  ></app-placeholder
></ng-template>
