import { Constants } from './constants';

const hexRegex = /^[0-9A-Fa-f]{8}/;
export function isUUIDValid(
  UUID: string | null,
  isDefaultable: boolean = false
): boolean {
  if (
    UUID == null ||
    UUID === '' ||
    (!isDefaultable && UUID === Constants.GUID_DEFAULT_VALUE)
  ) {
    return false;
  }

  const parts = UUID.split('-').filter((x) => x !== '' && x !== undefined);
  if (parts.length === 0) {
    return false;
  }
  const regex = new RegExp(hexRegex);
  // matching the first element to check for UUID format should be enough
  // https://en.wikipedia.org/wiki/Universally_unique_identifier format section
  return regex.test(parts[0]);
}
