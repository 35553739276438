import { Pipe, PipeTransform } from '@angular/core';
import { UserRolesDisplayLabel } from '../utils/constants';

@Pipe({
  name: 'userRoleDictionary',
})
export class UserRoleDictionaryPipe implements PipeTransform {
  transform(value: string): any {
    return UserRolesDisplayLabel.has(value)
      ? UserRolesDisplayLabel.get(value)
      : value;
  }
}
