<app-input-container
  class="editable-text-container"
  [label]="editableTextLabel"
  [isRequired]="isRequired"
  [characterCounterLength]="editableTextFormControl?.value?.length ?? 0"
  [characterCounterMaxLength]="editableTextMaxLength"
  [app-input-validation-container]="editableTextFormControl"
>
  <textarea
    [style.height]="textAreaHeight"
    id="description"
    name="description"
    type="text"
    class="adap-form-textarea text-area-container"
    [ngrxFormControlState]="editableTextFormControl"
  >
  </textarea>

  <button
    *ngIf="showCopyLabel"
    type="button"
    (click)="copyEvent.emit()"
    class="adap-btn-blank copy-button"
    [disabled]="editableTextFormControl?.isDisabled"
  >
    {{ copyLabel }}
    <mat-icon class="copy-icon">content_copy</mat-icon>
  </button>
</app-input-container>
