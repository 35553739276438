import { Injectable } from '@angular/core';
import { CsvExportParams } from 'ag-grid-community';
import { Observable, Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ColumnSizeStrategy } from 'src/app/shared/ag-grid-config/ag-grid-config.constants';

export abstract class ITableEventsService {
  public static COLUMN_SIZE: string = '-column-size';
  public static EXPORT_CSV: string = '-export-csv';
  abstract exportToCsv$(tableName: string): Observable<CsvExportParams>;
  abstract publishExportToCsv(
    tableName: string,
    exportParams: CsvExportParams
  ): void;
  abstract columnResizeStrategy$(
    tableName: string
  ): Observable<ColumnSizeStrategy>;
  abstract publishColumnResizeStrategy(
    tableName: string,
    resizeStrategy: ColumnSizeStrategy
  ): void;
}

@Injectable()
export class TableEventsService implements ITableEventsService {
  private observables: Map<string, Subject<any>> = new Map();

  exportToCsv$(tableName: string): Observable<CsvExportParams> {
    return this.getObservable(
      tableName + ITableEventsService.EXPORT_CSV
    ).asObservable();
  }

  publishExportToCsv(tableName: string, exportParams: CsvExportParams): void {
    let obs = this.getObservable(tableName + ITableEventsService.EXPORT_CSV);
    obs.next(exportParams);
  }

  columnResizeStrategy$(tableName: string): Observable<ColumnSizeStrategy> {
    return this.getObservable(
      tableName + ITableEventsService.COLUMN_SIZE
    ).asObservable();
  }

  publishColumnResizeStrategy(
    tableName: string,
    resizeStrategy: ColumnSizeStrategy
  ): void {
    let obs = this.getObservable(tableName + ITableEventsService.COLUMN_SIZE);
    obs.next(resizeStrategy);
  }

  private getObservable(obsId: string): Subject<any> {
    let obs = this.observables.get(obsId);

    if (obs !== undefined) {
      return obs;
    }

    return this.createAndReturnObservable(obsId);
  }

  private createAndReturnObservable(obsId: string): Subject<any> {
    const subj = new Subject<any>().pipe(
      finalize(() => {
        if ((subj as any).destination.observers.length <= 1) {
          subj.unsubscribe();
          this.observables.delete(obsId);
        }
      })
    ) as Subject<any>;
    this.observables.set(obsId, subj);
    return subj;
  }
}
