<app-input-container
  [label]="label"
  [labelFor]="id"
  [isRequired]="isRequired"
  [app-input-validation-container]="formStateControl"
>
  <input
    [id]="id"
    [name]="id"
    type="text"
    [placeholder]="placeholder"
    class="adap-form-input"
    [maxLength]="maxLength"
    [ngrxFormControlState]="formStateControl"
    [matAutocomplete]="auto"
  />
  <mat-autocomplete #auto="matAutocomplete">
    <mat-option *ngFor="let option of filteredOptions" [value]="option">
      {{ option }}
    </mat-option>
  </mat-autocomplete>
</app-input-container>
