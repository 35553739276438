<ng-container *ngIf="tableConfig$ | async as tableConfig">
  <ng-container *ngIf="selectedColumns$ | async as selectedColumns">
    <mat-form-field class="adap-select" appearance="outline">
      <mat-select
        multiple
        placeholder="show/hide columns"
        [value]="selectedColumns"
        (valueChange)="selectedColumnsChange($event)"
        [disabled]="tableConfig?.allColumnsFrozen"
      >
        <mat-select-trigger>show/hide columns</mat-select-trigger>
        <mat-option
          (click)="selectAll(tableConfig.columnDefs ?? [])"
          [value]="allSelectedValue"
        >
          select all
        </mat-option>
        <ng-container *ngFor="let column of tableConfig.columnDefs">
          <mat-option
            *ngIf="shouldDisplayOption(column)"
            [value]="column.colId"
            >{{ column.columnTitle }}</mat-option
          >
        </ng-container>
      </mat-select>
    </mat-form-field>
  </ng-container>
</ng-container>
