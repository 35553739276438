import { createAction, props } from '@ngrx/store';

export const noopAction = createAction('[Shared] Noop action');

export const loadingStarted = createAction('[Shared] Loading Started');

export const loadingFinished = createAction('[Shared] Loading Finished');

export const showAuthorizationErrorPage = createAction(
  '[Error] Auth Error',
  props<{ error: any; errorMessage: string }>()
);

export const killLoadingSpinner = createAction(
  '[Shared] Killing the loading spinner'
);

export const loadApplicationVersion = createAction(
  '[Shared] Load application version'
);

export const setApplicationVersion = createAction(
  '[Shared] Set application version',
  props<{ currentVersion: string }>()
);

export const cacheClearFlag = createAction(
  '[Shared] Cache clear finished',
  props<{ cacheClearFlag: boolean }>()
);
