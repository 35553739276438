import { Component } from '@angular/core';
import { SMEDisciplineViewModel } from '../../models/autogenerated';

@Component({
  selector: 'ag-grid-chip-discipline-renderer',
  templateUrl: './ag-grid-chip-discipline-renderer.component.html',
  styleUrls: ['./ag-grid-chip-discipline-renderer.component.scss'],
})
export class AgGridDisciplineChipRendererComponent {
  smeDisciplines!: SMEDisciplineViewModel[];

  agInit(params: { smeDisciplines: SMEDisciplineViewModel[] }) {
    this.smeDisciplines = params.smeDisciplines;
  }
}
