import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SharedModule } from '../shared.module';
import { AgGridOptionsComponent } from './components/ag-grid-options/ag-grid-options.component';
import { ColumnListDropdownComponent } from './components/column-list-dropdown/column-list-dropdown.component';
import { GridQuickFilterComponent } from './components/grid-quick-filter/grid-quick-filter.component';
import { TableActionsComponent } from './components/table-actions/table-actions.component';
import { AgGridConfigStoreDirective } from './directives/ag-grid-config-store.directive';
import { AgGridCsvExportService } from './services/ag-grid-csv-export.service';
import { AgGridConfigEffects } from './store/ag-grid-config/ag-grid-config.effects';
import {
  agGridConfigInitialState,
  agGridConfigReducer,
} from './store/ag-grid-config/ag-grid-config.reducer';

@NgModule({
  declarations: [
    AgGridConfigStoreDirective,
    AgGridOptionsComponent,
    ColumnListDropdownComponent,
    GridQuickFilterComponent,
    TableActionsComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    MatFormFieldModule,
    MatSelectModule,
    MatIconModule,
    MatTooltipModule,
    FormsModule,
    StoreModule.forFeature('agGridConfig', agGridConfigReducer, {
      initialState: agGridConfigInitialState,
    }),
    EffectsModule.forFeature([AgGridConfigEffects]),
  ],
  exports: [
    AgGridConfigStoreDirective,
    AgGridOptionsComponent,
    ColumnListDropdownComponent,
    GridQuickFilterComponent,
  ],
  providers: [AgGridCsvExportService],
})
export class AgGridConfigModule {}
