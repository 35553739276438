import { Component } from '@angular/core';
import {
  AgGridButtonRendererProperties,
  AgGridButtonRendererModel,
  AgGridButtonRendererActions,
} from '../../models/ag-grid-button-renderer.model';

@Component({
  selector: 'ag-grid-button-renderer',
  templateUrl: './ag-grid-button-renderer.component.html',
  styleUrls: ['./ag-grid-button-renderer.component.scss'],
})
export class AgGridButtonRenderer {
  props!: AgGridButtonRendererProperties;
  private actions: AgGridButtonRendererActions;
  constructor() {
    this.actions = {
      setProps: (props) => (this.props = props),
    };
  }

  agInit(params: AgGridButtonRendererModel): void {
    params.onInit(this.actions);
  }
}
