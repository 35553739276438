import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { FormControlState } from 'ngrx-forms';
import { Subject, Subscription } from 'rxjs';
import { IPIMSDomain } from 'src/app/shared/models/autogenerated';
import { openIPIMSDomainAssignDialog } from '../../utils/ipims-dialog-utils';
import { MatDialog } from '@angular/material/dialog';
import { IPIMSDomainConfig } from '../../models/ipims-dialog-configuration';
import { selectIPIMSDomains } from 'src/app/roles/da-engineer/project-dashboard/store/project.selectors';
import { loadIPIMSDomains } from 'src/app/roles/da-engineer/project-dashboard/store/project.actions';

@Component({
  selector: 'app-ipims-domain-selector-input',
  templateUrl: './ipims-domain-selector-input.component.html',
})
export class IPIMSDomainSelectorInputComponent implements OnInit {
  selectedDomain!: IPIMSDomain | null | undefined;
  domains: string[] = [];
  searchText: string = '';
  isTouched: boolean = false;
  isInvalid: boolean = false;
  isDisabled: boolean = false;

  @ViewChild(MatSelect) matSelect!: MatSelect;

  readonly setValueSubject = new Subject<IPIMSDomain>();
  readonly setValue$ = this.setValueSubject.asObservable();

  @Input()
  placeholder!: string;

  @Input() set ngrxFormControlState(state: FormControlState<any>) {
    this.isTouched = state.isTouched;
    this.isInvalid = state.isInvalid;
    this.isDisabled = state.isDisabled;
  }

  @Output()
  domainSelectionChanged = new EventEmitter<IPIMSDomain | null>();

  private subscription: Subscription = new Subscription();
  private onTouchedEmitter: Function | undefined;

  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {
    this.subscription.add(
      this.setValue$.subscribe((domain) => {
        this.selectedDomain = domain;
        this.domains = [this.selectedDomain.domain];
      })
    );
  }

  onOpen() {
    this.matSelect.close();
    openIPIMSDomainAssignDialog(this.dialog, {
      selectedDomain: this.selectedDomain,
      domainsLoadAction: loadIPIMSDomains,
      domainsSelector: selectIPIMSDomains,
      onDomainAssign: (domain) => this.domainChosen(domain),
    } as IPIMSDomainConfig);
  }

  domainChosen(domain: IPIMSDomain | null) {
    this.selectedDomain = domain;
    this.domainSelectionChanged.emit(this.selectedDomain);
    this.setValueSubject.next(domain!);
  }

  onClose() {
    this.onTouched();
  }

  registerOnTouched(fn: () => {}) {
    this.onTouchedEmitter = fn;
  }

  onTouched() {
    this.onTouchedEmitter?.();
  }
}
