export const DELIVERABLES_CLASS_ALL_TEXT = 'all deliverables';
export class DeliverableTypes {
  static readonly DA_DELIVERABLES = 'DA deliverables';
  static readonly PROJECT_DELIVERABLES = 'project deliverables';
}

export const TOTAL = 'total hours per discipline';
export const NUMBER_OF_DELIVERABLES_PER_DISCIPLINE =
  'number of deliverables per discipline';
export const NUMBER_OF_CHECKS_PER_DISCIPLINE =
  'number of checks per discipline';
export const AVERAGE_NUMBER_OF_HOURS_PER_DISCIPLINE =
  'average number of hours per deliverable';

export const TOTAL_ID = 'TOTAL';
export const NUMBER_OF_DELIVERABLES_PER_DISCIPLINE_ID =
  'NUMBER_OF_DELIVERABLES_PER_DISCIPLINE';
export const AVERAGE_NUMBER_OF_HOURS_PER_DISCIPLINE_ID =
  'AVERAGE_NUMBER_OF_HOURS_PER_DISCIPLINE';
export const NUMBER_OF_CHECKS_PER_DISCIPLINE_ID =
  'NUMBER_OF_CHECKS_PER_DISCIPLINE';

export const ADHOC_DA_DELIVERABLE_DEFINITION_MAX_LENGTH = 10000;

export class ScreeningRoutes {
  static readonly DELIVERABLES_ROUTING_PATH = 'da-deliverables';
  static readonly MASTER = 'master';
  static readonly DELIVERABLE_MANAGER = 'deliverable-manager';
  static readonly SORT_ASSIST = 'sort-assist';
  static readonly PROJECT_DELIVERABLES = 'project-deliverables';
  static readonly CHECKS = 'checks';
  static readonly OVERVIEW = 'overview';
  static readonly ESTIMATES = 'estimates';
}

export class EstimatePlanRoutes {
  static readonly DELIVERABLES = 'deliverables';
  static readonly CARRY_OVER_OBSERVATIONS = 'carry-over-observations';
  static readonly CHECKS = 'checks';
}

export const DELIVERABLE_MANAGER_TABLE_ROUTE_ID = 'phaseId';

export const DELIVERABLE_MANAGER_TABLE_DA_CONFIG_STORE =
  'deliverableMgrDADeliverables';
export const DELIVERABLE_MANAGER_TABLE_PROJECT_CONFIG_STORE =
  'deliverableMgrProjectDeliverables';

export const DELIVERABLE_MANAGER_TABLE_CHECK_CONFIG_STORE =
  'deliverableMgrChecks';
export const DELIVERABLE_MANAGER_TABLE_PROJECT_DELIVERABLE_WITH_CHECK_CONFIG_STORE =
  'deliverableMgrProjectDeliverablesWithChecks';

export const DOC_TYPES = [
  'BID',
  'CAL',
  'CEF',
  'CPO',
  'CRT',
  'DAS',
  'DBD',
  'DIA',
  'DTL',
  'EOL',
  'GAR',
  'HAZ',
  'ISO',
  'LET',
  'LOP',
  'LST',
  'MAN',
  'MAP',
  'MSD',
  'PCD',
  'PFD',
  'PID',
  'PLN',
  'PLT',
  'RPT',
  'RSK',
  'SCM',
  'SOW',
];
