<button class="options-button" [matMenuTriggerFor]="optionsMenu">
  <mat-icon>{{ buttonIcon }}</mat-icon>
</button>
<mat-menu #optionsMenu>
  <ng-container *ngFor="let option of options">
    <button
      mat-menu-item
      *ngIf="!option.hidden"
      (click)="onOptionSelect(option)"
    >
      <span class="options-menu">{{ option.text }}</span>
    </button>
  </ng-container>
</mat-menu>
