import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectRouteNestedParam } from 'src/app/shared/store/shared.selectors';
import { QuickFilterModes } from '../../ag-grid-config.constants';
import { AgGridConfigState } from './ag-grid-config.reducer';

export const selectAgGridConfigFeature =
  createFeatureSelector<AgGridConfigState>('agGridConfig');

export const selectAgGridConfigForTable = (
  tableName: string,
  routeIdName: string
) =>
  createSelector(
    selectAgGridConfigFeature,
    selectRouteNestedParam(routeIdName),
    (state, routeId) =>
      state.tableConfig[`${tableName}_${routeIdName}_${routeId ?? ''}`]
  );

export const selectAgGridConfigColumns = (
  tableName: string,
  routeIdName: string
) =>
  createSelector(
    selectAgGridConfigFeature,
    selectRouteNestedParam(routeIdName),
    (state, routeId) =>
      state.tableConfig[`${tableName}_${routeIdName}_${routeId ?? ''}`]
        ?.columnDefs!
  );

export const selectAgGridTableTouched = (
  tableName: string,
  routeIdName: string
) =>
  createSelector(
    selectAgGridConfigFeature,
    selectRouteNestedParam(routeIdName),
    (state, routeId) =>
      state.tableConfig[`${tableName}_${routeIdName}_${routeId ?? ''}`]
        ?.touched!
  );

export const selectFilterMode = (tableName: string, routeIdName: string) =>
  createSelector(
    selectAgGridConfigFeature,
    selectRouteNestedParam(routeIdName),
    (state, routeId) =>
      state.tableConfig[`${tableName}_${routeIdName}_${routeId ?? ''}`]
        ?.quickFilterMode ?? QuickFilterModes.text
  );

export const selectFilterText = (tableName: string, routeIdName: string) =>
  createSelector(
    selectAgGridConfigFeature,
    selectRouteNestedParam(routeIdName),
    (state, routeId) =>
      state.tableConfig[`${tableName}_${routeIdName}_${routeId ?? ''}`]
        ?.quickFilter
  );

export const selectFilterTags = (tableName: string, routeIdName: string) =>
  createSelector(
    selectAgGridConfigFeature,
    selectRouteNestedParam(routeIdName),
    (state, routeId) =>
      state.tableConfig[`${tableName}_${routeIdName}_${routeId ?? ''}`]
        ?.quickFilterTags ?? []
  );

export const selectAllColumnsFreeze = (
  tableName: string,
  routeIdName: string
) =>
  createSelector(
    selectAgGridConfigForTable(tableName, routeIdName),
    (tableConfig) => tableConfig?.allColumnsFrozen ?? false
  );
