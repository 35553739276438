import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap } from 'rxjs/operators';
import { ApplicationVersionClient } from 'src/app/shared/models/autogenerated';
import {
  loadApplicationVersion,
  setApplicationVersion,
} from './shared.actions';

@Injectable()
export class SharedEffects {
  loadApplicationVersion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadApplicationVersion),
      mergeMap(() => this.appVersionClient.get()),
      map((applicationVersion) =>
        setApplicationVersion({
          currentVersion: applicationVersion?.currentVersion ?? '',
        })
      )
    )
  );

  constructor(
    private actions$: Actions,
    private appVersionClient: ApplicationVersionClient
  ) {}
}
