<input
  type="text"
  class="adap-form-input"
  [placeholder]="placeholder"
  [ngModel]="filterText"
  (ngModelChange)="onFilterChange($event)"
  (keydown)="$event.stopPropagation()"
  [readonly]="isReadOnly"
/>
<div class="clear-button-wrapper">
  <button
    class="adap-btn-blank clear-button"
    title="clear"
    *ngIf="filterText != null && filterText != ''"
    (click)="onClearFilterClicked()"
  >
    <mat-icon svgIcon="cvx-close"></mat-icon>
  </button>
</div>

<button class="adap-btn-filled search-button" title="clear">
  <mat-icon>search</mat-icon>
</button>
