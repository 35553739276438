import { NgrxValueConverter } from 'ngrx-forms';

export const CustomNgrxValueConverters = {
  dateOnlyToISOString: {
    convertViewToStateValue: (date) => {
      if (date === null) {
        return null;
      }
      const dateWithoutOffset = getDateTimeWithoutTimeOffset(date);
      return dateWithoutOffset.toISOString();
    },
    convertStateToViewValue: (s) =>
      s === null ? null : new Date(Date.parse(s)),
  } as NgrxValueConverter<Date | null, string | null>,
};

export function getCurrentDateTimeString() {
  const now = new Date();
  return `${now.toLocaleDateString()} ${now.toLocaleTimeString()}`;
}

export function getDateTimeWithoutTimeOffset(value: Date) {
  const getUTC = value.getTime();
  const offset = value.getTimezoneOffset() * 60000;
  return new Date(getUTC - offset);
}
