export const DESCRIPTION_MAX_LENGTH = 10000;

export class WorkplanRoutes {
  static readonly INFORMATION = 'information';
  static readonly SCREENING = 'screening';
  static readonly PEER_POOL = 'peer-pool';
  static readonly RISK_ASSESSMENT = 'risk-assessment';
  static readonly CHECKS = 'checks';
  static readonly OBSERVATION_TOOL = 'observation-tool';
  static readonly OBSERVATION_TOOL_METRICS = 'observation-tool-metrics';
  static readonly PROJECT_DELIVERABLES = 'project-deliverables';
}
