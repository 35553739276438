<button
  *ngIf="props.show"
  click-stop-propagation
  class="adap-btn-grid-cell"
  (click)="props.action(data)"
  [disabled]="props.disabled"
  [matTooltip]="props.tooltip"
  matTooltipPosition="above"
>
  <mat-icon>{{ props.icon }}</mat-icon>
</button>
