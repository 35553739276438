import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject, Subscription } from 'rxjs';
import {
  debounceTime,
  delay,
  filter,
  map,
  startWith,
  take,
  tap,
} from 'rxjs/operators';
import { AppState } from 'src/app/app.state';
import { InputFilterComponent } from 'src/app/shared/components/input-filter/input-filter.component';
import { AgGridCheckboxRendererActions } from 'src/app/shared/models/ag-grid-checkbox-renderer.model';
import { IPIMSEventsService } from '../../services/ipims-dialog-events.service';
import { SearchIPIMSDocumentsViewModel } from '../ipims-documents-load-common/ipims-documents-load-common.component';
import { LoadIPIMSDocumentsConfig } from '../../models/ipims-dialog-configuration';

@Component({
  selector: 'app-ipims-documents-load',
  templateUrl: './ipims-documents-load.component.html',
})
export class IPIMSDocumentsLoadComponent implements OnInit, OnDestroy {
  @Input() config!: LoadIPIMSDocumentsConfig;
  @Output() onCloseWindow = new EventEmitter();

  selection: Map<string, SearchIPIMSDocumentsViewModel> = new Map();
  loadEnabled$: Observable<{ enabled: boolean }>;
  private subscriptions = new Subscription();
  searchSpinner$: Observable<boolean>;
  resultsCount = 0;
  private searchSpinner: Subject<boolean> = new Subject();
  private quickFilterSearch: Subject<string> = new Subject();
  private minimumSearchLength = 3;
  resultLimit: number = 100;
  private alreadyLoadedDocuments: string[] = [];
  dataSource$!: Observable<SearchIPIMSDocumentsViewModel[]>;
  checkboxActions: Map<string, AgGridCheckboxRendererActions> = new Map();
  private rowsRendered: Subject<void> = new Subject();

  @ViewChild(InputFilterComponent) searchFilter!: InputFilterComponent;

  constructor(
    private store: Store<AppState>,
    public ipimsEventsService: IPIMSEventsService
  ) {
    this.searchSpinner$ = this.searchSpinner.pipe(startWith(false));
    this.loadEnabled$ = ipimsEventsService.loadEnabled$;
    this.ipimsEventsService.loadEnabled(false);
  }

  ngOnInit() {
    this.setUpComponent();
  }

  setUpComponent() {
    this.setUpSearchFromQuickFilter();
    this.setUpDataSource();
    this.clearSearch();
  }

  setUpSearchFromQuickFilter() {
    this.subscriptions.add(
      this.quickFilterSearch
        .pipe(
          debounceTime(1000),
          tap((searchedText) => {
            if ((searchedText?.length ?? 0) >= this.minimumSearchLength) {
              this.searchSpinner.next(true);
              this.store.dispatch(
                this.config.documentsSearchAction({
                  domain: this.config.ipimsDomain ?? '',
                  filter: searchedText!,
                })
              );
            }
          })
        )
        .subscribe()
    );
  }

  private setUpDataSource() {
    this.dataSource$ = this.store
      .select(this.config.documentsSearchResultSelector)
      .pipe(
        filter((x) => x != null),
        map((x) => {
          this.searchSpinner.next(false);
          this.resultLimit = x!.limit;
          this.resultsCount = x!.total;

          const currentSelection = Array.from(this.selection.values());
          this.checkboxActions.clear();

          this.rowsRendered
            .pipe(take(1), delay(100))
            .subscribe(this.handleRowsRendered.bind(this));

          return (
            x!.exceededLimit
              ? [...currentSelection]
              : [...x!.ipimsDocuments!, ...currentSelection]
          )
            .map((doc) => {
              return {
                ...doc,
                isLoaded: this.alreadyLoadedDocuments.includes(doc.primKey),
              } as SearchIPIMSDocumentsViewModel;
            })
            .sort((a) => (a.isLoaded ? 1 : -1));
        }),
        startWith([])
      );
  }

  clearSearch() {
    this.clearFilter();
    this.store.dispatch(this.config.clearSearchAction());
  }

  clearFilter() {
    if (this.searchFilter) {
      this.searchFilter.filterText = '';
    }
  }

  private handleRowsRendered() {
    const alreadySelectedIds = Array.from(this.selection?.keys() ?? []);
    for (const id of alreadySelectedIds) {
      const action = this.checkboxActions.get(id);
      if (action) {
        action.setChecked(true);
      }
    }
  }

  search(filterText: string) {
    this.quickFilterSearch.next(filterText);
  }

  clearSelection() {
    this.checkboxActions.forEach((checkBoxAction) =>
      checkBoxAction.setChecked(false)
    );
    this.selection.clear();
    this.ipimsEventsService.loadEnabled(false);
  }

  ngOnDestroy(): void {
    this.subscriptions?.unsubscribe();
  }

  onRowsRendered() {
    this.rowsRendered.next();
  }

  closeWindow() {
    this.onCloseWindow.emit();
  }

  load() {
    const documentIds = [...this.selection.keys()!];
    this.store.dispatch(
      this.config.documentLoadAction({
        documentIds,
      })
    );
    this.closeWindow();
  }
}
