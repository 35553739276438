import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { getCurrentDateTimeString } from '../utils/date-utils';

@Injectable({
  providedIn: 'root',
})
export class ToastMessagesService {
  private readonly TOAST_TIMEOUT = 5000;
  private readonly EASE_TIME = 100;

  constructor(private toastrService: ToastrService) {}

  displayError(msg: string, title?: string | undefined) {
    msg = `${getCurrentDateTimeString()}\n${msg}`;
    this.toastrService.error(msg, title, {
      disableTimeOut: true,
      closeButton: true,
      tapToDismiss: false,
      easeTime: this.EASE_TIME,
    });
  }

  displayWarning(msg: string, title?: string | undefined) {
    this.toastrService.warning(msg, title, {
      disableTimeOut: true,
      tapToDismiss: true,
      easeTime: this.EASE_TIME,
    });
  }

  displaySuccess(msg: string, title?: string | undefined) {
    this.toastrService.success(msg, title, {
      timeOut: this.TOAST_TIMEOUT,
      easeTime: this.EASE_TIME,
    });
  }
}
