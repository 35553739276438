import { Params } from '@angular/router';
import { getRouterSelectors, RouterReducerState } from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  ApplicationVersion,
  AuthState,
  CacheClearConfig,
  LoadState,
} from './shared.reducer';

export const {
  selectCurrentRoute, // select the current route
  selectFragment, // select the current route fragment
  selectQueryParams, // select the current route query params
  selectQueryParam, // factory function to select a query param
  selectRouteParams, // select the current route params
  selectRouteParam, // factory function to select a route param
  selectRouteData, // select the current route data
  selectUrl, // select the current url
} = getRouterSelectors();

export const selectRouter = createFeatureSelector<RouterReducerState>('router');
export const selectLoadFeature = createFeatureSelector<LoadState>('load');
export const selectAuthFeature = createFeatureSelector<AuthState>('auth');
export const selectAppVersion =
  createFeatureSelector<ApplicationVersion>('appVer');
export const selectCacheClearConfigFeature =
  createFeatureSelector<CacheClearConfig>('cacheClear');

export const selectCurrentVersion = createSelector(
  selectAppVersion,
  (appVer) => appVer.currentVersion ?? ''
);

export const selectCurrentPath = createSelector(
  selectCurrentRoute,
  (route) => route?.routeConfig?.path
);

export const selectRouteNestedParams = createSelector(
  selectRouter,
  (router) => {
    let currentRoute = router?.state?.root;
    let params: Params = {};
    while (currentRoute?.firstChild) {
      currentRoute = currentRoute.firstChild;
      params = {
        ...params,
        ...currentRoute.params,
      };
    }
    return params;
  }
);

export const selectRouteNestedParam = (param: string) =>
  createSelector(selectRouteNestedParams, (params) => params && params[param]);

export const selectShowLoadingSpinner = createSelector(
  selectLoadFeature,
  (state) => state.showSpinner
);

export const selectShowAuthErrorPage = createSelector(
  selectAuthFeature,
  (state) => state.showAuthErrorPage
);

export const selectErrorStatus = createSelector(
  selectAuthFeature,
  (state) => state.errorStatus
);

export const selectErrorMessage = createSelector(
  selectAuthFeature,
  (state) => state.errorMessage
);
