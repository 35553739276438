import { Injectable } from '@angular/core';
import {
  IRequestCacheService,
  ResponseWithExpirationDate,
} from './requestCache.service';

@Injectable()
export class RequestInMemoryCacheService implements IRequestCacheService {
  private requestsCache: Map<string, ResponseWithExpirationDate> = new Map();

  async get(request: RequestInfo): Promise<Response | undefined> {
    const url = this.getUrl(request);
    if (this.requestsCache.has(url)) {
      const responseWithExpiration = this.requestsCache.get(url)!;

      if (new Date().getTime() < responseWithExpiration.expirationDate) {
        return responseWithExpiration.response;
      }
      await this.delete(url);
    }

    return undefined;
  }

  async put(
    request: RequestInfo,
    response: Response,
    expirationDate: number
  ): Promise<void> {
    const url = this.getUrl(request);
    this.requestsCache.set(url, {
      expirationDate: expirationDate,
      response: response.clone(),
    });
  }

  async delete(request: RequestInfo): Promise<void> {
    const url = this.getUrl(request);
    this.requestsCache.delete(url);
  }

  private getUrl(request: RequestInfo): string {
    if (typeof request === 'string') {
      return request;
    }

    return request.url;
  }
}
