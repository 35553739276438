<mat-form-field
  class="adap-select"
  appearance="outline"
  [class.mat-form-field-invalid]="isTouched && isInvalid"
>
  <mat-select
    [ngModel]="selectedDomain?.domain"
    [placeholder]="placeholder"
    (opened)="onOpen()"
    (closed)="onClose()"
    [disabled]="isDisabled"
  >
    <mat-option class="empty-option" *ngIf="domains.length < 1"></mat-option>
    <mat-option *ngFor="let domain of domains" [value]="domain">
      {{ domain }}
    </mat-option>
  </mat-select>
</mat-form-field>
