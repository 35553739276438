<ng-container *ngIf="tableConfig$ | async as tableConfig">
  <app-grid-quick-filter
    *ngIf="showQuickFilter"
    [configStoreTableName]="configStoreTableName"
    [configStoreRouteIdName]="configStoreRouteIdName"
    [tagsMode]="tagsMode"
  ></app-grid-quick-filter>

  <app-column-list-dropdown
    *ngIf="showColumnsDropdown"
    [configStoreTableName]="configStoreTableName"
    [configStoreRouteIdName]="configStoreRouteIdName"
  ></app-column-list-dropdown>

  <app-table-actions
    [configStoreTableName]="configStoreTableName"
    [configStoreRouteIdName]="configStoreRouteIdName"
    [disableColumnSizeStrategies]="disableColumnSizeStrategies"
    [disableExportToCsv]="disableExportToCsv"
    [tableConfig]="tableConfig"
  >
  </app-table-actions>
</ng-container>
