<app-header></app-header>
<main>
  <app-not-found-or-not-authorized
    *ngIf="showAuthErrorPage$ | async; else foundAndAuthorized"
  ></app-not-found-or-not-authorized>
  <ng-template #foundAndAuthorized>
    <div class="scroll-wrapper">
      <router-outlet></router-outlet>
    </div>
  </ng-template>
</main>
<app-new-project-dialog-entry></app-new-project-dialog-entry>
<app-footer></app-footer>
<app-spinner></app-spinner>
