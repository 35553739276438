import { Directive, forwardRef } from '@angular/core';
import { FormViewAdapter, NGRX_FORM_VIEW_ADAPTER } from 'ngrx-forms';
import { UserSearchComponent } from '../components/user-search/user-search.component';

@Directive({
  selector: 'app-user-search[ngrxFormControlState]',
  providers: [
    {
      provide: NGRX_FORM_VIEW_ADAPTER,
      useExisting: forwardRef(() => UserSearchAdapterDirective),
      multi: true,
    },
  ],
})
export class UserSearchAdapterDirective implements FormViewAdapter {
  constructor(private userSearchComponent: UserSearchComponent) {}

  setViewValue(value: any): void {
    if (value) {
      this.userSearchComponent.setValueSubject.next(value);
    }
  }

  setOnChangeCallback(fn: (value: any) => void): void {
    this.userSearchComponent.selectionChanged.subscribe((user) => fn(user?.id));
  }

  setOnTouchedCallback(fn: any): void {
    this.userSearchComponent.registerOnTouched(fn);
  }

  setIsDisabled(isDisabled: boolean) {
    // we handle this in the component itself because of expression changed error
  }
}
