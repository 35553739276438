<label class="adap-input-label" [attr.for]="labelFor" [ngClass]="{ 'adap-input-label-bold': boldLabel }">
  {{ label }}
  <span *ngIf="isRequired" class="field-required-text">(required)</span>
  <mat-icon *ngIf="copy" class="copy-icon" (click)="copyEvent.emit()"
    >content_copy</mat-icon
  >
</label>
<mat-icon
  *ngIf="info"
  class="info-icon"
  svgIcon="cvx-info-circle"
  color="primary"
  [matTooltip]="info"
  matTooltipPosition="above"
  matTooltipShowDelay="1000"
></mat-icon>
<span *ngIf="characterCounterMaxLength" class="character-counter">
  <span [class.exceeds-max]="characterCounterLength > characterCounterMaxLength"
    >{{ characterCounterLength }} </span
  >/ {{ characterCounterMaxLength }}
</span>
