import { Component, OnInit, Renderer2 } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from './app.state';
import { storageSyncAction } from './app.state.actions';
import { AppInsightsMonitorService } from './shared/services/appinsights-monitor.service';
import { IconRegistryService } from './shared/services/icon-registry.service';
import { selectShowAuthErrorPage } from './shared/store/shared.selectors';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'ADAP';
  showAuthErrorPage$ = this.store.select(selectShowAuthErrorPage);

  constructor(
    appinsights: AppInsightsMonitorService,
    private store: Store<AppState>,
    private iconRegistry: IconRegistryService,
    private renderer: Renderer2
  ) {}

  ngOnInit() {
    // needed to make sync between tabs work, so that state
    // is updated from latest local storage
    this.renderer.listen('window', 'storage', (event) => {
      if (event?.key && typeof event.key === 'string') {
        this.store.dispatch(storageSyncAction({ payload: event.key }));
      }
    });
  }
}
