import { createReducer, on } from '@ngrx/store';
import { ColDef } from 'ag-grid-community';
import { QuickFilterMode } from '../../ag-grid-config.constants';
import {
  clearAgGridConfigForTableRaw,
  freezeAllColumnsRaw,
  quickFilterChangeSuccess,
  quickFilterModeChangeSuccess,
  quickFilterTagsChangeSuccess,
  setAgGridConfigForTableRaw,
} from './ag-grid-config.actions';

export type ColDefWithTitle = ColDef & { columnTitle: string };
export type ExportableColDefWithTitle = ColDef & {
  columnExportHeader?: string;
  exportValueGetter?: (data: any) => string;
  ignoreExport?: boolean;
};

export interface AgGridConfig {
  touched: boolean;
  columnDefs?: ColDefWithTitle[];
  quickFilter?: string;
  quickFilterTags?: string[];
  quickFilterMode?: QuickFilterMode;
  allColumnsFrozen: boolean;
}

export interface AgGridConfigState {
  tableConfig: {
    [key: string]: AgGridConfig;
  };
}

const initialState: AgGridConfigState = {
  tableConfig: {},
};

export const agGridConfigInitialState = initialState;

export const agGridConfigReducer = createReducer(
  initialState,
  on(setAgGridConfigForTableRaw, (state, { tableId, columnDefs, touched }) => ({
    ...state,
    tableConfig: {
      ...state.tableConfig,
      [tableId]: {
        ...state.tableConfig[tableId],
        touched,
        columnDefs,
      },
    },
  })),
  on(clearAgGridConfigForTableRaw, (state, { tableId }) => {
    const tableConfig = { ...state.tableConfig };
    delete tableConfig[tableId];
    return {
      ...state,
      tableConfig,
    };
  }),
  on(quickFilterChangeSuccess, (state, { tableId, quickFilterText }) => ({
    ...state,
    tableConfig: {
      ...state.tableConfig,
      [tableId]: {
        ...state.tableConfig[tableId],
        quickFilter: quickFilterText,
      },
    },
  })),
  on(quickFilterModeChangeSuccess, (state, { tableId, quickFilterMode }) => ({
    ...state,
    tableConfig: {
      ...state.tableConfig,
      [tableId]: {
        ...state.tableConfig[tableId],
        quickFilterMode,
      },
    },
  })),
  on(quickFilterTagsChangeSuccess, (state, { tableId, quickFilterTags }) => ({
    ...state,
    tableConfig: {
      ...state.tableConfig,
      [tableId]: {
        ...state.tableConfig[tableId],
        quickFilterTags,
      },
    },
  })),
  on(freezeAllColumnsRaw, (state, { tableId, freeze }) => ({
    ...state,
    tableConfig: {
      ...state.tableConfig,
      [tableId]: {
        ...state.tableConfig[tableId],
        allColumnsFrozen: freeze,
      },
    },
  }))
);
