import { Component } from '@angular/core';

@Component({
  selector: 'app-mandatory-check-chip-renderer',
  templateUrl: './mandatory-check-chip-renderer.component.html',
  styleUrls: ['./mandatory-check-chip-renderer.component.scss'],
})
export class MandatoryCheckChipRendererComponent {
  agInit() {}
}
