import { Injectable } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class SnackBarService {
  constructor(public snackbar: MatSnackBar) {}

  displaySnackBarFromComponent(
    component: any,
    duration: number = 60000,
    horizontalPosition: MatSnackBarHorizontalPosition = 'end',
    verticalPosition: MatSnackBarVerticalPosition = 'bottom',
    panelClass: string | string[] = ['snack-bar-content']
  ) {
    this.snackbar.openFromComponent(component, {
      duration: duration,
      horizontalPosition: horizontalPosition,
      verticalPosition: verticalPosition,
      panelClass: panelClass,
    });
  }
}
