<app-input-container
  *ngFor="
    let selectedUserId of selectedUsersIds;
    index as i;
    trackBy: trackUserId
  "
  [label]="label + ' ' + (i + 1)"
>
  <app-user-search
    [placeholder]="label + ' ' + (i + 1)"
    [userId]="selectedUserId"
    (selectionChanged)="onSelectionChanged($event, i)"
  ></app-user-search>
</app-input-container>
