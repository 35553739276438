import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'AdapCurrency',
})
export class AdapCurrencyPipe extends CurrencyPipe implements PipeTransform {
  transform(value: any, display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean): any {
    return super.transform(value, 'USD', display, '1.2-2');
  }
}
