<mat-dialog-content class="adap-dialog-content">
  <app-dialog-header title="assign iPIMS domain"></app-dialog-header>
  <div class="navigation-component">
    <app-grid-quick-filter
      [configStoreTableName]="observationDomainAssign"
      [configStoreRouteIdName]="projectDeliverableId"
      placeholder="filter by domain"
      class="quick-filter-search"
    ></app-grid-quick-filter>

    <div class="results-sub-header">
      <span class="results-sub-header-text">results</span>
    </div>
    <div class="table-container">
      <ng-container *ngIf="dataSource$ | async as rows">
        <app-ag-grid-options
          class="grid-options"
          [showQuickFilter]="false"
          [configStoreTableName]="observationDomainAssign"
          [configStoreRouteIdName]="projectDeliverableId"
        ></app-ag-grid-options>

        <app-ipims-common-table
          class="table-component"
          [columnDefs]="IPIMsDomainsColumns"
          [rows]="rows"
          [configStoreTableName]="observationDomainAssign"
          [configStoreRouteIdName]="projectDeliverableId"
        ></app-ipims-common-table>
      </ng-container>
    </div>
  </div>

  <div class="dialog-actions">
    <button class="adap-btn-outline" type="button" (click)="closeWindow()">
      cancel
    </button>
    <button
      class="adap-btn-filled"
      type="button"
      [disabled]="!isChooseEnabled"
      (click)="choose()"
    >
      choose
    </button>
  </div>
</mat-dialog-content>
