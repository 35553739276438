import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { HeaderComponent } from './components/header/header.component';
import { MsalComponent } from './components/msal.component';
import { PortalModule } from '@angular/cdk/portal';
import { DefaultHeaderComponent } from './components/header/default-header/default-header.component';
import { UserButtonComponent } from './components/header/user-button/user-button.component';
import { DefaultFooterComponent } from './components/footer/default-footer/default-footer.component';
import { FooterComponent } from './components/footer/footer.component';
import { SharedModule } from '../shared/shared.module';
import { StoreModule } from '@ngrx/store';
import { userReducer } from './store/user/user.reducer';
import { EffectsModule } from '@ngrx/effects';
import { UserEffects } from './store/user/user.effects';
import { GlobalMenuComponent } from './components/global-menu/global-menu.component';
import { CacheMiddleware } from './middleware/cache-middleware';
import { CacheClearComponent } from './components/cache-clear/cache-clear.component';

@NgModule({
  declarations: [
    HeaderComponent,
    DefaultHeaderComponent,
    UserButtonComponent,
    DefaultFooterComponent,
    FooterComponent,
    MsalComponent,
    GlobalMenuComponent,
    CacheClearComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    PortalModule,
    SharedModule,
    MatMenuModule,
    StoreModule.forFeature('user', userReducer),
    EffectsModule.forFeature([UserEffects]),
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    MsalComponent,
    CacheClearComponent,
  ],
  providers: [UserEffects, CacheMiddleware],
})
export class CoreModule {}
