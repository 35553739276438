import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {
  ApplicationInsights,
  IExceptionTelemetry,
} from '@microsoft/applicationinsights-web';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AppInsightsMonitorService {
  appInsights: ApplicationInsights;
  //loggedUser: AccountInfo; TODO: implement when MSAL is available

  constructor(private router: Router) {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment.appInsights.instrumentationKey,
        enableAutoRouteTracking: true, // option to log all route changes
        enableCorsCorrelation: true,
      },
    });

    this.appInsights.loadAppInsights();
    this.createRouterSubscription();
  }

  logPageView(name?: string, uri?: string) {
    this.appInsights.trackPageView({ name, uri });
  }

  logException(error: Error) {
    const exception: IExceptionTelemetry = {
      exception: error,
    };
    this.appInsights.trackException(exception);
  }

  private createRouterSubscription() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd)
        this.logPageView(event.urlAfterRedirects); //todo: add loged user after MSAL is implemented
    });
  }
}
