import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class IPIMSEventsService {
  private loadEnabledSubject = new BehaviorSubject<{ enabled: boolean }>({
    enabled: false,
  });
  loadEnabled$ = this.loadEnabledSubject.asObservable();

  loadEnabled(enabled: boolean) {
    this.loadEnabledSubject.next({ enabled });
  }
}
