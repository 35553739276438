import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.state';
import { cacheClearFlag } from 'src/app/shared/store/shared.actions';

@Component({
  selector: 'app-cache-clear',
  templateUrl: './cache-clear.component.html',
})
export class CacheClearComponent implements OnInit {
  constructor(private router: Router, private store: Store<AppState>) {}

  ngOnInit(): void {
    localStorage.clear();
    sessionStorage.clear();
    document.cookie.split('; ').forEach(function (c) {
      document.cookie = c
        .replace(/^ +/, '')
        .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
    });
    this.router.navigate(['']);
    this.store.dispatch(cacheClearFlag({ cacheClearFlag: true }));
  }
}
