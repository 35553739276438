import { createSelector } from '@ngrx/store';

const themesList: string[] = [
  'Incomplete Engineering',
  'Design Basis Too Narrow',
  'Flare and Relief Sizing',
  'HAZOP Inconsistencies',
  'MOC Inconsistencies',
  'Technology Qualification Incomplete',
  'Separator Sizing',
  'Documentation Incomplete',
  'Electrical Design',
  'Piping and Valve Design',
  'Incompatible Materials',
];

export const selectProjectThemesList = createSelector(() => themesList);
