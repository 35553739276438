<mat-dialog-content class="adap-dialog-content">
  <div class="navigation-header">
    <app-dialog-header title="create new project"></app-dialog-header>
    <app-navigation-tab-strip
      class="navigation-tab"
      [tabs]="[
        { title: 'basic info', routerLink: newWorkplanOverviewLink },
        {
          title: 'contacts',
          routerLink: newWorkplanContactsLink
        }
      ]"
    >
    </app-navigation-tab-strip>
  </div>

  <div class="navigation-component">
    <router-outlet name="new-workplan-dialog"></router-outlet>
  </div>

  <ng-container *ngIf="formState$ | async as formState">
    <div class="transitions">
      <ng-container *ngIf="currentRoute$ | async as currentRoute">
        <button
          class="adap-btn-blank navigation-button"
          type="button"
          (click)="navigate(currentRoute)"
        >
          <div
            [ngClass]="{
              'text-appear': currentRoute.includes('overview'),
              'text-disappear': !currentRoute.includes('overview')
            }"
            class="text-animated"
          >
            next
          </div>
          <div
            [ngClass]="{
              'text-appear': currentRoute.includes('contacts'),
              'text-disappear': !currentRoute.includes('contacts')
            }"
            class="text-animated"
          >
            back
          </div>
        </button>

        <mat-icon
          class="arrow-icon"
          [ngClass]="{
            'icon-animation': currentRoute.includes('contacts'),
            'icon-animation-reverse': !currentRoute.includes('contacts')
          }"
          svgIcon="cvx-right"
        ></mat-icon>
      </ng-container>
    </div>

    <div class="dialog-actions">
      <button class="adap-btn-outline" type="button" (click)="closeWindow()">
        cancel
      </button>
      <button
        class="adap-btn-filled"
        type="button"
        (click)="create(formState.value.phaseId!)"
        [disabled]="!formState.isValid"
      >
        create
      </button>
    </div>
  </ng-container>
</mat-dialog-content>
