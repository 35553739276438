<mat-dialog-content class="adap-dialog-content">
  <app-dialog-header
    [title]="data.title"
    class="title-no-wrap"
  ></app-dialog-header>

  <ng-container *ngIf="data.formatDescriptionAsHtml; else plainText">
    <div [innerHTML]="data.description"></div>
  </ng-container>

  <ng-template #plainText>
    <div>{{ data.description }}</div>
  </ng-template>

  <div class="dialog-actions">
    <button
      class="adap-btn-outline"
      *ngIf="showCancelButton"
      type="button"
      (click)="cancel()"
    >
      {{ data.cancelButtonText ?? "cancel" }}
    </button>
    <button
      class="adap-btn-filled"
      type="button"
      (click)="confirm()"
      [ngClass]="confirmationButtonClass"
    >
      {{ data.confirmButtonText }}
    </button>
  </div>
</mat-dialog-content>
