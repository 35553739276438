import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ColDef } from 'ag-grid-community';
import {
  AgGridCheckboxRendererActions,
  AgGridCheckboxRendererModel,
} from 'src/app/shared/models/ag-grid-checkbox-renderer.model';
import { IIPIMSDocumentsViewModel } from 'src/app/shared/models/autogenerated';
import { nameof } from 'src/app/shared/utils/nameof';
import { IPIMSEventsService } from '../../services/ipims-dialog-events.service';

export interface SearchIPIMSDocumentsViewModel
  extends IIPIMSDocumentsViewModel {
  isLoaded: boolean;
}

@Component({
  selector: 'app-ipims-documents-load-common',
  templateUrl: './ipims-documents-load-common.component.html',
  styleUrls: ['./ipims-documents-load-common.component.scss'],
})
export class IPIMSDocumentsLoadCommonComponent {
  placeholder = 'document search (min. 3 characters)';
  observationDocumentsLoad = 'observationDocumentsLoad';
  projectDeliverableId = 'projectDeliverableId';

  @Input() selection!: Map<string, SearchIPIMSDocumentsViewModel>;
  @Input() searchSpinner!: boolean | null;
  @Input() rows!: SearchIPIMSDocumentsViewModel[] | null;
  @Input() resultLimit!: number | null;
  @Input() resultsCount!: number | null;
  @Input() IPIMSDomain?: string | null;
  @Input() checkboxActions!: Map<string, AgGridCheckboxRendererActions>;
  @Input() ipimsEventsService!: IPIMSEventsService;

  @Output() rowsRendered = new EventEmitter();
  @Output() onSearch = new EventEmitter<string>();
  @Output() onClearSelection = new EventEmitter();

  onRowsRendered() {
    this.rowsRendered.emit();
  }

  IPIMsDocumentsColumns: ColDef[] = [
    {
      cellRenderer: 'agGridCheckbox',
      cellRendererParams: {
        onInit: this.onInitCheckbox.bind(this),
        onCheckChanged: this.onSelectionChanged.bind(this),
      } as AgGridCheckboxRendererModel<IIPIMSDocumentsViewModel>,
      minWidth: 60,
      maxWidth: 60,
      resizable: false,
      type: 'centerAligned',
      pinned: 'left',
    },
    {
      field: nameof<IIPIMSDocumentsViewModel>('title'),
      headerName: 'title',
      width: 180,
    },
    {
      field: nameof<IIPIMSDocumentsViewModel>('discipline'),
      headerName: 'discipline',
      width: 100,
    },
    {
      field: nameof<IIPIMSDocumentsViewModel>('currentStep'),
      headerName: 'issue',
      width: 80,
    },
    {
      field: nameof<IIPIMSDocumentsViewModel>('currentRevision'),
      headerName: 'revision',
      width: 80,
    },
    {
      field: nameof<IIPIMSDocumentsViewModel>('documentID'),
      headerName: 'document ID',
      width: 160,
    },
  ];

  private onInitCheckbox(
    data: SearchIPIMSDocumentsViewModel,
    actions: AgGridCheckboxRendererActions
  ) {
    this.checkboxActions.set(data.primKey, actions);
    if (data.isLoaded) {
      actions.setDisabled(true);
    }
  }

  private onSelectionChanged(
    isChecked: boolean,
    data: SearchIPIMSDocumentsViewModel,
    actions?: AgGridCheckboxRendererActions
  ) {
    if (isChecked) {
      this.selection.set(data.primKey, data);
    } else {
      this.selection.delete(data.primKey);
    }

    this.ipimsEventsService.loadEnabled(this.selection.size > 0);
  }

  clearSelection() {
    this.onClearSelection.emit();
  }

  search(filterText: string) {
    this.onSearch.emit(filterText);
  }
}
