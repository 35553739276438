<ng-container *ngIf="!isHidden">
  <mat-checkbox
    [disabled]="isDisabled"
    *ngIf="!isIcon"
    color="primary"
    (change)="onChange()"
    [(ngModel)]="isChecked"
  ></mat-checkbox>
  <button
    class="adap-btn-grid-cell"
    color="primary"
    *ngIf="isIcon"
    (click)="onIconClick()"
    [matTooltip]="tooltipText"
    matTooltipPosition="above"
    matTooltipShowDelay="300"
  >
    <mat-icon [svgIcon]="icon"></mat-icon>
  </button>
</ng-container>
