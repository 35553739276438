import { ComponentPortal, Portal, TemplatePortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DefaultFooterComponent } from '../components/footer/default-footer/default-footer.component';

@Injectable({
  providedIn: 'root',
})
export class FooterService {
  private defaultFooterPortal = new ComponentPortal(DefaultFooterComponent);
  private footerPortalSubject = new BehaviorSubject<Portal<any>>(
    this.defaultFooterPortal
  );
  public footerPortal$ = this.footerPortalSubject.asObservable();

  setFooterPortal(portal: TemplatePortal) {
    this.footerPortalSubject.next(portal);
  }

  clearFooterPortal() {
    this.footerPortalSubject.next(this.defaultFooterPortal);
  }
}
