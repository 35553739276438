import { BrowserAuthOptions } from '@azure/msal-browser';

const appRegistration = {
  clientId: '211b6f0e-0f56-49ba-84e1-71826d2011a7',
  tenantId: 'fd799da1-bfc1-4234-a91c-72b3a1cb9e26',
};

const msalConfigBrowserAuth: BrowserAuthOptions = {
  clientId: appRegistration.clientId,
  redirectUri: '/',
  postLogoutRedirectUri: '/',
  authority: `https://login.microsoftonline.com/${appRegistration.tenantId}/`,
};

export const environment = {
  msalConfig: {
    browserAuth: msalConfigBrowserAuth,
  },
  apiEndpoint: 'https://adap-api-dev.azure.chevron.com',
  appRegistration,
  production: false,
  wikiReleaseNotesUrl:
    'https://dev.azure.com/chevron/Surf-SE-ADAP/_wiki/wikis/ADAP%20wiki/81730/Release-Notes-Dev',
  appInsights: {
    instrumentationKey: (<any>window).appInsights.config.instrumentationKey,
  },
};
