import { Component, Input, ViewChild } from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { DeliverableManagerDADeliverableTableComponent } from '../deliverable-manager-da-deliverable-table/deliverable-manager-da-deliverable-table.component';
import { DeliverableManagerProjectDeliverableTableComponent } from '../deliverable-manager-project-deliverable-table/deliverable-manager-project-deliverable-table.component';

@Component({
  selector: 'app-deliverable-manager-matched-filter',
  templateUrl: './deliverable-manager-matched-filter.component.html',
})
export class DeliverableManagerMatchedFilterComponent {
  @Input()
  projectDeliverableTableComponent!: DeliverableManagerProjectDeliverableTableComponent;
  @Input()
  daDeliverableTableComponent!: DeliverableManagerDADeliverableTableComponent;
  @ViewChild('displayViewMatSelect') displayViewMatSelect!: MatSelect;

  MATCHED = 'matched';
  UNMATCHED = 'unmatched';
  UNMATCHED_DA_DELIVERABLES = 'unmatched DA Deliverables';
  UNMATCHED_PROJECT_DELIVERABLES = 'unmatched Project Deliverables';
  displayViewSelected: string[] = [];

  private displayingFilter: string | null = null;

  private isFilterMatched(
    tableComponent:
      | DeliverableManagerProjectDeliverableTableComponent
      | DeliverableManagerDADeliverableTableComponent
  ) {
    if (tableComponent == null) {
      return true;
    }

    const mapFilter = tableComponent.agGrid.api!.getFilterModel().map;
    return (
      mapFilter != null &&
      mapFilter.type === 'greaterThan' &&
      mapFilter.filter == 0
    );
  }

  private isFilterUnmatched(
    tableComponent:
      | DeliverableManagerProjectDeliverableTableComponent
      | DeliverableManagerDADeliverableTableComponent
  ) {
    if (tableComponent == null) {
      return true;
    }

    const mapFilter = tableComponent.agGrid.api!.getFilterModel().map;
    return (
      mapFilter != null && mapFilter.type === 'equals' && mapFilter.filter == 0
    );
  }

  filterChanged() {
    const isFilterDADeliverableMatched = this.isFilterMatched(
      this.daDeliverableTableComponent
    );
    const isFilterProjectDeliverableMatched = this.isFilterMatched(
      this.projectDeliverableTableComponent
    );

    const isFilterDADeliverableUnmatched = this.isFilterUnmatched(
      this.daDeliverableTableComponent
    );
    const isFilterProjectDeliverableUnmatched = this.isFilterUnmatched(
      this.projectDeliverableTableComponent
    );

    if (isFilterDADeliverableMatched && isFilterProjectDeliverableMatched) {
      this.displayingFilter = this.MATCHED;
    } else if (
      isFilterDADeliverableUnmatched &&
      isFilterProjectDeliverableUnmatched
    ) {
      this.displayingFilter = this.UNMATCHED;
    } else if (isFilterDADeliverableUnmatched) {
      this.displayingFilter = this.UNMATCHED_DA_DELIVERABLES;
    } else if (isFilterProjectDeliverableUnmatched) {
      this.displayingFilter = this.UNMATCHED_PROJECT_DELIVERABLES;
    } else {
      this.displayingFilter = null;
    }

    this.updateDisplayViewSelected();
  }

  displayViewSelectedChange() {
    this.displayViewMatSelect.close();

    this.displayingFilter =
      this.displayViewSelected.find((x) => x != this.displayingFilter) ?? null;

    const filterUnmatched = {
      map: { type: 'equals', filter: '0' },
    };

    switch (this.displayingFilter) {
      case this.MATCHED:
        this.applyMapFilterForTables({
          map: { type: 'greaterThan', filter: '0' },
        });
        break;
      case this.UNMATCHED:
        this.applyMapFilterForTables(filterUnmatched);
        break;
      case this.UNMATCHED_DA_DELIVERABLES:
        this.applyMapFilterForDADeliverable(filterUnmatched);
        this.cleanMapFilterForProjectDeliverables();
        break;
      case this.UNMATCHED_PROJECT_DELIVERABLES:
        this.cleanMapFilterForDADeliverable();
        this.applyMapFilterForProjectDeliverable(filterUnmatched);
        break;
      default:
        this.cleanMapFilterForTables();
    }

    this.updateDisplayViewSelected();
  }

  private updateDisplayViewSelected() {
    this.displayViewSelected = [];
    if (this.displayingFilter) {
      this.displayViewSelected.push(this.displayingFilter);
    }
  }

  private applyMapFilterForTables(mapFilter: any) {
    this.applyMapFilterForDADeliverable(mapFilter);
    this.applyMapFilterForProjectDeliverable(mapFilter);
  }

  private applyMapFilterForDADeliverable(mapFilter: any) {
    this.daDeliverableTableComponent?.agGrid.api.setFilterModel(mapFilter);
  }

  private applyMapFilterForProjectDeliverable(mapFilter: any) {
    this.projectDeliverableTableComponent?.agGrid.api.setFilterModel(mapFilter);
  }

  private cleanMapFilterForTables() {
    this.cleanMapFilterForDADeliverable();
    this.cleanMapFilterForProjectDeliverables();
  }

  private cleanMapFilterForDADeliverable() {
    this.daDeliverableTableComponent?.agGrid.api.destroyFilter('map');
  }

  private cleanMapFilterForProjectDeliverables() {
    this.projectDeliverableTableComponent?.agGrid.api.destroyFilter('map');
  }
}
