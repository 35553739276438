<div class="header">
  <span *ngIf="isReadOnly" class="project-read-only">
    <mat-icon>edit_off</mat-icon> read-only
  </span>
  <div class="row">
    <p class="title">{{ title }}</p>
    <button
      *ngIf="options?.length"
      mat-icon-button
      [matMenuTriggerFor]="menu"
      class="menu-button"
    >
      <mat-icon>more_horiz</mat-icon>
    </button>
    <mat-menu #menu>
      <button
        mat-menu-item
        *ngFor="let option of options"
        (click)="onOptionSelect(option)"
        [disabled]="option.disabled"
      >
        <span class="menu-name"> {{ option.text }}</span>
      </button>
    </mat-menu>
  </div>
  <p class="subtitle">{{ subtitle }}</p>
</div>
<ng-content></ng-content>
