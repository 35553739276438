<ng-container *ngIf="phase$ | async as phase">
  <div fullscreenWrapper class="wrapper">
    <div class="actions-container">
      <div>
        <button class="adap-btn-outline" (click)="clearAllFilters()">
          clear all filters
        </button>

        <button
          [disabled]="phase.isReadOnly"
          class="adap-btn-outline"
          (click)="openOneOnOneMatchDialog()"
        >
          1:1 match
        </button>

        <button
          [disabled]="phase.isReadOnly"
          class="adap-btn-outline"
          (click)="openAutoMatchingDialog()"
        >
          automatching
        </button>
      </div>
      <div class="filters">
        <app-deliverable-manager-matched-filter
          #matchedFilter
          [daDeliverableTableComponent]="firstTableComponent"
          [projectDeliverableTableComponent]="projectDeliverableTableComponent"
        ></app-deliverable-manager-matched-filter>
        <app-fullscreen-toggle></app-fullscreen-toggle>
      </div>
    </div>
    <div class="match-container">
      <button
        class="adap-btn-filled"
        [disabled]="
          projectDeliverablesSelected.length == 0 ||
          deliverablesSelected.length == 0 ||
          phase.isReadOnly
        "
        (click)="matchDeliverables()"
      >
        <span class="match-selected-number">{{
          deliverablesSelected.length
        }}</span>
        <mat-icon class="match_right" svgIcon="cvx-right"></mat-icon>
        match
        <mat-icon class="match_left" svgIcon="cvx-left"></mat-icon>
        <span class="match-selected-number">{{
          projectDeliverablesSelected.length
        }}</span>
      </button>
    </div>
    <ng-container *ngIf="deliverables$ | async as daDeliverables">
      <ng-container *ngIf="projectDeliverables$ | async as projectDeliverables">
        <div class="tables-container">
          <div class="da-deliverables-container">
            <div class="table-title-container">
              <div class="table-title">DA deliverables</div>
              <div class="match-rate">
                {{ daDeliverableMatchRate }}% match
                <mat-icon svgIcon="cvx-checkmark-circle"></mat-icon>
              </div>
            </div>
            <app-deliverable-manager-da-deliverable-table
              *ngIf="daDeliverables.length > 0; else deliverablePlaceholder"
              [rowData]="daDeliverables"
              (selectionChanged)="onDeliverablesSelectionChanged($event)"
              (setFilterByDeliverableIds)="
                setFilterByProjectDeliverableIds($event)
              "
              (deselectAllInBothTables)="deselectAll()"
              (filterChanged)="onFilterChanged()"
              (quickFilterChanged)="onQuickFilterChanged(Da)"
            ></app-deliverable-manager-da-deliverable-table>
            <ng-template #deliverablePlaceholder>
              <div class="deliverable-placeholder">
                <app-placeholder
                  icon="assets/images/no-systems.svg"
                  title="no DA deliverables added yet"
                  description="add DA deliverables"
                  [buttonCaption]="DELIVERABLES_CLASS_ALL_TEXT"
                  (buttonClick)="navigateToDeliverables()"
                ></app-placeholder>
              </div>
            </ng-template>
          </div>

          <div
            *ngIf="{
              linkEnabled: isQuickFilterLinkAvailable$ | async
            } as context"
            class="middle-container"
            [matTooltip]="
              !context.linkEnabled
                ? 'filters must be in the same mode for sync.'
                : ''
            "
          >
            <button
              class="adap-btn-blank btn-sync-quick-filters"
              [disabled]="!context.linkEnabled"
              (click)="toggleLinkQuickFilters()"
              *ngIf="
                projectDeliverables.length > 0 && daDeliverables.length > 0
              "
            >
              <mat-icon
                *ngIf="areQuickFiltersLinked === true"
                matTooltip="unlink filters"
                svgIcon="cvx-refresh"
              ></mat-icon>
              <mat-icon
                *ngIf="areQuickFiltersLinked === false"
                matTooltip="link filters"
                >sync_disabled</mat-icon
              >
            </button>
          </div>

          <div class="project-deliverables-container">
            <div class="table-title-container">
              <div class="table-title">project deliverables</div>
            </div>
            <app-deliverable-manager-project-deliverable-table
              *ngIf="projectDeliverables.length > 0"
              [rowData]="projectDeliverables"
              (selectionChanged)="onProjectDeliverablesSelectionChanged($event)"
              (setFilterByDeliverableIds)="setFilterByFirstTableIds($event)"
              (deselectAllInBothTables)="deselectAll()"
              (filterChanged)="onFilterChanged()"
              (quickFilterChanged)="onQuickFilterChanged(Project)"
            ></app-deliverable-manager-project-deliverable-table>
            <div class="deliverable-placeholder">
              <app-placeholder
                [ngClass]="{ hidden: projectDeliverables.length > 0 }"
                icon="assets/images/no-systems.svg"
                title="no project deliverables added yet"
                description="import project deliverables with the button below"
                buttonCaption="upload completed template"
                (buttonClick)="importExcel()"
                [buttonDisabled]="phase.isReadOnly"
              ></app-placeholder>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
