import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AgGridModule } from 'ag-grid-angular';
import { NgrxFormsModule } from 'ngrx-forms';
import { AgGridConfigModule } from 'src/app/shared/ag-grid-config/ag-grid-config.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { IPIMSCommonTableComponent } from './components/ipims-common-table/ipims-common-table.component';
import { IPIMSDocumentsLoadCommonComponent } from './components/ipims-documents-load-common/ipims-documents-load-common.component';
import { IPIMSDocumentsLoadComponent } from './components/ipims-documents-load/ipims-documents-load.component';
import { IPIMSDomainAssignDialogComponent } from './components/ipims-domain-assign-dialog/ipims-domain-assign-dialog.component';
import { IPIMSDomainSelectorInputComponent } from './components/ipims-domain-selector-input/ipims-domain-selector-input.component';
import { IPIMSDomainAssignAdapter } from './directives/ipims-domain-assign-adapter';
import { IPIMSEventsService } from './services/ipims-dialog-events.service';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    AgGridConfigModule,
    AgGridModule,
    MatDialogModule,
    MatIconModule,
    MatSelectModule,
    MatTooltipModule,
    NgrxFormsModule,
  ],
  declarations: [
    IPIMSCommonTableComponent,
    IPIMSDocumentsLoadCommonComponent,
    IPIMSDocumentsLoadComponent,
    IPIMSDomainAssignDialogComponent,
    IPIMSDomainAssignAdapter,
    IPIMSDomainSelectorInputComponent,
  ],
  exports: [
    IPIMSDocumentsLoadComponent,
    IPIMSDomainSelectorInputComponent,
    IPIMSDomainAssignAdapter,
  ],
  providers: [IPIMSEventsService],
})
export class IPIMSModule {}
