import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActionDialogData } from '../../models/action-dialog-data';
import { ActionDialogType } from '../../models/enums/action-dialog-type.enum';

@Component({
  selector: 'app-action-dialog',
  templateUrl: './action-dialog.component.html',
  styleUrls: ['./action-dialog.component.scss'],
})
export class ActionDialogComponent {
  showCancelButton: boolean;
  confirmationButtonClass: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: ActionDialogData) {
    this.showCancelButton = this.shouldDisplayCancelButton();
    this.confirmationButtonClass = this.getConfirmationButtonClass();
  }

  cancel() {
    this.data.onCancel?.();
  }

  confirm() {
    this.data.onConfirm?.();
  }

  shouldDisplayCancelButton() {
    return (
      this.data.dialogType === ActionDialogType.Confirmation ||
      this.data.dialogType === ActionDialogType.DeleteConfirmation
    );
  }

  getConfirmationButtonClass() {
    return this.data.dialogType === ActionDialogType.DeleteConfirmation
      ? 'adap-btn-warning'
      : '';
  }
}
