import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'Plural',
})
export class PluralPipe implements PipeTransform {
  transform(word: string, count: number): string {
    if (count === 1) {
      // no modification for singular word
      return word;
    }

    let transformed;
    // 'ies' vs singular 'y' (city / cities)
    if (word.slice(-1) === 'y') {
      // replace y with ies
      transformed = word.replace(/.$/, 'ies');
    } else if (word === 'is'){
      transformed = 'are';
    }
    else {
      // default to plural 's' (applicant / applicants)
      transformed = word + 's';
    }

    return transformed;
  }
}
