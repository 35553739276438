import { ChangeDetectorRef, Directive, OnDestroy } from '@angular/core';
import { DeliverableManagerCheckTableComponent } from './deliverable-manager-check-table/deliverable-manager-check-table.component';
import { DeliverableManagerDADeliverableTableComponent } from './deliverable-manager-da-deliverable-table/deliverable-manager-da-deliverable-table.component';
import { DeliverableManagerProjectDeliverableTableComponent } from './deliverable-manager-project-deliverable-table/deliverable-manager-project-deliverable-table.component';
import { DeliverableManagerProjectDeliverableWithCheckTableComponent } from './deliverable-manager-project-deliverable-with-check-table/deliverable-manager-project-deliverable-with-check-table.component';
import { Subscription } from 'rxjs';
import { AppState } from 'src/app/app.state';
import { Store } from '@ngrx/store';
import {
  selectFilterTags,
  selectFilterText,
} from 'src/app/shared/ag-grid-config/store/ag-grid-config/ag-grid-config.selectors';
import {
  quickFilterChange,
  quickFilterTagsChange,
} from 'src/app/shared/ag-grid-config/store/ag-grid-config/ag-grid-config.actions';
import { skip } from 'rxjs/operators';

@Directive()
export abstract class MatchingManagerAbstract<
  T extends
    | DeliverableManagerCheckTableComponent
    | DeliverableManagerDADeliverableTableComponent,
  TT extends
    | DeliverableManagerProjectDeliverableWithCheckTableComponent
    | DeliverableManagerProjectDeliverableTableComponent
> implements OnDestroy
{
  abstract firstTableComponent: T;
  abstract projectDeliverableTableComponent: TT;

  protected linkSearchFieldsSubscription = new Subscription();
  protected lastFilterUsed: string = '';

  protected areQuickFiltersLinked: boolean = false;

  readonly Project: string = 'project';
  readonly Check: string = 'check';
  readonly Da: string = 'da';

  constructor(
    protected store: Store<AppState>,
    protected cdRef: ChangeDetectorRef
  ) {}

  ngAfterContentChecked() {
    this.cdRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.linkSearchFieldsSubscription?.unsubscribe();
  }

  deselectAll() {
    this.projectDeliverableTableComponent.deselectAll();
    this.firstTableComponent.deselectAll();
  }

  clearAllFilters() {
    this.projectDeliverableTableComponent.clearAllFilters();
    this.firstTableComponent.clearAllFilters();

    this.deselectAll();
  }

  setFilterByProjectDeliverableIds(projectDeliverablesIds: string[]) {
    if (projectDeliverablesIds != null && projectDeliverablesIds.length > 0) {
      this.turnOffFilterSync();
    }

    this.projectDeliverableTableComponent.setShowingOnlyIds(
      projectDeliverablesIds
    );
  }

  setFilterByFirstTableIds(FirstTableIds: string[]) {
    if (FirstTableIds != null && FirstTableIds.length > 0) {
      this.turnOffFilterSync();
    }

    this.firstTableComponent.setShowingOnlyIds(FirstTableIds);
  }

  private turnOffFilterSync() {
    this.areQuickFiltersLinked = false;
    this.linkSearchFieldsSubscription.unsubscribe();
  }

  onQuickFilterChanged(filter: string) {
    this.lastFilterUsed = filter;
  }

  setLinkedTagsQuickFilters() {
    this.linkSearchFieldsSubscription.add(
      this.store
        .select(
          selectFilterTags(
            this.firstTableComponent.configStoreTableName,
            this.firstTableComponent.configStoreRouteIdName ?? ''
          )
        )
        .pipe(skip(1))
        .subscribe((filterTags) =>
          this.store.dispatch(
            quickFilterTagsChange({
              tableName:
                this.projectDeliverableTableComponent.configStoreTableName,
              routeIdName:
                this.projectDeliverableTableComponent.configStoreRouteIdName,
              quickFilterTags: filterTags ?? [],
            })
          )
        )
    );

    this.linkSearchFieldsSubscription.add(
      this.store
        .select(
          selectFilterTags(
            this.projectDeliverableTableComponent.configStoreTableName,
            this.projectDeliverableTableComponent.configStoreRouteIdName ?? ''
          )
        )
        .pipe(skip(1))
        .subscribe((filterTags) =>
          this.store.dispatch(
            quickFilterTagsChange({
              tableName: this.firstTableComponent.configStoreTableName,
              routeIdName: this.firstTableComponent.configStoreRouteIdName,
              quickFilterTags: filterTags ?? [],
            })
          )
        )
    );

    let projectDeliverablesQuickFilterTags =
      this.projectDeliverableTableComponent.quickFilterTags;
    let QuickFilterTagsOne = this.firstTableComponent.quickFilterTags;

    // Check if we should copy quick filter from project deliverable to DA deliverable or vice versa
    if (this.lastFilterUsed === this.Project) {
      this.store.dispatch(
        quickFilterTagsChange({
          tableName: this.firstTableComponent.configStoreTableName,
          routeIdName: this.firstTableComponent.configStoreRouteIdName,
          quickFilterTags: projectDeliverablesQuickFilterTags,
        })
      );
    } else if (
      this.lastFilterUsed === this.Check ||
      this.lastFilterUsed === this.Da
    ) {
      this.store.dispatch(
        quickFilterTagsChange({
          tableName: this.projectDeliverableTableComponent.configStoreTableName,
          routeIdName:
            this.projectDeliverableTableComponent.configStoreRouteIdName,
          quickFilterTags: QuickFilterTagsOne,
        })
      );
    }
  }

  setLinkedTextQuickFilters() {
    this.linkSearchFieldsSubscription.add(
      this.store
        .select(
          selectFilterText(
            this.firstTableComponent?.configStoreTableName,
            this.firstTableComponent.configStoreRouteIdName ?? ''
          )
        )
        .pipe(skip(1))
        .subscribe((filter) =>
          this.store.dispatch(
            quickFilterChange({
              tableName:
                this.projectDeliverableTableComponent.configStoreTableName,
              routeIdName:
                this.projectDeliverableTableComponent.configStoreRouteIdName,
              quickFilterText: filter ?? '',
            })
          )
        )
    );

    this.linkSearchFieldsSubscription.add(
      this.store
        .select(
          selectFilterText(
            this.projectDeliverableTableComponent.configStoreTableName,
            this.projectDeliverableTableComponent.configStoreRouteIdName ?? ''
          )
        )
        .pipe(skip(1))
        .subscribe((filter) =>
          this.store.dispatch(
            quickFilterChange({
              tableName: this.firstTableComponent.configStoreTableName,
              routeIdName: this.firstTableComponent.configStoreRouteIdName,
              quickFilterText: filter ?? '',
            })
          )
        )
    );

    let projectDeliverablesQuickFilter =
      this.projectDeliverableTableComponent.agGrid.quickFilterText ?? '';
    let quickFilterOne = this.firstTableComponent.agGrid.quickFilterText ?? '';

    // clean quick filters if they are showing only mapped deliverables/checks
    if (this.projectDeliverableTableComponent.isDisplayingOnlyIds()) {
      projectDeliverablesQuickFilter = quickFilterOne;
      this.projectDeliverableTableComponent.agGrid.quickFilterText =
        projectDeliverablesQuickFilter;
      this.projectDeliverableTableComponent.setQuickFilter(
        projectDeliverablesQuickFilter
      );
    }

    if (this.firstTableComponent.isDisplayingOnlyIds()) {
      quickFilterOne = projectDeliverablesQuickFilter;
      this.firstTableComponent.agGrid.quickFilterText = quickFilterOne;
      this.firstTableComponent.setQuickFilter(quickFilterOne);
    }

    // Check if we should copy quick filter from project deliverable to Check/DADeliverable or vice versa
    if (this.lastFilterUsed === this.Project) {
      this.store.dispatch(
        quickFilterChange({
          tableName: this.firstTableComponent.configStoreTableName,
          routeIdName: this.firstTableComponent.configStoreRouteIdName,
          quickFilterText: projectDeliverablesQuickFilter,
        })
      );
    } else if (
      this.lastFilterUsed === this.Check ||
      this.lastFilterUsed === this.Da
    ) {
      this.store.dispatch(
        quickFilterChange({
          tableName: this.projectDeliverableTableComponent.configStoreTableName,
          routeIdName:
            this.projectDeliverableTableComponent.configStoreRouteIdName,
          quickFilterText: quickFilterOne,
        })
      );
    }
  }
}
