import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { UserRoles } from 'src/app/shared/utils/constants';
import { environment } from 'src/environments/environment';

export type IdTokenClaims = {
  roles: string[];
};

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private msalService: MsalService) {}

  getCurrentUser() {
    const allCvxAccounts = this.msalService.instance
      .getAllAccounts()
      .filter(
        (account) => account.tenantId === environment.appRegistration.tenantId
      );
    const loggedIn = allCvxAccounts.length > 0;
    if (loggedIn) {
      const account = allCvxAccounts[0];
      this.msalService.instance.setActiveAccount(account);
      return account;
    } else {
      console.log('user not yet signed in');
      return null;
    }
  }

  isDAEngineer() {
    return this.hasRole(UserRoles.DA_ENGINEER);
  }

  isChapterManager() {
    return this.hasRole(UserRoles.CHAPTER_MANAGER);
  }

  isSME() {
    return this.hasRole(UserRoles.SME);
  }

  isProjectContact() {
    return this.hasRole(UserRoles.PROJECT_CONTACT);
  }

  private hasRole(roleName: string) {
    const userInfo = this.getCurrentUser();
    const roles = (userInfo?.idTokenClaims as IdTokenClaims)?.roles;
    return roles !== undefined && roles.includes(roleName);
  }

  isAuthenticated() {
    const userInfo = this.getCurrentUser();
    return userInfo !== null;
  }
}
