import { Injectable } from '@angular/core';
import { Context, Middleware } from '@microsoft/microsoft-graph-client';
import { IRequestCacheService } from 'src/app/shared/services/requestCache.service';
import { GetContextHeaderValue } from '../../shared/utils/graph-client-utils';

@Injectable()
export class CacheMiddleware implements Middleware {
  constructor(private requestCache: IRequestCacheService) {}
  private nextMiddleware!: Middleware;

  public async execute(context: Context): Promise<void> {
    try {
      return await this.handleRequest(context);
    } catch (error) {
      throw error;
    }
  }

  public setNext(next: Middleware): void {
    this.nextMiddleware = next;
  }

  private async handleRequest(context: Context): Promise<void> {
    const cacheExpirationDate = GetContextHeaderValue(
      context,
      IRequestCacheService.EXPIRATION_DATE_HEADER
    );
    if (cacheExpirationDate !== null) {
      const cachedResponse = await this.requestCache.get(context.request);
      if (cachedResponse !== undefined) {
        // we need to clone the object, otherwise it will keep
        // the reference and mess up the async call
        context.response = cachedResponse.clone();
        return;
      }

      // wait until it makes the fetch and save in cache
      await this.nextMiddleware.execute(context);
      await this.requestCache.put(
        context.request,
        context.response!,
        Number.parseInt(cacheExpirationDate)
      );
      return;
    }

    return this.nextMiddleware.execute(context);
  }
}
