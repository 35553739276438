import { Action, createAction, props } from '@ngrx/store';

export type PayloadAction<T> = Action & { payload: T };
export type StoragePayloadSync = PayloadAction<string>;

export const storageActionType =
  '[Local Storage] Sync store selector from local storage';

export const storageSyncAction = createAction(
  storageActionType,
  props<{ payload: string }>()
);
