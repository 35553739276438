import { Pipe, PipeTransform } from '@angular/core';
import { FormControlState } from 'ngrx-forms';

@Pipe({
  name: 'FormErrorsIterable',
})
export class FormErrorsAsIterable implements PipeTransform {
  transform(control: FormControlState<any>, customTexts?: string): string[] {
    if (!control?.errors) {
      return [];
    }
    let errorsArray: string[] = [];
    const customTextsMap = this.getCustomTextsMap(customTexts);
    for (const key in control.errors) {
      if (Object.prototype.hasOwnProperty.call(control.errors, key)) {
        const element = control.errors[key];
        if (customTexts && customTextsMap.has(key)) {
          const text = customTextsMap.get(key);
          if (text !== undefined) {
            errorsArray.push(text);
          }
        } else if (typeof element === 'string') {
          errorsArray.push(element);
        } else {
          errorsArray.push(JSON.stringify(element));
        }
      }
    }
    return errorsArray;
  }

  private getCustomTextsMap(
    customTexts: string | undefined
  ): Map<string, string> {
    const map = new Map();
    const textsAsArray = customTexts?.split(',') ?? [];
    for (let index = 0; index < textsAsArray.length; index = index + 2) {
      const elementKey = textsAsArray[index];
      const elementValue = textsAsArray[index + 1];
      map.set(elementKey, elementValue);
    }

    return map;
  }
}
