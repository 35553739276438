import {
  AfterContentInit,
  Component,
  ContentChild,
  ElementRef,
  Input,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-nickname',
  templateUrl: './nickname.component.html',
})
export class NicknameComponent implements AfterContentInit {
  @Input() originalName: string = '';
  @Input() nickname: string | undefined = '';
  @ContentChild('display') display!: ElementRef;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.originalName || changes.nickname) {
      this.updateElementContent();
    }
  }

  ngAfterContentInit(): void {
    this.updateElementContent();
  }

  private updateElementContent() {
    if (this.display) {
      this.display.nativeElement.innerText = this.nickname || this.originalName;
    }
  }
}
