import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { ColDef } from 'ag-grid-community';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/app.state';
import {
  AgGridCheckboxRendererActions,
  AgGridCheckboxRendererModel,
} from 'src/app/shared/models/ag-grid-checkbox-renderer.model';
import { IPIMSDomain } from 'src/app/shared/models/autogenerated';
import { nameof } from 'src/app/shared/utils/nameof';
import { IPIMSDomainConfig } from '../../models/ipims-dialog-configuration';

@Component({
  selector: 'app-ipims-domain-assign-dialog',
  templateUrl: './ipims-domain-assign-dialog.component.html',
  styleUrls: ['./ipims-domain-assign-dialog.component.scss'],
})
export class IPIMSDomainAssignDialogComponent {
  selection!: IPIMSDomain | null;
  observationDomainAssign = 'workplanDomainAssign';
  projectDeliverableId = 'projectDeliverableId';
  dataSource$!: Observable<IPIMSDomain[] | null>;
  isChooseEnabled: boolean = false;

  private checkboxActions: Map<string, AgGridCheckboxRendererActions> =
    new Map();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IPIMSDomainConfig,
    private dialogRef: MatDialogRef<IPIMSDomainConfig>,
    private store: Store<AppState>
  ) {
    this.selection = data.selectedDomain;

    this.dataSource$ = this.store.select(data.domainsSelector);
    this.store.dispatch(data.domainsLoadAction());
  }

  IPIMsDomainsColumns: ColDef[] = [
    {
      cellRenderer: 'agGridCheckbox',
      cellRendererParams: {
        onInit: this.onInitCheckbox.bind(this),
        onCheckChanged: this.onSelectionChanged.bind(this),
      } as AgGridCheckboxRendererModel<IPIMSDomain>,
      minWidth: 60,
      maxWidth: 60,
      resizable: false,
      type: 'centerAligned',
      pinned: 'left',
      lockVisible: true,
    },
    {
      field: nameof<IPIMSDomain>('domain'),
      headerName: 'domain',
    },
  ];

  clearSelection() {
    this.checkboxActions.forEach((checkBoxAction) =>
      checkBoxAction.setChecked(false)
    );
    this.selection = null;
  }

  private onInitCheckbox(
    data: IPIMSDomain,
    actions: AgGridCheckboxRendererActions
  ) {
    this.checkboxActions.set(data.domain, actions);
    if (data.domain === this.selection?.domain) {
      actions.setChecked(true);
      this.isChooseEnabled = true;
    }
  }

  private onSelectionChanged(
    isChecked: boolean,
    data: IPIMSDomain,
    actions?: AgGridCheckboxRendererActions
  ) {
    this.selection = data;
    if (isChecked && this.selection != null) {
      this.isChooseEnabled = true;
      this.checkboxActions.forEach((action, key) => {
        if (key !== this.selection!.domain) {
          action.setChecked(false);
        }
      });
    } else {
      this.isChooseEnabled = false;
    }
  }

  choose() {
    this.data.onDomainAssign(this.selection!);
    this.closeWindow();
  }

  closeWindow() {
    this.dialogRef.close();
  }
}
