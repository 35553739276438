const time = new Intl.RelativeTimeFormat('en');

// in milliseconds
const units: { [key: string]: number } = {
  year: 24 * 60 * 60 * 1000 * 365,
  month: (24 * 60 * 60 * 1000 * 365) / 12,
  day: 24 * 60 * 60 * 1000,
  hour: 60 * 60 * 1000,
  minute: 60 * 1000,
  second: 1000,
};

export function getRelativeTime(dateTime: Date, dateTimeComparer = new Date()) {
  const elapsed = +dateTime - +dateTimeComparer;

  // "Math.abs" accounts for both "past" & "future" scenarios
  for (let unit in units) {
    if (Math.abs(elapsed) > units[unit]) {
      return time.format(
        Math.round(elapsed / units[unit]),
        <Intl.RelativeTimeFormatUnit>unit
      );
    }
  }

  return 'right now';
}
