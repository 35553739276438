import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map, mergeMap } from 'rxjs/operators';
import { GraphService } from '../../services/graph-service';
import { UserService } from '../../services/user.service';
import {
  loadAccountInfo,
  loadUserPhoto,
  loadUserProfile,
  setCurrentUserAndRoles,
  setCurrentUserPhoto,
  setCurrentUserProfile,
} from './user.actions';

@Injectable()
export class UserEffects {
  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAccountInfo),
      map(() => this.userService.getCurrentUser()),
      map((accountInfo) => setCurrentUserAndRoles({ accountInfo }))
    )
  );

  loadPhoto$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadUserPhoto),
      mergeMap(() => this.graphService.getCurrentUserPhoto()),
      filter((photo) => photo != null),
      map((photo) => setCurrentUserPhoto({ photo }))
    )
  );

  loadProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadUserProfile),
      mergeMap(() => this.graphService.getProfile()),
      map((userProfile) => setCurrentUserProfile({ userProfile }))
    )
  );

  constructor(
    private actions$: Actions,
    private userService: UserService,
    private graphService: GraphService
  ) {}
}
