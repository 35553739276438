import { Component, OnInit } from '@angular/core';
import { ChipInfo, getClassName, getTooltipText } from '../../utils/chip-utils';
import { PluralPipe } from '../../pipes/plural.pipe';

@Component({
  selector: 'app-ag-grid-chip-renderer',
  templateUrl: './ag-grid-chip-renderer.component.html',
  styleUrls: ['./ag-grid-chip-renderer.component.scss'],
})
export class AgGridChipRendererComponent implements OnInit {
  value!: string;
  chipMap!: Map<string, ChipInfo>;
  className: string = '';
  tooltipText: string = '';
  focusAreaCount?: number;

  constructor(private pluralPipe: PluralPipe) {}

  agInit(params: {
    value: string;
    chipMap: Map<string, ChipInfo>;
    focusAreaCount?: number;
  }): void {
    this.value = params.value;
    this.chipMap = params.chipMap;
    this.focusAreaCount = params.focusAreaCount;
  }

  ngOnInit(): void {
    this.className = getClassName(this.value);
    this.tooltipText = getTooltipText(
      this.value,
      this.pluralPipe,
      this.focusAreaCount
    );
  }
}
