import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';
import {
  UserDataClient,
  UserDataViewModel,
} from 'src/app/shared/models/autogenerated';
import { Constants } from 'src/app/shared/utils/constants';
import { WRONG_USER_OID } from 'src/app/shared/utils/graph-client-utils';
import { isUUIDValid } from 'src/app/shared/utils/guid-utils';
import { GraphService } from './graph-service';

@Injectable({
  providedIn: 'root',
})
export class UserDataService {
  private userDataCache$: {
    [oid: string]: Observable<UserDataViewModel | null>;
  } = {};

  constructor(
    private userDataClient: UserDataClient,
    private graphService: GraphService
  ) {}

  getUserByOid(oid: string): Observable<UserDataViewModel | null> {
    if (isUUIDValid(oid) === false) {
      return of(null);
    }

    return this.initUserDataCache(oid);
  }

  getUserNameByOid(oid: string): Observable<string> {
    return isUUIDValid(oid)
      ? this.getUserByOid(oid).pipe(map((user) => user?.displayName ?? oid))
      : of(WRONG_USER_OID);
  }

  getUserName$(userOid: string): Observable<string | null> {
    return this.getUserNameByOid(userOid).pipe(
      catchError(() => of(userOid)),
      map((displayName) => {
        return displayName === null ? userOid : displayName;
      })
    );
  }

  getUserNameAndPhoto$(
    userOid: string,
    photoSize: string = Constants.USER_PHOTO_MINIATURE_SIZE
  ): Observable<string | null>[] {
    return [
      this.getUserName$(userOid),
      this.graphService.getUserPhoto$(userOid, photoSize),
    ];
  }

  private initUserDataCache(oid: string): Observable<UserDataViewModel | null> {
    if (!this.userDataCache$[oid]) {
      this.userDataCache$[oid] = this.userDataClient.getUserData(oid).pipe(
        catchError(() => of(null)),
        shareReplay({ bufferSize: 1, refCount: false })
      );
    }
    return this.userDataCache$[oid];
  }
}
