import { ColDef } from 'ag-grid-community';
import { ExportableColDefWithTitle } from '../store/ag-grid-config/ag-grid-config.reducer';

export const isExportableColumnHeader = (
  columnDef: ColDef
): columnDef is ExportableColDefWithTitle =>
  columnDef !== undefined &&
  (columnDef as ExportableColDefWithTitle).columnExportHeader !== undefined;

export const isExportableColumnCell = (
  columnDef: ColDef
): columnDef is ExportableColDefWithTitle =>
  columnDef !== undefined &&
  (columnDef as ExportableColDefWithTitle).exportValueGetter !== undefined;

export const isNotExportable = (
  columnDef: ColDef
): columnDef is ExportableColDefWithTitle =>
  columnDef !== undefined &&
  (columnDef as ExportableColDefWithTitle).ignoreExport === true;
