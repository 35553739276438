import { createAction, props } from '@ngrx/store';
import { FeatureToggleEntryViewModel } from 'src/app/shared/models/autogenerated';

export const setLastVisitedPage = createAction(
  '[Shared] Set current page as last visited'
);

export const saveLastVisitedPage = createAction(
  '[Shared] Current page saved as last visited',
  props<{
    userRole: string;
    workplanId: string;
    url: string;
  }>()
);

export const clearLastVisitedPages = createAction(
  '[Shared] Clear all pages saved as last visited for the user'
);

export const clearLastVisitedPagesPerRole = createAction(
  '[Shared] Clear current pages saved for role for all workplanIds',
  props<{ userRole: string }>()
);

export const clearLastVisitedPagesPerRoleSuccess = createAction(
  '[Shared] Clear current pages saved for role for all workplanIds Success',
  props<{ userRole: string }>()
);

export const clearLastVisitedPagePerRole = createAction(
  '[Shared] Clear current page saved for role and current navigated workplanId',
  props<{ userRole: string }>()
);

export const clearLastVisitedPagePerRoleSuccess = createAction(
  '[Shared] Clear current page saved for role and current navigated workplanId Success',
  props<{ userRole: string; workplanId: string }>()
);

export const getFeaturesForUserRole = createAction(
  '[Shared] Get features for user role'
);

export const getFeaturesForUserRoleSuccess = createAction(
  '[Shared] Get features for user role Success',
  props<{ userRole: string; featuresForUser: FeatureToggleEntryViewModel[] }>()
);

export const sidebarVisibleChange = createAction(
  '[Shared] Sidebar visibility change',
  props<{ visible: boolean }>()
);
