import { Component, EventEmitter, Input, Output } from '@angular/core';
import { QUICKFILTER_INPUT_PLACEHOLDER } from '../../utils/constants';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';

@Component({
  selector: 'app-input-tags-filter',
  templateUrl: './input-tags-filter.component.html',
  styleUrls: ['./input-tags-filter.component.scss'],
})
export class InputTagsFilterComponent {
  @Input() filterTags: Set<string> = new Set();
  @Input() placeholder: string = QUICKFILTER_INPUT_PLACEHOLDER;

  @Output() filterTagsChanged = new EventEmitter<Set<string>>();

  readonly separatorKeysCodes = [ENTER, COMMA] as const;

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      this.filterTags.add(value);
    }

    event.chipInput!.clear();

    this.filterTagsChanged.next(this.filterTags);
  }

  remove(tag: string): void {
    this.filterTags.delete(tag);
    this.filterTagsChanged.next(this.filterTags);
  }

  onClearFilterTagsClicked() {
    this.filterTags.clear();
    this.filterTagsChanged.next(this.filterTags);
  }
}
