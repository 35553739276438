import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NEW_WORKPLAN_DIALOG_OUTLET } from './new-workplan-flow.constants';
import { NewProjectContactsComponent } from './pages/new-project-contacts/new-project-contacts.component';
import { NewProjectOverviewComponent } from './pages/new-project-overview/new-project-overview.component';

const routes: Routes = [
  {
    path: 'overview',
    component: NewProjectOverviewComponent,
    outlet: NEW_WORKPLAN_DIALOG_OUTLET,
  },
  {
    path: 'contacts',
    component: NewProjectContactsComponent,
    outlet: NEW_WORKPLAN_DIALOG_OUTLET,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes), CommonModule],
  exports: [RouterModule],
})
export class NewWorkplanRoutingModule {}
