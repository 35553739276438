<mat-dialog-content class="adap-dialog-content">
  <app-dialog-header title="automatching"></app-dialog-header>
  <ng-container *ngIf="showDeliverables; else placeholder">
    <ng-container>
      <div class="table-title-container">
        <div class="table-title">DA deliverables</div>
      </div>
      <div class="table-container" *ngIf="currentPageDADeliverable">
        <ag-grid-angular
          [columnDefs]="daDeliverableColumnDefs"
          [rowData]="[
            similarityMap[currentPageDADeliverable.title!][0].daDeliverable
          ]"
          [defaultColDef]="defaultColDef"
          columnHoverHighlight
          rowHoverHighlight
          class="ag-theme-adap da-table"
          [animateRows]="true"
          domLayout="normal"
          rowClass="ag-no-border"
          columnSizeStrategy="sizeColumnsToFit"
          suppressScrollOnNewData
          suppressDragLeaveHidesColumns
        ></ag-grid-angular>
      </div>

      <div class="table-title-container">
        <div class="table-title">project deliverables</div>
      </div>
      <div class="table-container" *ngIf="currentPageProjectDeliverable">
        <ag-grid-angular
          [columnDefs]="projectDeliverableColumnDefs"
          [rowData]="currentPageProjectDeliverable"
          [defaultColDef]="defaultColDef"
          columnHoverHighlight
          rowHoverHighlight
          class="ag-theme-adap project-table"
          (selectionChanged)="onProjectDeliverablesSelectionChanged($event)"
          [animateRows]="true"
          [rowSelection]="'multiple'"
          domLayout="normal"
          rowClass="ag-no-border"
          columnSizeStrategy="sizeColumnsToFit"
          suppressScrollOnNewData
          suppressDragLeaveHidesColumns
          (gridReady)="onGridReady($event)"
        ></ag-grid-angular>
      </div>
    </ng-container>

    <div class="page-bottom">
      <div class="navigation">
        <button
          type="button"
          mat-button
          class="adap-btn-blank nav-button"
          (click)="previousPage()"
        >
          <mat-icon svgIcon="cvx-left"></mat-icon>
        </button>
        <input
          type="number"
          class="page-input"
          [(ngModel)]="currentPage"
          (change)="navigateToPage(currentPage)"
        />
        <span>/ {{ daDeliverables.length }}</span>
        <button
          type="button"
          mat-button
          class="adap-btn-blank nav-button"
          (click)="nextPage()"
        >
          <mat-icon svgIcon="cvx-right"></mat-icon>
        </button>
      </div>

      <div class="dialog-actions">
        <button
          class="adap-btn-outline"
          type="button"
          mat-button
          (click)="closeWindow()"
        >
          Close
        </button>
        <button
          class="adap-btn-filled"
          type="button"
          mat-button
          [disabled]="isMatchButtonDisabled"
          (click)="matchDeliverables()"
        >
          Match
        </button>
      </div>
    </div></ng-container
  >
</mat-dialog-content>

<ng-template #placeholder>
  <app-placeholder
    class="placeholder"
    icon="assets/images/no-systems.svg"
    title="no automatic matches found by name"
    buttonCaption="cancel"
    (buttonClick)="closeWindow()"
  ></app-placeholder>
</ng-template>
