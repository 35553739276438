import {
  Directive,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { AgGridAngular } from 'ag-grid-angular';
import {
  ColDef,
  Column,
  ColumnState,
  GridApi,
  IRowNode,
  RowNode,
} from 'ag-grid-community';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  Subject,
  Subscription,
} from 'rxjs';
import { debounceTime, delay, filter, skip, take } from 'rxjs/operators';
import { AppState } from 'src/app/app.state';
import { FullscreenManagerService } from 'src/app/shared/services/fullscreen-manager.service';
import { ITableEventsService } from 'src/app/shared/services/table-events.service';
import {
  ColumnSizeStrategy,
  ColumnSizeStrategyOptions,
  QuickFilterMode,
  QuickFilterModes,
} from '../ag-grid-config.constants';
import { AgGridCsvExportService } from '../services/ag-grid-csv-export.service';
import { setAgGridConfigForTable } from '../store/ag-grid-config/ag-grid-config.actions';
import { ColDefWithTitle } from '../store/ag-grid-config/ag-grid-config.reducer';
import {
  selectAgGridConfigColumns,
  selectAgGridTableTouched,
  selectAllColumnsFreeze,
  selectFilterMode,
  selectFilterTags,
  selectFilterText,
} from '../store/ag-grid-config/ag-grid-config.selectors';

@Directive({
  selector: 'ag-grid-angular[configStore][configStoreTableName]',
})
export class AgGridConfigStoreDirective
  implements OnInit, OnDestroy, OnChanges
{
  @Input() configStoreTableName!: string;
  @Input() configStoreRouteIdName: string | undefined;
  @Input() columnSizeStrategy: ColumnSizeStrategy =
    ColumnSizeStrategyOptions.none;
  @Input() customIsExternalFilter?: () => boolean;
  @Input() customDoesExternalFilterPass?: (rowNode: RowNode) => boolean;
  @Input() columnDefs!: ColDef[];
  @Input() fullScreenId: string = '';

  public gridApi!: GridApi;
  private subscription = new Subscription();
  private columnStateChanged = new Subject<void>();
  private autoSizeSubject = new BehaviorSubject<void>(void 0);
  private initialColumnDefs!: ColDef[] | undefined;

  private columnDefs$!: Observable<ColDefWithTitle[]>;
  private tableTouched$!: Observable<boolean>;
  private quickFilter$!: Observable<string | undefined>;
  private quickFilterTags$!: Observable<string[] | undefined>;
  private quickFilterMode$!: Observable<QuickFilterMode>;
  private columnSizeStrategy$!: Observable<ColumnSizeStrategy>;
  private freezeAllColumns$!: Observable<boolean>;
  private columnSizeStrategyDone = new Subject<void>();
  private exportCsvFunction!: Function;
  private currentFilterTags: string[] = [];
  private currentFilter: string = '';
  private currentFilterMode: QuickFilterMode = QuickFilterModes.text;

  constructor(
    private agGridComponent: AgGridAngular,
    private store: Store<AppState>,
    private tableEventsService: ITableEventsService,
    private fullScreenManagerService: FullscreenManagerService,
    private csvExportService: AgGridCsvExportService
  ) {}

  ngOnInit(): void {
    this.setAgGridOptions();
    this.columnDefs$ = this.store.select(
      selectAgGridConfigColumns(
        this.configStoreTableName,
        this.configStoreRouteIdName ?? ''
      )
    );

    this.tableTouched$ = this.store.select(
      selectAgGridTableTouched(
        this.configStoreTableName,
        this.configStoreRouteIdName ?? ''
      )
    );

    this.columnSizeStrategy$ = this.tableEventsService.columnResizeStrategy$(
      this.configStoreTableName
    );

    this.freezeAllColumns$ = this.store.select(
      selectAllColumnsFreeze(
        this.configStoreTableName,
        this.configStoreRouteIdName ?? ''
      )
    );

    this.setupEventSubscriptions();

    this.subscription.add(
      this.columnStateChanged.pipe(debounceTime(300)).subscribe(() => {
        this.setColumnDefsState(true);
      })
    );

    this.subscription.add(
      combineLatest([this.agGridComponent.gridReady.pipe(take(1))]).subscribe(
        ([gridReady]) => {
          this.gridApi = gridReady.api;
          this.exportCsvFunction = this.csvExportService.getExporterFunction(
            this.gridApi
          );
        }
      )
    );

    this.quickFilterMode$ = this.store.select(
      selectFilterMode(
        this.configStoreTableName,
        this.configStoreRouteIdName ?? ''
      )
    );

    this.quickFilterTags$ = this.store.select(
      selectFilterTags(
        this.configStoreTableName,
        this.configStoreRouteIdName ?? ''
      )
    );

    this.quickFilter$ = this.store.select(
      selectFilterText(
        this.configStoreTableName,
        this.configStoreRouteIdName ?? ''
      )
    );

    this.subscription.add(
      combineLatest([
        this.quickFilter$,
        this.agGridComponent.gridReady,
      ]).subscribe(([quickFilter, _]) => {
        this.currentFilter = quickFilter ?? '';
        this.agGridComponent.quickFilterText = this.currentFilter;        
        this.agGridComponent.api.setGridOption('quickFilterText', this.currentFilter);
        this.gridApi.redrawRows();
      })
    );

    this.subscription.add(
      combineLatest([
        this.quickFilterMode$,
        this.agGridComponent.gridReady,
      ]).subscribe(([mode, _]) => {
        this.currentFilterMode = mode;
        const quickFIlterText =
          this.currentFilterMode === QuickFilterModes.tags
            ? ''
            : this.currentFilter;
        this.agGridComponent.quickFilterText = quickFIlterText;
        this.agGridComponent.api.setGridOption('quickFilterText', quickFIlterText);
        this.gridApi.onFilterChanged();
        this.gridApi.redrawRows();
      })
    );

    this.subscription.add(
      combineLatest([
        this.quickFilterTags$,
        this.agGridComponent.gridReady,
      ]).subscribe(([quickFilterTags, _]) => {
        this.currentFilterTags = quickFilterTags ?? [];
        this.gridApi.onFilterChanged();
      })
    );

    this.subscription.add(
      combineLatest([
        this.columnDefs$.pipe(take(1)),
        this.tableTouched$.pipe(take(1)),
        this.agGridComponent.gridReady,
        this.autoSizeSubject,
      ]).subscribe(([columnDefs, tableTouched, _]) => {
        this.loadInitialColumnDefs();
        if (columnDefs === undefined || tableTouched === false) {
          this.setColumnSizeStrategy(this.columnSizeStrategy, false);
          this.setColumnDefsState(false);
        }
      })
    );

    this.subscription.add(
      combineLatest([
        this.columnDefs$,
        this.tableTouched$,
        this.autoSizeSubject,
        this.agGridComponent.gridReady,
      ]).subscribe(([columnDefs, tableTouched, _, __]) => {
        if (columnDefs === undefined) {
          this.setColumnDefsState(false);
        } else {
          this.gridApi.applyColumnState({
            state: columnDefs as ColumnState[],
            defaultState: {
              // important to say 'null' as undefined means 'do nothing'
              sort: null,
              rowGroup: null,
              pivot: null,
              pinned: null,
            },
            applyOrder: true,
          });

          if (tableTouched === false) {
            this.setColumnSizeStrategy(this.columnSizeStrategy, false);
          }
        }
      })
    );

    this.subscription.add(
      combineLatest([
        this.columnSizeStrategy$,
        this.agGridComponent.gridReady,
      ]).subscribe(([strategy, _]) => {
        this.setColumnSizeStrategy(strategy);
      })
    );

    this.subscription.add(
      combineLatest([
        this.freezeAllColumns$,
        this.agGridComponent.gridReady,
      ]).subscribe(([freeze, _]) => {
        const columnDefsFreeze = this.gridApi.getColumnDefs()?.map((x) => {
          return {
            ...x,
            suppressAutoSize: freeze,
            suppressMovable: freeze,
            suppressSizeToFit: freeze,
            lockPinned: freeze,
            resizable: !freeze,
          };
        });
        if (columnDefsFreeze !== undefined) {
          this.gridApi.updateGridOptions({ columnDefs: columnDefsFreeze });
        }
      })
    );

    this.subscription.add(
      combineLatest([
        this.tableTouched$,
        this.freezeAllColumns$,
        this.fullScreenManagerService.subscribeToFullScreen(this.fullScreenId),
        this.agGridComponent.gridReady,
      ])
        .pipe(skip(1), delay(100))
        .subscribe(([touched, freeze, _, __]) => {
          if (touched === false && freeze === false) {
            this.setColumnSizeStrategy(this.columnSizeStrategy, false);
          }
        })
    );

    this.subscription.add(
      combineLatest([
        this.agGridComponent.gridReady,
        this.tableEventsService.exportToCsv$(this.configStoreTableName),
      ]).subscribe(([_, csvExportArgs]) => {
        this.exportCsvFunction(this.gridApi.getColumns(), csvExportArgs);
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.columnDefs != null) {
      this.autoSizeSubject.next();
    }
  }

  private setColumnSizeStrategy(
    strategy: ColumnSizeStrategy,
    saveColumnDef: boolean = true
  ) {
    const sub = this.columnSizeStrategyDone.pipe(take(1)).subscribe(() => {
      if (saveColumnDef) {
        this.setColumnDefsState(true);
      }
    });
    switch (strategy) {
      case ColumnSizeStrategyOptions.autoSizeAllColumns:
        this.gridApi.autoSizeAllColumns();
        break;
      case ColumnSizeStrategyOptions.autoSizeAllColumnsSkipHeader:
        this.gridApi.autoSizeAllColumns(true);
        break;
      case ColumnSizeStrategyOptions.sizeColumnsToFit:
        this.gridApi.sizeColumnsToFit();
        break;
      default:
        sub?.unsubscribe();
    }
  }

  private loadInitialColumnDefs() {
    this.initialColumnDefs = this.gridApi.getColumnDefs();
    this.initialColumnDefs?.forEach((colDef) => {
      // Set the default hide value as boolean (do not leave it undefined, or the column will be not visible on reset)
      colDef.hide = colDef.hide === true;
    });
  }

  private setupEventSubscriptions() {
    this.subscription.add(
      this.agGridComponent.columnVisible
        .pipe(filter((event) => event.source === 'uiColumnDragged'))
        .subscribe(() => this.columnStateChanged.next())
    );

    this.subscription.add(
      this.agGridComponent.columnMoved
        .pipe(
          filter(
            (event) =>
              event.source === 'uiColumnMoved' ||
              event.source === 'uiColumnDragged'
          )
        )
        .subscribe(() => this.columnStateChanged.next())
    );

    this.subscription.add(
      this.agGridComponent.columnPinned
        .pipe(filter((event) => event.source === 'uiColumnDragged'))
        .subscribe(() => this.columnStateChanged.next())
    );

    this.subscription.add(
      this.agGridComponent.columnResized
        .pipe(
          filter(
            (event) =>
              event.source === 'uiColumnResized' ||
              event.source === 'uiColumnDragged'
          )
        )
        .subscribe(() => this.columnStateChanged.next())
    );

    this.subscription.add(
      this.agGridComponent.sortChanged.subscribe(() =>
        this.columnStateChanged.next()
      )
    );

    this.subscription.add(
      this.agGridComponent.columnResized
        .pipe(
          filter(
            (event) =>
              event.source === 'autosizeColumns' ||
              event.source === 'sizeColumnsToFit'
          )
        )
        .subscribe(() => this.columnSizeStrategyDone.next())
    );
  }

  private setColumnDefsState(touched: boolean) {
    const columnDefs = touched
      ? this.gridApi.getColumnDefs()
      : this.initialColumnDefs;
    const columnDefsWithTitles = (columnDefs ?? []).map((def) => {
      const column = this.gridApi.getColumn((def as ColumnState).colId);
      return {
        ...def,
        columnTitle: column
          ? this.gridApi.getDisplayNameForColumn(column, null)
          : def.headerName,
      } as ColDefWithTitle;
    });

    this.store.dispatch(
      setAgGridConfigForTable({
        tableName: this.configStoreTableName,
        routeIdName: this.configStoreRouteIdName ?? '',
        columnDefs: columnDefsWithTitles,
        touched,
      })
    );
  }

  private setAgGridOptions() {
    this.agGridComponent.gridOptions = {
      // We need this to suppress exportable columns
      // https://www.ag-grid.com/angular-data-grid/grid-options/#reference-miscellaneous-suppressPropertyNamesCheck
      suppressPropertyNamesCheck: true,
      ...this.agGridComponent.gridOptions,
    };

    this.agGridComponent.isExternalFilterPresent = (() =>
      (this.customIsExternalFilter?.() ?? false) ||
      this.tagsExternalFilterPresent.call(this)).bind(this);

      this.agGridComponent.doesExternalFilterPass = ((node: IRowNode<any>) =>
        (this.customDoesExternalFilterPass?.(node as RowNode<any>) ?? false) ||
        this.doesTagsFilterPass.call(this, node as RowNode<any>)).bind(this);
  }

  private tagsExternalFilterPresent() {
    return this.currentFilterMode === QuickFilterModes.tags;
  }

  private doesTagsFilterPass(node: RowNode) {
    if (!this.tagsExternalFilterPresent()) {
      return false;
    }
    const columns = this.gridApi?.getAllGridColumns() ?? [];
    return columns!.some((col: Column) => {
      const cellValue = node.data[col.getColId()];
      if (
        this.currentFilterTags == null ||
        this.currentFilterTags.length === 0
      ) {
        return true;
      }

      return this.currentFilterTags!.every(
        (tag) => cellValue?.toLowerCase().includes(tag.toLowerCase()) ?? false
      );
    });
  }
}
