import {
  Component,
  OnDestroy,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.state';
import { selectShowLoadingSpinner } from '../../store/shared.selectors';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  animations: [
    trigger('delayedShow', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms step-end', style({ opacity: 1 })),
      ]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpinnerComponent implements OnDestroy {
  private showSpinnerSubscription: Subscription | null = null;
  showSpinner = false;

  constructor(
    private store: Store<AppState>,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.showSpinnerSubscription = this.store
      .select(selectShowLoadingSpinner)
      .subscribe((showSpinner) => {
        this.showSpinner = showSpinner;
        this.cdRef.detectChanges(); // Trigger change detection manually
      });
  }

  ngOnDestroy() {
    this.showSpinnerSubscription?.unsubscribe();
  }
}
