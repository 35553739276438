import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  DetachedRouteHandle,
  RouteReuseStrategy,
} from '@angular/router';

/**
 * This class is implemented so that two routes that point to the same
 * component can be reused (not destroyed and rendered again).
 *
 * For example:
 *
 * - da-engineer/project-dashboard/my-projects
 * - da-engineer/project-dashboard/all-projects
 *
 * By default Angular will rebuild the component, because the route configuration
 * does not match between them, even if they point to the same component.
 *
 * To define when to reuse, attach this data to the routes configuration:
 * ```
 *      data: {
 *         reuse: true
 *     }
 * ```
 * that share the same component but differ slightly in the url.
 *
 * Only the shouldReuseRoute method has been overriden, the rest of the
 * implementation has been copied from router.js from the
 * angular package
 */
@Injectable()
export class CustomRouteReuseStrategy implements RouteReuseStrategy {
  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }

  store(
    route: ActivatedRouteSnapshot,
    handle: DetachedRouteHandle | null
  ): void {}

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    return null;
  }

  shouldReuseRoute(
    future: ActivatedRouteSnapshot,
    curr: ActivatedRouteSnapshot
  ): boolean {
    let reUseUrl = false;
    if (future.routeConfig && future.routeConfig.data) {
      reUseUrl = future.routeConfig.data.reuse;
    }

    const defaultReuse = future.routeConfig === curr.routeConfig;
    return reUseUrl || defaultReuse;
  }
}
