import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ZeroDash',
})
export class ZeroDashPipe implements PipeTransform {
  transform(value: any): any {
    return value || '-';
  }
}
