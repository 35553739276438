<ng-container *ngIf="currentPhase$ | async as currentPhase">
  <div class="container">
    <button
      click-stop-propagation
      class="adap-btn-grid-cell mapping"
      type="button"
      matTooltip="show mappings"
      matTooltipPosition="above"
      (click)="showMappings()"
      [disabled]="totalMappings == 0 || currentPhase.isReadOnly"
    >
      <span class="mapping-number">{{ totalMappings }}</span>
      <mat-icon class="mapping-icon">link</mat-icon>
    </button>
    <button
      click-stop-propagation
      class="adap-btn-grid-cell"
      type="button"
      matTooltip="delete mappings"
      matTooltipPosition="above"
      (click)="deleteMappings()"
      [disabled]="totalMappings == 0 || currentPhase.isReadOnly"
    >
      <mat-icon svgIcon="cvx-trash"></mat-icon>
    </button>
  </div>
</ng-container>
