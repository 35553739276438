<mat-dialog-content class="adap-dialog-content">
  <app-dialog-header [title]="data.title"></app-dialog-header>
  {{ data.description }}
  <ng-container *ngIf="data.form$ | async as formState; else noForm">
    <ng-template
      class="input-container"
      [ngTemplateOutlet]="data.formTemplate"
      [ngTemplateOutletContext]="{
        formState: formState,
        errorMappings: data.errorTextMappings
      }"
    ></ng-template>
    <div class="dialog-actions">
      <button class="adap-btn-outline" type="button" (click)="cancel()">
        cancel
      </button>
      <button
        class="adap-btn-filled"
        (click)="confirm()"
        [disabled]="!formState.isValid || formState.isValidationPending"
      >
        confirm
      </button>
    </div>
  </ng-container>
  <ng-template #noForm>
    <ng-template
      class="input-container"
      [ngTemplateOutlet]="data.formTemplate"
      [ngTemplateOutletContext]="{
        errorMappings: data.errorTextMappings
      }"
    ></ng-template>
    <div class="dialog-actions">
      <button class="adap-btn-outline" type="button" (click)="cancel()">
        cancel
      </button>
      <button class="adap-btn-filled" (click)="confirm()">confirm</button>
    </div>
  </ng-template>
</mat-dialog-content>
