<button class="adap-btn-blank user-container">
  <div
    class="user-info"
    *ngIf="currentUserProfile$ | async as currentUserProfile"
  >
    {{ currentUserProfile.name }}
  </div>
  <div class="user-image-container">
    <img
      alt="user image"
      *ngIf="userImage$ | async as userImage; else defaultUserIcon"
      [src]="userImage | SafeUrl"
      class="user-photo"
    />
    <ng-template #defaultUserIcon>
      <mat-icon
        class="user-photo-placeholder"
        svgIcon="cvx-person-empty"
      ></mat-icon>
    </ng-template>
  </div>
</button>
