import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-text-area-with-copy',
  templateUrl: './text-area-with-copy.component.html',
  styleUrls: ['./text-area-with-copy.component.scss'],
})
export class TextAreaWithCopy {
  @Input() copyLabel: string = 'copy';
  @Input() isRequired: boolean = false;
  @Input() editableTextLabel!: string;
  @Input() editableTextFormControl!: any;
  @Input() editableTextMaxLength: number = 1000;
  @Input() textAreaHeight: string = '100px';
  @Input() showCopyLabel: boolean = false;
  @Output() copyEvent: EventEmitter<void> = new EventEmitter();
}
