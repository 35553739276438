export enum ColumnSizeStrategyOptions {
  autoSizeAllColumns = 'autoSizeAllColumns',
  autoSizeAllColumnsSkipHeader = 'autoSizeAllColumnsSkipHeader',
  sizeColumnsToFit = 'sizeColumnsToFit',
  none = 'none',
}
export type ColumnSizeStrategy = keyof typeof ColumnSizeStrategyOptions;

export enum QuickFilterModes {
  text = 'text',
  tags = 'tags',
}
export type QuickFilterMode = keyof typeof QuickFilterModes;
