import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActionDialogComponent } from '../../components/action-dialog/action-dialog.component';
import {
  openActionDialog,
  openDeleteConfirmationDialog,
} from './action-dialog.actions';
import { ActionDialogData } from '../../models/action-dialog-data';
import { ActionDialogType } from '../../models/enums/action-dialog-type.enum';

@Injectable()
export class ActionDialogEffects {
  constructor(private actions$: Actions, private dialog: MatDialog) {}
  private dialogRef!: MatDialogRef<ActionDialogComponent>;

  openActionDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(openActionDialog),
        map((data) => {
          this.openDialog(data);
        })
      ),
    { dispatch: false }
  );

  openDeleteConfirmationDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(openDeleteConfirmationDialog),
        map(({ onConfirm, description }) => {
          this.openDialog({
            title: 'delete item(s)',
            description: description,
            confirmButtonText: 'delete',
            dialogType: ActionDialogType.DeleteConfirmation,
            onConfirm,
          } as ActionDialogData);
        })
      ),
    { dispatch: false }
  );

  private openDialog(data: ActionDialogData) {
    const onCancel = data.onCancel ?? undefined;
    const onConfirm = data.onConfirm ?? undefined;

    data = {
      ...data,
      onCancel: () => {
        this.closeWindowAndCallAction(onCancel);
      },
      onConfirm: () => {
        this.closeWindowAndCallAction(onConfirm);
      },
    };

    this.dialogRef = this.dialog.open(ActionDialogComponent, {
      panelClass: ['generic-action-dialog', data.panelClass ?? ''],
      maxWidth: 'unset',
      data,
    });
    this.dialogRef.afterClosed().subscribe(() => {
      data.onClose?.();
    });
  }

  private closeWindowAndCallAction(action?: () => void) {
    this.dialogRef.close();
    action?.();
  }
}
