import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserState } from './user.reducer';

export const selectUserFeature = createFeatureSelector<UserState>('user');

export const selectUserAccount = createSelector(
  selectUserFeature,
  (state) => state.accountInfo
);

export const selectUserPhoto = createSelector(
  selectUserFeature,
  (state) => state.userPhoto
);

export const selectRoles = createSelector(
  selectUserFeature,
  (state) => state.roles
);
