import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

export interface ILinkCellRendererParams extends ICellRendererParams {
  link?: string;
  showOpenInNewTabIcon?: boolean;
}

@Component({
  selector: 'link-renderer',
  templateUrl: './ag-grid-link-renderer.component.html',
  styleUrls: ['./ag-grid-link-renderer.component.scss'],
})
export class AgGridLinkRendererComponent implements ICellRendererAngularComp {
  params!: ILinkCellRendererParams;

  agInit(params: ILinkCellRendererParams): void {
    this.params = params;
  }

  refresh(params: ILinkCellRendererParams): boolean {
    return false;
  }
}
