import { Component } from '@angular/core';
import {
  AgGridCheckboxRendererActions,
  AgGridCheckboxRendererModel,
} from '../../models/ag-grid-checkbox-renderer.model';

@Component({
  selector: 'app-ag-grid-checkbox-renderer',
  templateUrl: './ag-grid-checkbox-renderer.component.html',
  styleUrls: ['./ag-grid-checkbox-renderer.component.scss'],
})
export class AgGridCheckboxRendererComponent {
  isChecked: boolean = false;
  isHidden: boolean = false;
  isIcon: boolean = false;
  isDisabled: boolean = false;
  icon!: string;
  tooltipText: string = '';
  private params!: AgGridCheckboxRendererModel<any>;
  private actions: AgGridCheckboxRendererActions;

  constructor() {
    this.actions = {
      hide: (hide) => (this.isHidden = hide),
      setChecked: (isChecked) => {
        this.isChecked = isChecked;
      },
      setDisabled: (isDisabled) => (this.isDisabled = isDisabled),
      turnCheckboxIntoIcon: (icon, tooltipText) => {
        this.isIcon = true;
        this.icon = icon;
        this.tooltipText = tooltipText;
      },
    };
  }

  agInit(params: AgGridCheckboxRendererModel<any>) {
    this.params = params;
    params.onInit(params.data, this.actions);
  }

  onChange() {
    this.params.onCheckChanged(this.isChecked, this.params.data, this.actions);
  }

  onIconClick() {
    this.params.onIconClicked(this.params.data);
  }
}
