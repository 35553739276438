import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { AppState } from 'src/app/app.state';
import { setAgGridConfigForTable } from '../../store/ag-grid-config/ag-grid-config.actions';
import {
  AgGridConfig,
  ColDefWithTitle,
} from '../../store/ag-grid-config/ag-grid-config.reducer';
import { selectAgGridConfigForTable } from '../../store/ag-grid-config/ag-grid-config.selectors';

@Component({
  selector: 'app-column-list-dropdown',
  templateUrl: './column-list-dropdown.component.html',
  styleUrls: ['./column-list-dropdown.component.scss'],
})
export class ColumnListDropdownComponent implements OnInit {
  @Input() configStoreTableName!: string;
  @Input() configStoreRouteIdName: string | undefined;
  allSelectedValue = '__ALL_SELECTED__';

  tableConfig$!: Observable<AgGridConfig>;
  selectedColumns$!: Observable<string[]>;

  private subscription = new Subscription();

  public constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.tableConfig$ = this.store.select(
      selectAgGridConfigForTable(
        this.configStoreTableName,
        this.configStoreRouteIdName ?? ''
      )
    );
    this.selectedColumns$ = this.tableConfig$.pipe(
      filter((config) => config != null),
      map((config) => {
        const result = (config.columnDefs ?? [])
          .filter(
            (column) =>
              (column.hide === false || column.hide === undefined) &&
              column.colId != null
          )
          .map((column) => column.colId as string);
        if (result.length === config.columnDefs?.length) {
          result.push(this.allSelectedValue);
        }
        return result;
      })
    );
  }

  selectedColumnsChange(event: string[]) {
    this.subscription.add(
      this.tableConfig$.pipe(take(1)).subscribe((tableConfig) => {
        let newColumnDefs = tableConfig.columnDefs?.map((column) => ({
          ...column,
          hide:
            !event.includes(column.colId ?? '') &&
            this.shouldDisplayOption(column),
        }));
        this.store.dispatch(
          setAgGridConfigForTable({
            tableName: this.configStoreTableName,
            routeIdName: this.configStoreRouteIdName ?? '',
            columnDefs: newColumnDefs,
            touched: true,
          })
        );
      })
    );
  }

  selectAll(columnDefs: ColDefWithTitle[]) {
    this.selectedColumnsChange(
      columnDefs
        .map((def) => def.colId)
        .filter((id): id is string => id != null)
    );
  }

  shouldDisplayOption(columnDef: ColDefWithTitle) {
    return columnDef.columnTitle !== '';
  }
}
