import { createAction, props } from '@ngrx/store';
import { ActionDialogData } from '../../models/action-dialog-data';

export const openActionDialog = createAction(
  '[Action Dialog] Open action dialog',
  props<ActionDialogData>()
);

// openDeleteConfirmationDialog wraps the same functionality as openActionDialog with delete confirmation configuration to be easier to reuse it.
export const openDeleteConfirmationDialog = createAction(
  '[Action Dialog] Open delete confirmation dialog',
  props<{ onConfirm: () => void; description: string }>()
);
