<div class="snack-icons">
  <mat-icon>sms</mat-icon>
  <button class="close" (click)="dismiss()" mat-icon-button aria-label="close">
    <mat-icon svgIcon="cvx-close"></mat-icon>
  </button>
</div>
<button class="content" (click)="openFeedback()">
  <p class="title">We're improving ADAP!</p>
  <button class="link">
    Let us know how we can make your experience better.
  </button>
</button>
