import { Directive, forwardRef } from '@angular/core';
import { FormViewAdapter, NGRX_FORM_VIEW_ADAPTER } from 'ngrx-forms';
import { IPIMSDomain } from 'src/app/shared/models/autogenerated';
import { IPIMSDomainSelectorInputComponent } from '../components/ipims-domain-selector-input/ipims-domain-selector-input.component';

@Directive({
  selector: 'app-ipims-domain-selector-input[ngrxFormControlState]',
  providers: [
    {
      provide: NGRX_FORM_VIEW_ADAPTER,
      useExisting: forwardRef(() => IPIMSDomainAssignAdapter),
      multi: true,
    },
  ],
})
export class IPIMSDomainAssignAdapter implements FormViewAdapter {
  constructor(
    private ipimsDomainAssignComponent: IPIMSDomainSelectorInputComponent
  ) {}

  setViewValue(value: any): void {
    if (value) {
      this.ipimsDomainAssignComponent.setValueSubject.next({
        domain: value,
      } as IPIMSDomain);
    }
  }

  setOnChangeCallback(fn: (value: any) => void): void {
    this.ipimsDomainAssignComponent.domainSelectionChanged.subscribe((domain) =>
      fn(domain?.domain ?? null)
    );
  }
  setOnTouchedCallback(fn: any): void {
    this.ipimsDomainAssignComponent.registerOnTouched(fn);
  }

  setIsDisabled(isDisabled: boolean) {
    // we handle this in the component itself because of expression changed error
  }
}
