import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/app.state';
import { AgGridConfig } from '../../store/ag-grid-config/ag-grid-config.reducer';
import { selectAgGridConfigForTable } from '../../store/ag-grid-config/ag-grid-config.selectors';

@Component({
  selector: 'app-ag-grid-options',
  templateUrl: './ag-grid-options.component.html',
  styleUrls: ['./ag-grid-options.component.scss'],
})
export class AgGridOptionsComponent implements OnInit {
  @Input() showQuickFilter: boolean = true;
  @Input() showColumnsDropdown: boolean = true;
  @Input() configStoreTableName!: string;
  @Input() configStoreRouteIdName: string | undefined;
  @Input() disableColumnSizeStrategies: boolean = false;
  @Input() disableExportToCsv: boolean = false;
  @Input() tagsMode: boolean = false;

  tableConfig$!: Observable<AgGridConfig>;

  public constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.tableConfig$ = this.store.select(
      selectAgGridConfigForTable(
        this.configStoreTableName,
        this.configStoreRouteIdName ?? ''
      )
    );
  }
}
