import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControlState } from 'ngrx-forms';
import { Constants } from '../../utils/constants';

@Component({
  selector: 'app-input-container',
  templateUrl: './input-container.component.html',
  styleUrls: ['./input-container.component.scss'],
  host: {
    class: 'input-container',
  },
})
export class InputContainerComponent {
  @Input() label!: string;
  @Input() labelFor!: string;
  @Input() boldLabel: boolean = false;
  @Input() isRequired!: boolean;
  @Input() characterCounterLength!: number;
  @Input() characterCounterMaxLength!: number;
  @Input() info!: string;
  @Input('app-input-validation-container')
  inputValidationContainer!: FormControlState<any>;
  @Input() copy: boolean = false;
  @Output() copyEvent: EventEmitter<void> = new EventEmitter();

  REQUIRED_MAPPING_ERROR = Constants.REQUIRED_MAPPING_ERROR;
}
