import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-separator-with-text',
  templateUrl: './separator-with-text.component.html',
  styleUrls: ['./separator-with-text.component.scss'],
})
export class SeparatorWithTextComponent {
  @Input() text!: string;
  @Input() textMargin!: number;
}
