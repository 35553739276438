<ng-container *ngIf="quickFilterMode$ | async as mode">
  <ng-container *ngIf="tagsMode && !isReadOnly">
    <button
      class="filter-mode-toggle adap-btn-blank"
      (click)="toggleFilterMode(mode)"
      [matTooltip]="
        mode === QuickFilterModes.text ? 'toggle tags' : 'toggle text'
      "
    >
      <mat-icon *ngIf="mode === QuickFilterModes.text"
        >text_rotation_none</mat-icon
      >
      <mat-icon *ngIf="mode === QuickFilterModes.tags">bookmarks</mat-icon>
    </button>
  </ng-container>

  <app-input-filter
    *ngIf="mode === QuickFilterModes.text || isReadOnly"
    [filterText]="(quickFilterText$ | async)!"
    (filterChanged)="onQuickFilterChanged($event)"
    [isReadOnly]="isReadOnly"
    [placeholder]="placeholder"
    class="input-filter"
  ></app-input-filter>

  <app-input-tags-filter
    *ngIf="mode === QuickFilterModes.tags && !isReadOnly"
    [filterTags]="(quickFilterTags$ | async)!"
    (filterTagsChanged)="onQuickFilterTagsChanged($event)"
    [placeholder]="placeholder"
    class="input-filter"
  >
  </app-input-tags-filter>
</ng-container>
