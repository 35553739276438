<button
  class="adap-btn-blank"
  [ngClass]="buttonClass"
  (click)="toggle(fullScreen.fullScreenId)"
  *ngIf="isFullscreen$ | async as fullScreen"
>
  <mat-icon
    *ngIf="!fullScreen.isFullScreen"
    matTooltip="fullscreen"
    matTooltipPosition="above"
    matTooltipShowDelay="1000"
    >fullscreen</mat-icon
  >
  <mat-icon
    *ngIf="fullScreen.isFullScreen"
    matTooltip="close fullscreen"
    matTooltipPosition="above"
    matTooltipShowDelay="1000"
    >close_fullscreen</mat-icon
  >
</button>
