import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
@Component({
  selector: 'app-project-deliverable-carryover-renderer',
  templateUrl: './project-deliverable-carryover-renderer.component.html',
  styleUrls: ['./project-deliverable-carryover-renderer.component.scss'],
})

export class ProjectDeliverableCarryoverRendererComponent {
  params?: ICellRendererParams;
  // gets called once before the renderer is used
  agInit(params: ICellRendererParams): void {
    this.params = params;
  }
}

