import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from 'src/app/app.state';
import { UserService } from 'src/app/core/services/user.service';
import { loadAllProjects } from 'src/app/roles/da-engineer/project-dashboard/store/project.actions';
import {
  BusinessUnitDAEngineerClient,
  ProjectDAEngineerClient,
  ProjectPhaseTemplateDAEngineerClient,
  WorkplanDAEngineerClient,
} from 'src/app/shared/models/autogenerated';
import { ToastMessagesService } from 'src/app/shared/services/toast-messages.service';
import {
  finishLoading,
  startLoading,
} from 'src/app/shared/store/shared.operators';
import {
  createNewProject,
  createNewWorkplanForProject,
  createNewWorkplanForProjectSuccess,
  createNewWorkplanSuccess,
  loadBusinessUnits,
  loadNewProjectAllPhases,
  loadProjectToNewProjectDialog,
  setBusinessUnits,
  setEditProjectToNewProjectDialog,
  setNewProjectAllPhases,
  setNewProjectAvailablePhases,
  setNewProjectDALead,
} from './new-project.actions';
import { selectNewWorkplanForm } from './new-project.selectors';

@Injectable()
export class NewWorkplanEffects {
  createNewProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createNewProject),
      startLoading(this.store),
      withLatestFrom(this.store.select(selectNewWorkplanForm)),
      mergeMap(([_, newWorkplan]) =>
        this.workplanService.create({
          ...newWorkplan.value,
          themes: newWorkplan.value.themes.value,
          projectContacts: newWorkplan.value.projectContacts.value.filter(
            (x) => x
          ),
        } as any)
      ),
      map((project) => createNewWorkplanSuccess({ project })),
      finishLoading(this.store)
    )
  );

  createNewWorkplanForProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createNewWorkplanForProject),
      startLoading(this.store),
      withLatestFrom(this.store.select(selectNewWorkplanForm)),
      mergeMap(([_, newWorkplan]) =>
        this.workplanService.create({
          ...newWorkplan.value,
          copyFromPhase:
            newWorkplan.value.copyDADeliverables === true ||
            newWorkplan.value.copyProjectDeliverables === true ||
            newWorkplan.value.copyDeliverableMappings === true ||
            newWorkplan.value.copyPeerPool === true ||
            newWorkplan.value.copyRiskAssessment === true
              ? newWorkplan.value.copyFromPhase
              : null,
          themes: newWorkplan.value.themes.value,
          projectContacts: newWorkplan.value.projectContacts.value.filter(
            (x) => x
          ),
        } as any)
      ),
      map((project) => createNewWorkplanForProjectSuccess({ project })),
      finishLoading(this.store)
    )
  );

  loadProjectToNewWorkplan$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadProjectToNewProjectDialog),
      mergeMap(({ projectId }) =>
        this.projectService
          .getById(projectId)
          .pipe(
            mergeMap((project) => [
              setEditProjectToNewProjectDialog({ project }),
              setNewProjectAvailablePhases(),
            ])
          )
      )
    )
  );

  loadBusinessUnits$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadBusinessUnits),
      startLoading(this.store),
      mergeMap(() =>
        this.businessUnitService
          .getAll()
          .pipe(map((businessUnits) => setBusinessUnits({ businessUnits })))
      ),
      finishLoading(this.store)
    )
  );

  loadAllPhases$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadNewProjectAllPhases),
      mergeMap(() =>
        this.projectPhaseTemplateService.getAll().pipe(
          mergeMap((phases) => [
            setNewProjectAllPhases({
              allPhases: phases?.map((ph) => ph.phaseId) ?? [],
            }),
            setNewProjectAvailablePhases(),
            setNewProjectDALead({
              daLead: this.userService.getCurrentUser()!.localAccountId,
            }),
          ])
        )
      )
    )
  );

  reloadProjectsAfterWorkplanCreate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createNewWorkplanSuccess),
      map(() => loadAllProjects())
    )
  );

  reloadProjectsAfterProjectCreate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createNewWorkplanForProjectSuccess),
      map(() => loadAllProjects())
    )
  );

  toastAfterCreate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createNewWorkplanSuccess),
        map(({ project }) => {
          this.toastMessagesService.displaySuccess(
            `Success: new project "${
              project!.name
            }" created. More details in my dashboard`
          );
        })
      ),
    { dispatch: false }
  );

  toastAfterCreateForProject$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createNewWorkplanForProjectSuccess),
        map(() => {
          this.toastMessagesService.displaySuccess(
            `Success: new workplan created.`
          );
        })
      ),
    { dispatch: false }
  );

  constructor(
    private store: Store<AppState>,
    private actions$: Actions,
    private workplanService: WorkplanDAEngineerClient,
    private projectService: ProjectDAEngineerClient,
    private projectPhaseTemplateService: ProjectPhaseTemplateDAEngineerClient,
    private businessUnitService: BusinessUnitDAEngineerClient,
    private toastMessagesService: ToastMessagesService,
    private userService: UserService
  ) {}
}
