<mat-form-field class="adap-select display-view-select" appearance="outline">
  <mat-select
    #displayViewMatSelect
    multiple
    [(ngModel)]="displayViewSelected"
    (selectionChange)="displayViewSelectedChange()"
    placeholder="view"
  >
    <mat-option [value]="MATCHED">
      {{ MATCHED }}
    </mat-option>
    <mat-option [value]="UNMATCHED">
      {{ UNMATCHED }}
    </mat-option>
    <mat-option [value]="UNMATCHED_DA_DELIVERABLES">
      {{ UNMATCHED_DA_DELIVERABLES }}
    </mat-option>
    <mat-option [value]="UNMATCHED_PROJECT_DELIVERABLES">
      {{ UNMATCHED_PROJECT_DELIVERABLES }}
    </mat-option>
  </mat-select>
</mat-form-field>
