import { createFeatureSelector, createSelector } from '@ngrx/store';
import { NewWorkplanState } from './new-project.reducer';

export const selectNewProjectFeature =
  createFeatureSelector<NewWorkplanState>('newProject');

export const selectNewWorkplanForm = createSelector(
  selectNewProjectFeature,
  (state) => state.newWorkplanForm
);

export const selectNewProjectAvailablePhases = createSelector(
  selectNewProjectFeature,
  (state) => state.availablePhases
);

export const selectNewProjectCreatedProject = createSelector(
  selectNewProjectFeature,
  (state) => state.createdProject
);

export const selectExistingProjectForNewProject = createSelector(
  selectNewProjectFeature,
  (state) => state.existingProject
);

export const selectNewWorkplanIsValid = createSelector(
  selectNewProjectFeature,
  (state) => state.newWorkplanForm.isValid
);

export const selectBusinessUnits = createSelector(
  selectNewProjectFeature,
  (state) => state.businessUnits
);

export const selectLeadByList = createSelector(
  selectNewProjectFeature,
  (state) => state.leadByList
);
