import { Injectable } from '@angular/core';
import { ProjectDeliverableAutomatchViewModel } from 'src/app/roles/da-engineer/screening/models/project-deliverable-automatch.model';
import {
  DADeliverableWithMappingsViewModel,
  ProjectDeliverableWithMappingsViewModel,
} from 'src/app/shared/models/autogenerated';

type SimilarityMap = {
  [key: string]: {
    daDeliverable: DADeliverableWithMappingsViewModel;
    projectDeliverable: ProjectDeliverableAutomatchViewModel;
  }[];
};

@Injectable({
  providedIn: 'root',
})
export class StringMatchingService {
  private levenshteinDistance(a: string, b: string): number {
    const matrix = [];

    for (let i = 0; i <= b.length; i++) {
      matrix[i] = [i];
    }

    for (let j = 0; j <= a.length; j++) {
      matrix[0][j] = j;
    }

    for (let i = 1; i <= b.length; i++) {
      for (let j = 1; j <= a.length; j++) {
        if (b.charAt(i - 1) === a.charAt(j - 1)) {
          matrix[i][j] = matrix[i - 1][j - 1];
        } else {
          matrix[i][j] = Math.min(
            matrix[i - 1][j - 1] + 1,
            Math.min(matrix[i][j - 1] + 1, matrix[i - 1][j] + 1)
          );
        }
      }
    }

    return matrix[b.length][a.length];
  }

  calculateStringMatchingScores(
    daDeliverables: DADeliverableWithMappingsViewModel[],
    projectDeliverables: ProjectDeliverableWithMappingsViewModel[]
  ): SimilarityMap {
    const similarityMap: SimilarityMap = {};
    const wordScoreWeight = 0.3;
    const sentenceScoreWeight = 1 - wordScoreWeight;

    const unsorted: {
      daDeliverable: DADeliverableWithMappingsViewModel;
      matches: SimilarityMap[string];
    }[] = [];

    for (let daDeliverable of daDeliverables) {
      if (!daDeliverable.title) continue;

      const daTitle: string = daDeliverable.title!;
      const matches: SimilarityMap[string] = [];
      let updatedProjectDeliverable: ProjectDeliverableAutomatchViewModel;

      const daTitleWords = daTitle.split(' ');

      for (let projectDeliverable of projectDeliverables) {
        let sentenceScore = this.levenshteinDistance(
          daTitle,
          projectDeliverable.name
        );

        const projectDeliverableWords = projectDeliverable.name.split(' ');
        let wordScore = 0;

        for (let daTitleWord of daTitleWords) {
          for (let projectDeliverableWord of projectDeliverableWords) {
            wordScore = Math.min(
              wordScore,
              this.levenshteinDistance(daTitleWord, projectDeliverableWord)
            );
          }
        }

        const score =
          wordScoreWeight * wordScore + sentenceScoreWeight * sentenceScore;

        let recommendation: string;

        if (score < 5) {
          recommendation = 'high';
        } else if (score < 10) {
          recommendation = 'medium';
        } else {
          recommendation = 'low';
        }

        updatedProjectDeliverable = {
          ...projectDeliverable,
          recommendation,
          score,
        };

        matches.push({
          daDeliverable,
          projectDeliverable: updatedProjectDeliverable,
        });
      }

      matches.sort(
        (a, b) => a.projectDeliverable.score - b.projectDeliverable.score
      );
      unsorted.push({ daDeliverable, matches: matches.slice(0, 10) });
    }

    unsorted.sort(
      (a, b) =>
        a.matches[0]?.projectDeliverable.score -
        b.matches[0]?.projectDeliverable.score
    );

    for (let { daDeliverable, matches } of unsorted) {
      similarityMap[daDeliverable.title!] = matches;
    }

    return similarityMap;
  }
}
