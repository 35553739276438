import { Component, Input } from '@angular/core';
import { EntityCardOptionSelect } from '../../models/entity-card-option-select.model';

@Component({
  selector: 'app-entity-card',
  templateUrl: './entity-card.component.html',
  styleUrls: ['./entity-card.component.scss'],
})
export class EntityCardComponent {
  @Input() isReadOnly!: boolean;
  @Input() options!: EntityCardOptionSelect[];
  @Input() title!: string;
  @Input() subtitle!: string;

  onOptionSelect(option: EntityCardOptionSelect): void {
    option.onClick();
  }
}
