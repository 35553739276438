import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input } from '@angular/core';
import { NavigationEntry } from '../../models/navigation-entry';

@Component({
  selector: '[navigationLink]',
  templateUrl: './navigation-link.component.html',
  styleUrls: ['./navigation-link.component.scss'],
  animations: [
    trigger('skipInitial', [transition(':enter', [])]),
    trigger('fadeSlideInOut', [
      transition(':enter', [
        style({ transform: 'translateY(-100%)' }),
        animate('200ms ease', style({ transform: 'translateY(0)' })),
      ]),
      transition(':leave', [
        animate('200ms ease', style({ transform: 'translateY(-100%)' })),
      ]),
    ]),
  ],
})
export class NavigationLinkComponent {
  @Input()
  navigationLink!: NavigationEntry;
  collapsed = false;
}
