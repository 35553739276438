<div
  class="chip {{ className }}"
  [matTooltip]="tooltipText"
  matTooltipPosition="above"
  matTooltipShowDelay="1000"
>
  {{ value }}
  <ng-container *ngIf="focusAreaCount && focusAreaCount > 1">
    ({{ focusAreaCount }})
  </ng-container>
</div>
