import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { AppState } from 'src/app/app.state';
import { selectRoles } from '../store/user/user.selectors';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard {
  constructor(private store: Store<AppState>, private router: Router) {}
  canActivate(next: ActivatedRouteSnapshot): Observable<boolean> {
    const allowedRoles = next.data.roles;
    return this.store.select(selectRoles).pipe(
      filter((userRoles): userRoles is string[] => userRoles != null),
      map(
        (userRoles) =>
          userRoles.filter((userRole) => allowedRoles.includes(userRole))
            .length > 0
      ),
      tap((isAuthorized) => {
        if (!isAuthorized) {
          this.router.navigate(['403']);
        }
      })
    );
  }
}
