import { Component, Inject } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';
import { EditValueDialogData } from '../../models/form-dialog-data';

@Component({
  selector: 'app-edit-value-dialog',
  templateUrl: './edit-value-dialog.component.html',
})
export class EditValueDialog {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: EditValueDialogData,
    private dialogRef: MatDialogRef<EditValueDialog>
  ) {}

  cancel() {
    this.dialogRef.close();
  }

  confirm() {
    this.data.onConfirm?.();
    this.dialogRef.close();
  }
}
