import { SafeUrl } from '@angular/platform-browser';
import { AccountInfo } from '@azure/msal-common';
import { User } from '@microsoft/microsoft-graph-types';
import { createAction, props } from '@ngrx/store';

export const setCurrentUserAndRoles = createAction(
  '[User] Set',
  props<{ accountInfo: AccountInfo | null }>()
);
export const setCurrentUserPhoto = createAction(
  '[User] Set Photo',
  props<{ photo: string | null }>()
);
export const setCurrentUserProfile = createAction(
  '[User] Set Profile',
  props<{ userProfile: User | null }>()
);

export const loadAccountInfo = createAction('[User] Load');
export const loadUserProfile = createAction('[User] Load Profile');
export const loadUserPhoto = createAction('[User] Load photo');
