import { createAction, props } from '@ngrx/store';
import { QuickFilterMode } from '../../ag-grid-config.constants';
import { ColDefWithTitle } from './ag-grid-config.reducer';

export const setAgGridConfigForTable = createAction(
  '[AgGridConfig] Set',
  props<{
    tableName: string;
    routeIdName: string;
    columnDefs?: ColDefWithTitle[];
    touched: boolean;
  }>()
);

export const setAgGridConfigForTableRaw = createAction(
  '[AgGridConfig] Set Raw',
  props<{
    tableId: string;
    columnDefs?: ColDefWithTitle[];
    touched: boolean;
  }>()
);

export const clearAgGridConfigForTable = createAction(
  '[AgGridConfig] Clear the config',
  props<{ tableName: string; routeIdName: string }>()
);

export const clearAgGridConfigForTableRaw = createAction(
  '[AgGridConfig] Clear the config Raw',
  props<{ tableId: string }>()
);

export const quickFilterChange = createAction(
  '[AgGridConfig] Quick Filter Changed',
  props<{ tableName: string; routeIdName: string; quickFilterText: string }>()
);

export const quickFilterChangeSuccess = createAction(
  '[AgGridConfig] Quick Filter Change Success',
  props<{ tableId: string; quickFilterText: string }>()
);

export const quickFilterTagsChange = createAction(
  '[AgGridConfig] Quick Filter Tags Changed',
  props<{ tableName: string; routeIdName: string; quickFilterTags: string[] }>()
);

export const quickFilterTagsChangeSuccess = createAction(
  '[AgGridConfig] Quick Filter Tags Change Success',
  props<{ tableId: string; quickFilterTags: string[] }>()
);

export const quickFilterModeChange = createAction(
  '[AgGridConfig] Quick Filter Mode Changed',
  props<{
    tableName: string;
    routeIdName: string;
    quickFilterMode: QuickFilterMode;
  }>()
);

export const quickFilterModeChangeSuccess = createAction(
  '[AgGridConfig] Quick Filter Mode Changed Success',
  props<{ tableId: string; quickFilterMode: QuickFilterMode }>()
);

export const freezeAllColumns = createAction(
  '[AgGridConfig] Freeze all columns',
  props<{ tableName: string; routeIdName: string; freeze: boolean }>()
);

export const freezeAllColumnsRaw = createAction(
  '[AgGridConfig] Freeze all columns Raw',
  props<{ tableId: string; freeze: boolean }>()
);
