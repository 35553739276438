<div class="header">
  <div class="menu-toggle-wrapper">
    <button class="adap-btn-blank menu-toggle" [matMenuTriggerFor]="menu">
      <mat-icon svgIcon="cvx-hamburger"></mat-icon>
      menu
    </button>
  </div>
  <a class="sitename" [routerLink]="['/']">
    <img alt="chevron logo" class="cvx-img" src="/assets/images/CvxLogo.svg" />
    adap
  </a>
  <app-user-button class="usr-btn"></app-user-button>
</div>

<nav class="navigation-bar">
  <ul class="tabs">
    <li class="tab">
      <a
        *ngIf="isUserDAEngineer"
        class="tab-link"
        [routerLink]="['/da-engineer']"
        routerLinkActive="active"
      >
        design assurance projects
      </a>
      <a
        *ngIf="isUserChapterManager"
        class="tab-link"
        [routerLink]="['/chapter-manager']"
        routerLinkActive="active"
      >
        chapter manager requests
      </a>
      <a
        *ngIf="isUserSME"
        class="tab-link"
        [routerLink]="['/sme']"
        routerLinkActive="active"
      >
        SME my projects
      </a>
      <a
        *ngIf="isUserProjectContact"
        class="tab-link"
        [routerLink]="['/project-contact']"
        routerLinkActive="active"
      >
        project contact my projects
      </a>
    </li>
  </ul>
</nav>

<mat-menu #menu="matMenu" class="global-menu-wrapper">
  <app-global-menu></app-global-menu>
</mat-menu>
