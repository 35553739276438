import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { CsvExportParams } from 'ag-grid-community';
import { AppState } from 'src/app/app.state';
import { ITableEventsService } from 'src/app/shared/services/table-events.service';
import { getCurrentDateTimeString } from 'src/app/shared/utils/date-utils';
import {
  ColumnSizeStrategy,
  ColumnSizeStrategyOptions,
} from '../../ag-grid-config.constants';
import {
  clearAgGridConfigForTable,
  freezeAllColumns,
} from '../../store/ag-grid-config/ag-grid-config.actions';
import { AgGridConfig } from '../../store/ag-grid-config/ag-grid-config.reducer';

@Component({
  selector: 'app-table-actions',
  templateUrl: './table-actions.component.html',
  styleUrls: ['./table-actions.component.scss'],
})
export class TableActionsComponent {
  @Input() configStoreTableName!: string;
  @Input() configStoreRouteIdName: string | undefined;
  @Input() tableConfig!: AgGridConfig;
  @Input() disableColumnSizeStrategies: boolean = false;
  @Input() disableExportToCsv: boolean = false;

  constructor(
    private tableEventsService: ITableEventsService,
    private store: Store<AppState>
  ) {}

  resetTable() {
    this.store.dispatch(
      clearAgGridConfigForTable({
        tableName: this.configStoreTableName,
        routeIdName: this.configStoreRouteIdName ?? '',
      })
    );
  }

  sizeColumnsToFit() {
    this.dispatchSizeStrategyToTable(
      ColumnSizeStrategyOptions.sizeColumnsToFit
    );
  }

  sizeColumnsToContent() {
    this.dispatchSizeStrategyToTable(
      ColumnSizeStrategyOptions.autoSizeAllColumns
    );
  }

  exportToCsv() {
    const csvArgs = {
      fileName: this.getFileName(),
      columnSeparator: ',',
      allColumns: true,
    } as CsvExportParams;
    this.tableEventsService.publishExportToCsv(
      this.configStoreTableName,
      csvArgs
    );
  }

  freezeAllColumns(freeze: boolean) {
    this.store.dispatch(
      freezeAllColumns({
        tableName: this.configStoreTableName,
        routeIdName: this.configStoreRouteIdName ?? '',
        freeze,
      })
    );
  }

  private dispatchSizeStrategyToTable(columnSizeStrategy: ColumnSizeStrategy) {
    this.tableEventsService.publishColumnResizeStrategy(
      this.configStoreTableName,
      columnSizeStrategy
    );
  }

  private getFileName() {
    const currentDateTime = getCurrentDateTimeString();
    return `${this.configStoreTableName}_${currentDateTime}`;
  }
}
