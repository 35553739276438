import { CdkPortalOutlet, Portal, PortalOutlet } from '@angular/cdk/portal';
import { OnInit } from '@angular/core';
import { Component, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { HeaderService } from '../../services/header.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @ViewChild(CdkPortalOutlet, { static: true })
  portalOutlet!: PortalOutlet;

  private readonly subscription = new Subscription();

  constructor(private headerService: HeaderService) {}

  ngOnInit(): void {
    this.subscription.add(
      this.headerService.headerPortal$.subscribe((portal: Portal<any>) => {
        if (portal != null) {
          this.portalOutlet.detach();
          this.portalOutlet.attach(portal);
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
