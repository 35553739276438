<mat-dialog-content class="adap-dialog-content">
  <app-dialog-header title="upload completed template"></app-dialog-header>

  If you wish to perform an incremental import, please choose one, two, or three
  options under the checkbox "add all {{ deliverableType }}" and click "upload".

  <div class="checkboxes">
    <mat-checkbox
      color="primary"
      [(ngModel)]="onlyAdd"
      (change)="onCheckboxChanged('onlyAdd', $event)"
      (click)="onClick($event)"
    >
      add all {{ deliverableType }}
    </mat-checkbox>
    <mat-checkbox
      color="primary"
      [(ngModel)]="insert"
      (change)="onCheckboxChanged('insert', $event)"
    >
      insert missing {{ deliverableType }}
    </mat-checkbox>
    <mat-checkbox
      color="primary"
      [(ngModel)]="update"
      (change)="onCheckboxChanged('update', $event)"
    >
      update existing {{ deliverableType }}
    </mat-checkbox>
    <mat-checkbox
      color="primary"
      [(ngModel)]="delete"
      (change)="onCheckboxChanged('delete', $event)"
    >
      remove deleted {{ deliverableType }}
    </mat-checkbox>
  </div>

  <input
    type="file"
    #file
    (change)="importExcel(file)"
    accept=".xlsx"
    style="display: none"
  />

  <div class="dialog-actions">
    <button class="adap-btn-outline" type="button" (click)="closeWindow()">
      cancel
    </button>
    <button class="adap-btn-filled" type="button" (click)="file.click()">
      upload completed template
    </button>
  </div>
</mat-dialog-content>
