import { Component, Input } from '@angular/core';
import { TooltipPosition } from '@angular/material/tooltip';

@Component({
  selector: 'app-count-badge',
  templateUrl: './count-badge.component.html',
  styleUrls: ['./count-badge.component.scss'],
})
export class CountBadge {
  @Input() public count!: number;
  @Input() public tooltip: string = '';
  @Input() public tooltipPosition: TooltipPosition = 'above';
}
