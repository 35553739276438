import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { UserDataService } from 'src/app/core/services/user-data.service';

@Pipe({
  name: 'UserOidToName',
})
export class UserOidToNamePipe implements PipeTransform {
  constructor(private userDataService: UserDataService) {}
  transform(value: string): Observable<string> {
    return value ? this.userDataService.getUserNameByOid(value) : of(value);
  }
}
