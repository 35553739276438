import { HttpStatusCode } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.state';
import { clearLastVisitedPages } from 'src/app/roles/_shared-by-role/workplan/store/workplan.actions';
import { ADAPModelValidator, ApiException } from '../models/autogenerated';
import { AppInsightsMonitorService } from '../services/appinsights-monitor.service';
import { ToastMessagesService } from '../services/toast-messages.service';
import {
  killLoadingSpinner,
  showAuthorizationErrorPage,
} from '../store/shared.actions';
import { getValidationAsString } from '../utils/adap-validator-util';
import { TryPaseJson } from '../utils/json-utils';

@Injectable()
export class ApiErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) {}

  handleError(error: Error) {
    this.injector
      .get<AppInsightsMonitorService>(AppInsightsMonitorService)
      .logException(error);

    const store = this.injector.get<Store<AppState>>(Store);
    store.dispatch(killLoadingSpinner());

    if (error instanceof ApiException) {
      // handle HTTP errors
      switch (error.status) {
        case HttpStatusCode.Unauthorized:
          this.dispatchError(
            store,
            error,
            'you are not authorized to view this resource.'
          );
          break;
        case HttpStatusCode.Forbidden:
          this.dispatchError(
            store,
            error,
            'access to this resource is forbidden.'
          );
          break;
        case HttpStatusCode.NotFound:
          this.dispatchError(store, error, 'resource was not found.');
          break;
        case HttpStatusCode.NotImplemented:
          store.dispatch(clearLastVisitedPages()); // done so the user is not stuck, page will go to default navigation
          this.dispatchError(store, error, 'resource not yet available.');
          break;
        default:
          const toastMessagesService =
            this.injector.get<ToastMessagesService>(ToastMessagesService);
          toastMessagesService.displayError(
            this.getResponseErrorMessage(error)
          );
          break;
      }
    } else if (
      Array.isArray(error) &&
      error.every((x) => x instanceof ADAPModelValidator)
    ) {
      const validationErrors = error.map((x) => getValidationAsString(x));
      const toastMessagesService =
        this.injector.get<ToastMessagesService>(ToastMessagesService);
      toastMessagesService.displayError(validationErrors.join('\n\n'));
    } else {
      // If not ApiException, then throw error further
      throw error;
    }
  }

  getResponseErrorMessage(error: ApiException) {
    // Try to use the response because it has more details
    const response = TryPaseJson(error.response);
    if (!response) {
      return error.message;
    }

    if (typeof response === 'string') {
      return response;
    }

    const errors = response.errors;

    if (errors && Array.isArray(errors) && errors.length > 0) {
      return errors.map((x: any) => x.errorMessage ?? x).join('\n');
    }

    if (errors && typeof errors === 'object') {
      const errorsKeys = Object.keys(errors);
      return errorsKeys
        .map((errorKey: any) => `${errorKey}: ${errors[errorKey]}`)
        .join('\n');
    }

    return response && response.message
      ? response.message
      : 'An unexpected server error occurred';
  }

  dispatchError(store: Store<AppState>, error: Error, errorMessage: string) {
    store.dispatch(showAuthorizationErrorPage({ error, errorMessage }));
  }
}
