import { Directive, HostBinding, Input } from '@angular/core';
import { FormControlState } from 'ngrx-forms';

@Directive({
  selector: '[app-input-validation-container]',
})
export class InputValidationContainerDirective {
  @HostBinding('class')
  elementClass = '';

  @Input('app-input-validation-container') set inputValidationContainer(
    formStateControl: FormControlState<any>
  ) {
    this.elementClass =
      formStateControl?.isTouched &&
      formStateControl?.errors &&
      Object.keys(formStateControl.errors).length > 0
        ? 'adap-input-container-invalid'
        : '';
  }
}
