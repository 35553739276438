import { Component, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { NavigationStart, Router, RouterOutlet } from '@angular/router';
import { Store } from '@ngrx/store';
import { FormGroupState } from 'ngrx-forms';
import { Observable } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';
import { AppState } from 'src/app/app.state';
import { loadAllProjects } from 'src/app/roles/da-engineer/project-dashboard/store/project.actions';
import { ActionDialogType } from 'src/app/shared/models/enums/action-dialog-type.enum';
import { openActionDialog } from 'src/app/shared/store/action-dialog/action-dialog.actions';
import {
  CURRENT_PHASE_ID,
  NEW_WORK_PLAN_CONTACTS_LINK,
  NEW_WORK_PLAN_OVERVIEW_LINK,
} from '../../new-workplan-flow.constants';
import {
  clearNewWorkplan,
  createNewProject,
  loadBusinessUnits,
  loadNewProjectAllPhases,
  setCurrentPhaseId,
} from '../../store/new-project.actions';
import { NewWorkplanForm } from '../../store/new-project.reducer';
import { selectNewWorkplanForm } from '../../store/new-project.selectors';

@Component({
  selector: 'app-new-project',
  templateUrl: './new-project.component.html',
  styleUrls: ['./new-project.component.scss'],
})
export class NewProjectComponent {
  formState$: Observable<FormGroupState<NewWorkplanForm>>;

  newWorkplanOverviewLink = NEW_WORK_PLAN_OVERVIEW_LINK;
  newWorkplanContactsLink = NEW_WORK_PLAN_CONTACTS_LINK;
  currentRoute$!: Observable<string>;
  @ViewChild(RouterOutlet) outlet!: RouterOutlet;

  constructor(
    private store: Store<AppState>,
    private dialogRef: MatDialogRef<NewProjectComponent>,
    private router: Router
  ) {
    this.currentRoute$ = router.events.pipe(
      filter((event) => event instanceof NavigationStart),
      map(
        (navigationStartEvent) => (navigationStartEvent as NavigationStart).url
      ),
      startWith(router.url)
    );

    this.formState$ = this.store.select(selectNewWorkplanForm);
    this.store.dispatch(loadNewProjectAllPhases());
    this.store.dispatch(clearNewWorkplan());
    this.store.dispatch(loadAllProjects());
    this.store.dispatch(loadBusinessUnits());
    this.store.dispatch(setCurrentPhaseId({ phaseId: CURRENT_PHASE_ID }));
  }

  closeWindow() {
    this.dialogRef.close();
    this.outlet.deactivate();
  }

  create(phase: string) {
    if (phase === CURRENT_PHASE_ID) {
      this.createProjectAndClose();
    } else {
      this.store.dispatch(
        openActionDialog({
          title: 'Phase confirmation',
          description: `Are you sure you want to create a new project with a phase different than ${CURRENT_PHASE_ID}?`,
          dialogType: ActionDialogType.Confirmation,
          confirmButtonText: 'yes',
          onConfirm: this.createProjectAndClose.bind(this),
          onCancel: () => {
            this.router.navigate(this.newWorkplanOverviewLink);
          },
        })
      );
    }
  }

  navigate(currentRoute: string) {
    const nextRoute = currentRoute.includes('overview')
      ? this.newWorkplanContactsLink
      : this.newWorkplanOverviewLink;
    this.router.navigate(nextRoute);
  }

  private createProjectAndClose() {
    this.store.dispatch(createNewProject());
    this.closeWindow();
  }
}
