import {
  AfterContentInit,
  Component,
  ContentChildren,
  ElementRef,
  Input,
  OnDestroy,
  QueryList,
  ViewChild,
} from '@angular/core';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { debounceTime, delay, map, startWith } from 'rxjs/operators';
import { FullscreenManagerService } from '../../services/fullscreen-manager.service';

@Component({
  selector: 'app-horizontal-items-carousel',
  templateUrl: './horizontal-items-carousel.component.html',
  styleUrls: ['./horizontal-items-carousel.component.scss'],
})
export class HorizontalItemsCarouselComponent
  implements OnDestroy, AfterContentInit
{
  @Input() scrollStep: number = 150;
  @Input() fullScreenIds: string[] = [''];
  @ViewChild('horizontalCarousel', { static: true })
  carousel!: ElementRef<HTMLElement>;

  @ContentChildren('carouselItem') carouselItems!: QueryList<
    ElementRef<HTMLElement>
  >;
  leftDisabled: boolean = true;
  rightDisabled: boolean = true;

  private onScrollSubject: BehaviorSubject<number> = new BehaviorSubject(0);
  private subscriptions: Subscription = new Subscription();

  constructor(private fullScreenManagerService: FullscreenManagerService) {}

  async ngAfterContentInit() {
    this.updateScrollProperties(0);
    // we need to check if new filters are added/removed, fullscreen as well
    // as the scroll itself
    for (const id of this.fullScreenIds) {
      const obs = await this.fullScreenManagerService.subscribeToFullScreen(id);

      this.subscriptions.add(
        combineLatest([
          this.onScrollSubject.pipe(debounceTime(50)), // debounce to catch last scroll event
          obs.pipe(
            map((state) => state.isFullScreen),
            startWith(false),
            delay(750)
          ),
          this.carouselItems.changes,
        ]).subscribe(([step, _, __]) => this.updateScrollProperties(step))
      );
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  scrollCarousel(step: number): void {
    if (this.carousel?.nativeElement != null) {
      this.carousel.nativeElement.scrollLeft += step;
    }
  }

  onScroll($event: any) {
    // we need to react to the scroll event, otherwise the scrollLeft value
    // from the view child does not always give the real value
    this.onScrollSubject.next($event.target.scrollLeft);
  }

  private updateScrollProperties(scrollLeft: number) {
    const scrollWithClientWidth =
      scrollLeft + this.carousel.nativeElement.clientWidth;
    const totalScrollWidth = this.carousel.nativeElement.scrollWidth;

    this.rightDisabled = scrollWithClientWidth >= totalScrollWidth;
    this.leftDisabled = scrollLeft <= 0;
  }
}
