<div class="title-section">
  <div class="adap-dialog-title" *ngIf="title">
    {{ title }}
  </div>
  <ng-content></ng-content>
  <div>
    <button class="close-button" mat-dialog-close *ngIf="!hideCloseButton">
      <mat-icon svgIcon="cvx-close"></mat-icon>
    </button>
  </div>
</div>

<app-separator></app-separator>
