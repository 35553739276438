import { Component, Input } from '@angular/core';
import { Option } from '../../models/option.model';

@Component({
  selector: 'app-options-menu',
  templateUrl: './options-menu.component.html',
  styleUrls: ['./options-menu.component.scss'],
})
export class OptionsMenuComponent {
  @Input() options!: Option[];
  @Input() buttonIcon: string = 'more_horiz';

  onOptionSelect(option: Option): void {
    option.onClick(option.data);
  }
}
