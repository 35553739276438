<button
  class="adap-btn-blank"
  matTooltip="reset table columns"
  matTooltipPosition="above"
  [disabled]="!tableConfig?.touched || tableConfig?.allColumnsFrozen"
  (click)="resetTable()"
>
  <mat-icon>restart_alt</mat-icon>
</button>

<button
  *ngIf="tableConfig?.allColumnsFrozen; else freeze"
  class="adap-btn-blank"
  matTooltip="unfreeze all columns"
  matTooltipPosition="above"
  (click)="freezeAllColumns(false)"
>
  <mat-icon>wb_sunny</mat-icon>
</button>
<ng-template #freeze>
  <button
    class="adap-btn-blank"
    matTooltip="freeze all columns"
    matTooltipPosition="above"
    (click)="freezeAllColumns(true)"
  >
    <mat-icon>ac_unit</mat-icon>
  </button>
</ng-template>

<ng-container *ngIf="!disableColumnSizeStrategies">
  <button
    class="adap-btn-blank"
    value="sizeColumnsToFit"
    matTooltip="size columns to fit"
    matTooltipPosition="above"
    (click)="sizeColumnsToFit()"
    [disabled]="tableConfig?.allColumnsFrozen"
  >
    <mat-icon>compare_arrows</mat-icon>
  </button>

  <button
    class="adap-btn-blank"
    value="autoSizeAllColumns"
    matTooltip="autosize all columns"
    matTooltipPosition="above"
    (click)="sizeColumnsToContent()"
    [disabled]="tableConfig?.allColumnsFrozen"
  >
    <mat-icon>table_chart</mat-icon>
  </button>
</ng-container>

<button
  *ngIf="!disableExportToCsv"
  class="adap-btn-blank"
  matTooltip="export table to csv"
  matTooltipPosition="above"
  (click)="exportToCsv()"
>
  <mat-icon>save_alt</mat-icon>
</button>
