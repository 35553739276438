<mat-dialog-content class="adap-dialog-content">
  <app-dialog-header title="1 : 1 match"></app-dialog-header>
  <ng-container *ngIf="oneOnOneMatches$ | async as oneOnOneMatches">
    <ng-container *ngIf="oneOnOneMatches.length > 0; else placeholder">
      <app-ag-grid-options
        configStoreTableName="matchedDeliverables"
        configStoreRouteIdName="phaseId"
      ></app-ag-grid-options>
      <div class="table-container">
        <ag-grid-angular
          columnHoverHighlight
          rowHoverHighlight
          class="ag-theme-adap one-on-one-table"
          [components]="components"
          [columnDefs]="columnDefs"
          [defaultColDef]="defaultColDef"
          [rowData]="oneOnOneMatches"
          [animateRows]="true"
          domLayout="normal"
          rowClass="ag-no-border"
          configStore
          [configStoreTableName]="configStoreTableName"
          [configStoreRouteIdName]="configStoreRouteIdName"
          columnSizeStrategy="sizeColumnsToFit"
          suppressScrollOnNewData
        ></ag-grid-angular>
      </div>
    </ng-container>
  </ng-container>
  <div class="dialog-actions">
    <button class="adap-btn-outline" type="button" (click)="closeWindow()">
      cancel
    </button>
    <button
      class="adap-btn-filled"
      type="button"
      (click)="save()"
      [disabled]="noneSelected"
    >
      save
    </button>
  </div>
</mat-dialog-content>

<ng-template #placeholder>
  <app-placeholder
    class="placeholder"
    icon="assets/images/no-systems.svg"
    title="no 1:1 matches found by name or every deliverable has at least one match"
    buttonCaption="cancel"
    (buttonClick)="closeWindow()"
  ></app-placeholder>
</ng-template>
