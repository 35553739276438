import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult } from '@azure/msal-browser';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.state';
import { setCurrentUserAndRoles } from '../store/user/user.actions';
import { FeedbackSnackBarComponent } from 'src/app/shared/components/feedback-snack-bar/feedback-snack-bar.component';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';

/** This component is used only to avoid Angular reload when doing acquireTokenSilent() */
@Component({
  selector: 'app-msal',
  template: '',
})
export class MsalComponent implements OnInit {
  constructor(
    private msalService: MsalService,
    private store: Store<AppState>,
    private snackBarService: SnackBarService
  ) {}

  ngOnInit(): void {
    this.msalService.handleRedirectObservable().subscribe({
      next: (result: AuthenticationResult) => {
        if (result) {
          //ask for feedback when authenticated
          this.openFeedback();
          this.store.dispatch(
            setCurrentUserAndRoles({ accountInfo: result.account })
          );
        }
      },
      error: (error) => console.log(error),
    });
  }

  private openFeedback() {
    this.snackBarService.displaySnackBarFromComponent(
      FeedbackSnackBarComponent
    );
  }
}
