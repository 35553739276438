import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { FormGroupState } from 'ngrx-forms';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/app.state';
import { NEW_WORK_PLAN_OVERVIEW_LINK } from '../../new-workplan-flow.constants';
import { NewWorkplanForm } from '../../store/new-project.reducer';
import { selectNewWorkplanForm } from '../../store/new-project.selectors';

@Component({
  selector: 'app-new-project-contacts',
  templateUrl: './new-project-contacts.component.html',
  styleUrls: ['./new-project-contacts.component.scss'],
})
export class NewProjectContactsComponent {
  newWorkplanOverviewLink = NEW_WORK_PLAN_OVERVIEW_LINK;

  formState$: Observable<FormGroupState<NewWorkplanForm>>;

  constructor(private store: Store<AppState>) {
    this.formState$ = this.store.select(selectNewWorkplanForm);
  }
}
