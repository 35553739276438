import { Component, Input } from '@angular/core';
import { ProjectViewModel } from '../../models/autogenerated';
import { NavigationEntry } from '../../models/navigation-entry';

@Component({
  selector: 'app-navigation-tree',
  templateUrl: './navigation-tree.component.html',
  styleUrls: ['./navigation-tree.component.scss'],
})
export class NavigationTreeComponent {
  @Input() currentProject!: ProjectViewModel | null;
  @Input() entries!: NavigationEntry[];
  observationTool: string = 'observation tool';
}
