import { Context, GraphRequest } from '@microsoft/microsoft-graph-client';
import { IRequestCacheService } from '../services/requestCache.service';

declare module '@microsoft/microsoft-graph-client' {
  interface GraphRequest {
    cacheable(expirationInMin?: number): GraphRequest;
  }
}

export const WRONG_USER_OID = 'WRONG_USER_OID';
export const NO_PHOTO = 'NO_PHOTO';

GraphRequest.prototype.cacheable = function (
  expirationInMin?: number
): GraphRequest {
  if (expirationInMin === undefined || expirationInMin < 0) {
    expirationInMin = IRequestCacheService.DEFAULT_EXPIRATION_TIME_MIN;
  }
  const expirationDate = new Date().getTime() + expirationInMin * 60 * 1000;
  this.header(
    IRequestCacheService.EXPIRATION_DATE_HEADER,
    expirationDate.toString()
  );

  return this;
};

export function GetContextHeaderValue(
  context: Context,
  headerKey: string
): string | null {
  let headerValue: string | null = null;
  const headers = context.options!.headers;

  // we need to check what type of headers are passed (the string[][] we can ignore)
  if (headers instanceof Headers) {
    headers.forEach((val: string, key: string) => {
      if (key === headerKey) {
        headerValue = val;
      }
    });
  }

  //this means it is record type
  if (!Array.isArray(headers)) {
    const recordValue = (headers as Record<string, string>)[
      IRequestCacheService.EXPIRATION_DATE_HEADER
    ];
    if (recordValue !== undefined) {
      headerValue = recordValue;
    }
  }
  return headerValue;
}
