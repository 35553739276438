import { Component, Input } from '@angular/core';
import { NavigationEntry } from '../../models/navigation-entry';
@Component({
  selector: 'app-navigation-tab-strip',
  templateUrl: './navigation-tab-strip.component.html',
  styleUrls: ['./navigation-tab-strip.component.scss'],
})
export class NavigationTabStripComponent {
  @Input()
  public displayAsButton: boolean = false;
  @Input()
  public tabs!: NavigationEntry[];
}
