export const NEW_WORKPLAN_DIALOG_OUTLET = 'new-workplan-dialog';
export const NEW_WORKPLAN_DIALOG_LINK = [
  '/',
  { outlets: { [NEW_WORKPLAN_DIALOG_OUTLET]: ['overview'] } },
];

export const NEW_WORK_PLAN_OVERVIEW_LINK = [
  '/',
  { outlets: { [NEW_WORKPLAN_DIALOG_OUTLET]: ['overview'] } },
];

export const NEW_WORK_PLAN_CONTACTS_LINK = [
  '/',
  { outlets: { [NEW_WORKPLAN_DIALOG_OUTLET]: ['contacts'] } },
];

export const CURRENT_PHASE_ID = '2B';

export const DA_DELIVERABLES = 'DA deliverables';
export const PROJECT_DELIVERABLES = 'project deliverables';

export const LEAD_BY_TOOLTIP =
  'Ex: Lead By DA Deliverables will list single DA titles to multiple Project Titles. You can change this later as needed';

export const USER_ANONYMITY_HIDE_NAMES_DESCRIPTION = `Project Contact and SME do not see each other's names`;
export const USER_ANONYMITY_SHOW_NAMES_DESCRIPTION = `Project Contact and SME see each other's names`;
export const USER_ANONYMITY_TOOLTIP = `Hide names: ${USER_ANONYMITY_HIDE_NAMES_DESCRIPTION}`;
