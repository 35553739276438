import { Component, Input, OnInit } from '@angular/core';
import { ChipInfo, getClassName, getTooltipText } from '../../utils/chip-utils';
import { PluralPipe } from '../../pipes/plural.pipe';

@Component({
  selector: 'app-origin-chip',
  templateUrl: './origin-chip.component.html',
  styleUrls: ['./origin-chip.component.scss'],
})
export class OriginChipComponent implements OnInit {
  @Input() value!: string;
  @Input() chipMap!: Map<string, ChipInfo>;
  @Input() focusAreaCount?: number;
  className: string = '';
  tooltipText: string = '';

  constructor(private pluralPipe: PluralPipe) {}

  ngOnInit(): void {
    this.className = getClassName(this.value);
    this.tooltipText = getTooltipText(
      this.value,
      this.pluralPipe,
      this.focusAreaCount
    );
  }
}
