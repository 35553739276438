import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { selectRouteNestedParam } from 'src/app/shared/store/shared.selectors';
import {
  clearAgGridConfigForTable,
  clearAgGridConfigForTableRaw,
  freezeAllColumns,
  freezeAllColumnsRaw,
  quickFilterChange,
  quickFilterChangeSuccess,
  quickFilterModeChange,
  quickFilterModeChangeSuccess,
  quickFilterTagsChange,
  quickFilterTagsChangeSuccess,
  setAgGridConfigForTable,
  setAgGridConfigForTableRaw,
} from './ag-grid-config.actions';

@Injectable()
export class AgGridConfigEffects {
  setConfigWithRouteId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setAgGridConfigForTable),
      mergeMap(({ tableName, routeIdName, columnDefs, touched }) =>
        of(routeIdName).pipe(
          withLatestFrom(
            this.store.select(selectRouteNestedParam(routeIdName))
          ),
          map(([_, routeId]) => ({
            tableName,
            routeIdName,
            routeId: routeId ?? '',
            columnDefs,
            touched,
          }))
        )
      ),
      map(({ tableName, routeIdName, routeId, columnDefs, touched }) =>
        setAgGridConfigForTableRaw({
          tableId: this.getTableId(tableName, routeIdName, routeId),
          columnDefs,
          touched,
        })
      )
    )
  );
  clearAgGridConfigForTable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(clearAgGridConfigForTable),
      mergeMap(({ tableName, routeIdName }) =>
        of(routeIdName).pipe(
          withLatestFrom(
            this.store.select(selectRouteNestedParam(routeIdName))
          ),
          map(([_, routeId]) => ({
            tableName,
            routeIdName,
            routeId: routeId ?? '',
          }))
        )
      ),
      map(({ tableName, routeIdName, routeId }) =>
        clearAgGridConfigForTableRaw({
          tableId: this.getTableId(tableName, routeIdName, routeId),
        })
      )
    )
  );

  setFilterForTable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(quickFilterChange),
      mergeMap(({ tableName, routeIdName, quickFilterText }) =>
        of(routeIdName).pipe(
          withLatestFrom(
            this.store.select(selectRouteNestedParam(routeIdName))
          ),
          map(([_, routeId]) => ({
            tableName,
            routeIdName,
            routeId: routeId ?? '',
            quickFilterText: quickFilterText,
          }))
        )
      ),
      map(({ tableName, routeIdName, routeId, quickFilterText }) =>
        quickFilterChangeSuccess({
          tableId: this.getTableId(tableName, routeIdName, routeId),
          quickFilterText: quickFilterText,
        })
      )
    )
  );

  setFreezeColumns$ = createEffect(() =>
    this.actions$.pipe(
      ofType(freezeAllColumns),
      mergeMap(({ tableName, routeIdName, freeze }) =>
        of(routeIdName).pipe(
          withLatestFrom(
            this.store.select(selectRouteNestedParam(routeIdName))
          ),
          map(([_, routeId]) => ({
            tableName,
            routeIdName,
            routeId: routeId ?? '',
            freeze,
          }))
        )
      ),
      map(({ tableName, routeIdName, routeId, freeze }) =>
        freezeAllColumnsRaw({
          tableId: this.getTableId(tableName, routeIdName, routeId),
          freeze,
        })
      )
    )
  );

  setQuickFilterMode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(quickFilterModeChange),
      mergeMap(({ tableName, routeIdName, quickFilterMode }) =>
        of(routeIdName).pipe(
          withLatestFrom(
            this.store.select(selectRouteNestedParam(routeIdName))
          ),
          map(([_, routeId]) => ({
            tableName,
            routeIdName,
            routeId: routeId ?? '',
            quickFilterMode,
          }))
        )
      ),
      map(({ tableName, routeIdName, routeId, quickFilterMode }) =>
        quickFilterModeChangeSuccess({
          tableId: this.getTableId(tableName, routeIdName, routeId),
          quickFilterMode,
        })
      )
    )
  );

  setFilterTagsForTable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(quickFilterTagsChange),
      mergeMap(({ tableName, routeIdName, quickFilterTags }) =>
        of(routeIdName).pipe(
          withLatestFrom(
            this.store.select(selectRouteNestedParam(routeIdName))
          ),
          map(([_, routeId]) => ({
            tableName,
            routeIdName,
            routeId: routeId ?? '',
            quickFilterTags,
          }))
        )
      ),
      map(({ tableName, routeIdName, routeId, quickFilterTags }) =>
        quickFilterTagsChangeSuccess({
          tableId: this.getTableId(tableName, routeIdName, routeId),
          quickFilterTags,
        })
      )
    )
  );

  private getTableId(tableName: string, routeIdName: string, routeId: string) {
    return `${tableName}_${routeIdName}_${routeId}`;
  }

  constructor(private actions$: Actions, private store: Store) {}
}
