import { Component, EventEmitter, Input, Output } from '@angular/core';
import { QUICKFILTER_INPUT_PLACEHOLDER } from '../../utils/constants';

@Component({
  selector: 'app-input-filter',
  templateUrl: './input-filter.component.html',
  styleUrls: ['./input-filter.component.scss'],
})
export class InputFilterComponent {
  @Input() filterText!: string;
  @Input() isReadOnly: boolean = false;
  @Input() placeholder: string = QUICKFILTER_INPUT_PLACEHOLDER;

  @Output() filterChanged = new EventEmitter<string>();

  onFilterChange(filterText: string) {
    this.filterChanged.next(filterText);
  }

  onClearFilterClicked() {
    this.onFilterChange('');
  }
}
