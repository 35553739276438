import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import {
  AuthenticationHandler,
  Client,
  GraphError,
  HTTPMessageHandler,
} from '@microsoft/microsoft-graph-client';
import {
  AuthCodeMSALBrowserAuthenticationProvider,
  AuthCodeMSALBrowserAuthenticationProviderOptions,
} from '@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser';
import * as MicrosoftGraph from '@microsoft/microsoft-graph-types';
import { Store } from '@ngrx/store';
import { from, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AppState } from 'src/app/app.state';
import { Constants } from 'src/app/shared/utils/constants';
import { isUUIDValid } from 'src/app/shared/utils/guid-utils';
import { CacheMiddleware } from '../middleware/cache-middleware';
import { selectUserAccount } from '../store/user/user.selectors';
import './../../shared/utils/graph-client-utils'; // needed to include the extensions for the graph request
import { NO_PHOTO } from './../../shared/utils/graph-client-utils';

@Injectable({
  providedIn: 'root',
})
export class GraphService {
  private graphClient: Client | null = null;

  constructor(
    private msalService: MsalService,
    private store: Store<AppState>,
    cacheMiddleware: CacheMiddleware
  ) {
    this.store.select(selectUserAccount).subscribe((account) => {
      const application = this.msalService.instance;

      if (account == null) {
        return;
      }
      const options: AuthCodeMSALBrowserAuthenticationProviderOptions = {
        account: account, // the AccountInfo instance to acquire the token for.
        interactionType: InteractionType.Redirect, // msal-browser InteractionType
        scopes: ['user.read'], // example of the scopes to be passed
      };

      // Pass the PublicClientApplication instance from step 2 to create AuthCodeMSALBrowserAuthenticationProvider instance
      const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(
        application as PublicClientApplication,
        options
      );

      const authenticationHandler = new AuthenticationHandler(authProvider);
      const messageHandler = new HTTPMessageHandler();

      // we need to chain the middleware properly
      authenticationHandler.setNext(cacheMiddleware);
      cacheMiddleware.setNext(messageHandler);

      // Initialize the Graph client
      this.graphClient = Client.initWithMiddleware({
        middleware: authenticationHandler,
      });
    });
  }

  searchUsers(searchString: string): Observable<MicrosoftGraph.User[]> {
    if (this.graphClient == null) return of([]);
    if (searchString.length === 0) {
      return of([]);
    }
    const filters = [
      `startsWith(displayName, '${searchString}')`,
      `startsWith(userPrincipalName, '${searchString}')`,
      `startsWith(givenName, '${searchString}')`,
      `startsWith(surname, '${searchString}')`,
    ];
    let filterString = filters.join(' or ');
    if (searchString.includes(' ')) {
      const [name0, name1] = searchString.split(' ').filter((x) => x !== '');
      filterString += ` or (startsWith(givenName, '${name0}') and startsWith(surname, '${name1}'))`;
      filterString += ` or (startsWith(givenName, '${name1}') and startsWith(surname, '${name0}'))`;
    }
    return from(
      this.graphClient
        .api('users')
        .select('displayName,userPrincipalName,jobTitle,id')
        .filter(filterString)
        .top(10)
        .get()
    ).pipe(map((res) => res.value));
  }

  getProfile(): Observable<MicrosoftGraph.User | null> {
    if (this.graphClient == null) return of(null);
    return from(
      this.graphClient.api('me').select('displayName, jobTitle').get()
    );
  }

  getCurrentUserPhoto(): Observable<string | null> {
    if (this.graphClient == null) return of(null);
    return from(this.graphClient.api('me/photo/$value').get()).pipe(
      map((photo) => this.mapBlobToObjectUrl(photo)),
      catchError(this.handleGraphError)
    );
  }

  getUserPhotoByOid(oid: string, size?: string): Observable<string | null> {
    if (this.graphClient == null || isUUIDValid(oid) === false) {
      return of(null);
    }

    const endpoint =
      size == null
        ? `users/${oid}/photo/$value`
        : `users/${oid}/photos/${size}/$value`;

    return from(this.graphClient.api(endpoint).cacheable().get()).pipe(
      map((photo) => this.mapBlobToObjectUrl(photo)),
      catchError(this.handleGraphError)
    );
  }

  getUserPhoto$(
    userOid: string,
    photoSize: string = Constants.USER_PHOTO_MINIATURE_SIZE
  ): Observable<string | null> {
    return this.getUserPhotoByOid(userOid, photoSize).pipe(
      catchError(() => of(NO_PHOTO)),
      map((photo) => {
        return photo === null ? NO_PHOTO : photo;
      })
    );
  }

  private handleGraphError(error: GraphError) {
    // Return null if user has no info/photo else throw error
    if (error.statusCode === 404) {
      return of(null);
    }
    throw error;
  }

  private mapBlobToObjectUrl(blob: Blob): string {
    return URL.createObjectURL(blob);
  }
}
