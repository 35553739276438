import { IPIMSDomainAssignDialogComponent } from '../components/ipims-domain-assign-dialog/ipims-domain-assign-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { IPIMSDomainConfig } from '../models/ipims-dialog-configuration';

export function openIPIMSDomainAssignDialog(
  dialog: MatDialog,
  configuration: IPIMSDomainConfig
) {
  return dialog.open(IPIMSDomainAssignDialogComponent, {
    panelClass: 'ipims-domain-assign-dialog',
    closeOnNavigation: true,
    disableClose: false,
    maxWidth: 'unset', // by default the library sets it to 80vw,
    data: configuration,
  });
}
