import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/app.state';
import { ServiceNowForm } from 'src/app/shared/models/autogenerated';
import { loadApplicationVersion } from 'src/app/shared/store/shared.actions';
import { selectCurrentVersion } from 'src/app/shared/store/shared.selectors';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-default-footer',
  templateUrl: './default-footer.component.html',
  styleUrls: ['./default-footer.component.scss'],
})
export class DefaultFooterComponent {
  version$!: Observable<string>;
  releaseNotesPage!: string;
  ServiceNowUrl: string = ServiceNowForm.Url;
  constructor(private store: Store<AppState>) {
    this.getApplicationVersion();
  }

  private getApplicationVersion(): void {
    this.store.dispatch(loadApplicationVersion());
    this.version$ = this.store.select(selectCurrentVersion);
    this.releaseNotesPage = environment.wikiReleaseNotesUrl;
  }
}
