<button
  (click)="scrollCarousel(-scrollStep)"
  class="adap-btn-blank left-button"
  [class.chevron-button]="!leftDisabled"
  [disabled]="leftDisabled"
>
  <mat-icon svgIcon="cvx-left"></mat-icon>
</button>
<div #horizontalCarousel class="buttons-panel" (scroll)="onScroll($event)">
  <ng-content></ng-content>
</div>
<button
  (click)="scrollCarousel(scrollStep)"
  class="adap-btn-blank right-button"
  [class.chevron-button]="!rightDisabled"
  [disabled]="rightDisabled"
>
  <mat-icon svgIcon="cvx-right"></mat-icon>
</button>
