<div class="footer-main">
  <div class="footer-item">© 2024 Chevron Corporation</div>
  <app-separator></app-separator>
  <div class="footer-item">Company Confidential</div>
  <app-separator></app-separator>
  <div class="footer-item">
    OpCo:
    <a [href]="'https://chevron.sharepoint.com/sites/SBS-Org'" target="_blank"
      >Strategic Business Solutions</a
    >
  </div>
  <app-separator></app-separator>
  <div class="footer-item">
    Technical contact:
    <a [href]="ServiceNowUrl" target="_blank">Support Request</a>
  </div>
  <app-separator></app-separator>
  <div class="footer-item">
    Content contact:
    <a href="mailto:Surface-SE-ADAP@chevron.com?subject=ADAP Content"
      >Surface-SE-ADAP</a
    >
  </div>
  <ng-container *ngIf="version$ | async as version">
    <app-separator></app-separator>
    <div class="footer-item">
      Version:
      <a [href]="releaseNotesPage" target="_blank">{{ version }}</a>
    </div>
  </ng-container>
</div>
