import { Pipe, PipeTransform } from '@angular/core';
import { ProjectViewModel } from '../models/autogenerated';

@Pipe({
  name: 'ProjectTypeString',
})
export class ProjectTypeStringPipe implements PipeTransform {
  transform(project: ProjectViewModel): string {
    const projectTypes: string[] = [];

    if (project.onShore === true) {
      projectTypes.push('onshore');
    }

    if (project.offShore === true) {
      projectTypes.push('offshore');
    }

    return projectTypes.join(', ') || '';
  }
}
