import { Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';
import { AppState } from 'src/app/app.state';
import { loadingFinished, loadingStarted } from './shared.actions';

export const startLoading = <T>(store: Store<AppState>) =>
  tap<T>(() => store.dispatch(loadingStarted()));

export const finishLoading = <T>(store: Store<AppState>) =>
  tap<T>({
    next: () => store.dispatch(loadingFinished()),
    error: () => store.dispatch(loadingFinished()),
  });
