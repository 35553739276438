import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { Constants } from '../utils/constants';

@Pipe({
  name: 'AdapShortDate',
})
export class AdapShortDatePipe extends DatePipe implements PipeTransform {
  transform(value: any): any {
    return super.transform(value, Constants.ADAP_SHORT_DATE);
  }
}
