import { Pipe, PipeTransform } from '@angular/core';
import { Observable, map, of } from 'rxjs';
import { UserDataService } from 'src/app/core/services/user-data.service';
import { UserService } from '../../core/services/user.service';
import {
  SMEDisciplineAssignments,
  SMEDisciplineInObservationViewModel,
  SMEDisciplineViewModel,
} from '../models/autogenerated';

@Pipe({
  name: 'smeDisciplineChipTooltip',
})
export class SMEDisciplineChipTooltipPipe implements PipeTransform {
  constructor(
    private userDataService: UserDataService,
    private userService: UserService
  ) {}

  transform(
    smeDiscipline: SMEDisciplineViewModel | SMEDisciplineInObservationViewModel
  ): Observable<string> {
    if (!smeDiscipline) {
      return of('');
    }
    const name = smeDiscipline.name;
    const assignments = smeDiscipline.assignments;
    if (
      assignments &&
      assignments.length > 0 &&
      this.userService.isDAEngineer()
    ) {
      const primarySMEUserId = assignments.find(
        (x) => x.label === SMEDisciplineAssignments.PrimarySME
      )?.userId;
      const primarySMEUserName = primarySMEUserId
        ? this.userDataService.getUserNameByOid(primarySMEUserId)
        : of('');
      return primarySMEUserName.pipe(
        map((userName) => `${name} - ${userName}`)
      );
    }
    return of(name);
  }
}
