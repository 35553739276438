import { Pipe, PipeTransform } from '@angular/core';
import { ProjectViewModel } from '../models/autogenerated';

@Pipe({
  name: 'ProjectOperatedString',
})
export class ProjectOperatedStringPipe implements PipeTransform {
  transform(project: ProjectViewModel): any {
    return project.nojv === true ? 'NOJV' : 'Chevron';
  }
}
