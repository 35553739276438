import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.state';
import {
  selectErrorMessage,
  selectErrorStatus,
} from '../../store/shared.selectors';

@Component({
  selector: 'app-not-found-or-not-authorized',
  templateUrl: './not-found-or-not-authorized.component.html',
  styleUrls: ['./not-found-or-not-authorized.component.scss'],
})
export class NotFoundOrNotAuthorizedComponent {
  constructor(private store: Store<AppState>) {}

  errorStatus$ = this.store.select(selectErrorStatus);
  errorMessage$ = this.store.select(selectErrorMessage);
}
