import { Component, Input, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { FullScreenState } from '../../models/full-screen-state';
import { FullscreenManagerService } from '../../services/fullscreen-manager.service';

@Component({
  selector: 'app-fullscreen-toggle',
  templateUrl: './fullscreen-toggle.component.html',
  styleUrls: ['./fullscreen-toggle.component.scss'],
})
export class FullscreenToggleComponent implements OnInit {
  // set this when using multiple toggles in the same view
  @Input() fullScreenId: string = '';
  @Input() buttonClass: string = '';
  isFullscreen$: Observable<FullScreenState>;

  constructor(private fullscreenManagerService: FullscreenManagerService) {
    // start with a dummy observable to avoid ng changes errors
    // because of the async ngOnInit
    this.isFullscreen$ = of({
      isFullScreen: false,
      fullScreenId: '',
      isDisposing: false,
    });
  }

  async ngOnInit(): Promise<void> {
    this.isFullscreen$ =
      await this.fullscreenManagerService.subscribeToFullScreen(
        this.fullScreenId
      );
  }

  toggle(fullScreenId: string) {
    this.fullscreenManagerService.toggle(fullScreenId);
  }
}
