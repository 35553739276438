import { SafeUrl } from '@angular/platform-browser';
import { AccountInfo } from '@azure/msal-common';
import { User } from '@microsoft/microsoft-graph-types';
import { createReducer, on } from '@ngrx/store';
import { IdTokenClaims } from '../../services/user.service';
import {
  setCurrentUserPhoto,
  setCurrentUserAndRoles,
  setCurrentUserProfile,
} from './user.actions';

export interface UserState {
  accountInfo: AccountInfo | null;
  userProfile: User | null;
  userPhoto: SafeUrl | null;
  roles?: string[];
}

const initialState: UserState = {
  accountInfo: null,
  userProfile: null,
  userPhoto: null,
};

export const userReducer = createReducer(
  initialState,
  on(setCurrentUserAndRoles, (state, { accountInfo }) => ({
    ...state,
    accountInfo: accountInfo,
    roles: (accountInfo?.idTokenClaims as IdTokenClaims)?.roles,
  })),
  on(setCurrentUserPhoto, (state, { photo }) => ({
    ...state,
    userPhoto: photo,
  })),
  on(setCurrentUserProfile, (state, { userProfile }) => ({
    ...state,
    userProfile: userProfile,
  }))
);
