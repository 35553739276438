import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { filter, map } from 'rxjs/operators';
import { UserState } from '../../../store/user/user.reducer';
import {
  selectUserAccount,
  selectUserPhoto,
} from '../../../store/user/user.selectors';

@Component({
  selector: 'app-user-button',
  templateUrl: './user-button.component.html',
  styleUrls: ['./user-button.component.scss'],
})
export class UserButtonComponent {
  constructor(
    private sanitizer: DomSanitizer,
    private store: Store<{ user: UserState }>
  ) {}

  readonly currentUserProfile$ = this.store.select(selectUserAccount);
  userImage$ = this.store.select(selectUserPhoto);
}
