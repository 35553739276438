import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { User } from '@microsoft/microsoft-graph-types';
import { FormControlState } from 'ngrx-forms';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, map, switchMap } from 'rxjs/operators';
import { GraphService } from 'src/app/core/services/graph-service';
import { UserModel } from '../../models/user-model';
import { Constants } from '../../utils/constants';
import { UserDataService } from 'src/app/core/services/user-data.service';

@Component({
  selector: 'app-user-search',
  templateUrl: './user-search.component.html',
  styleUrls: ['./user-search.component.scss'],
})
export class UserSearchComponent implements OnDestroy, OnChanges {
  @ViewChild('search') searchElement!: ElementRef;
  @ViewChild(MatSelect) matSelect!: MatSelect;

  private readonly DEBOUNCE_TIME_MS = 300;
  private readonly subscription = new Subscription();

  selectedUser!: UserModel | null | undefined;
  searchText: string = '';
  isTouched: boolean = false;
  isInvalid: boolean = false;
  isDisabled: boolean = false;

  readonly searchTermSubject = new Subject<string>();
  readonly searchTerm$ = this.searchTermSubject.asObservable();

  readonly setValueSubject = new Subject<string>();
  readonly setValue$ = this.setValueSubject.asObservable();

  users: UserModel[] = [];

  @Input() userId?: string | null;
  @Input() placeholder!: string;
  @Input() set ngrxFormControlState(state: FormControlState<any>) {
    this.isTouched = state.isTouched;
    this.isInvalid = state.isInvalid;
    this.isDisabled = state.isDisabled;
  }

  @Output()
  selectionChanged = new EventEmitter<User | null>();

  private onTouchedEmitter: Function | undefined;

  constructor(
    private graphService: GraphService,
    private userDataService: UserDataService
  ) {
    this.subscription.add(
      this.searchTerm$
        .pipe(
          debounceTime(this.DEBOUNCE_TIME_MS),
          map((searchTerm) => encodeURIComponent(searchTerm.trim())),
          map((searchTerm) => searchTerm.replace(/%20/g, ' ')), // need spaces for graph api format
          switchMap((searchTerm) => this.graphService.searchUsers(searchTerm))
        )
        .subscribe(
          (result) =>
            (this.users = result.map((x) => ({
              profile: x,
              photo: x?.id
                ? this.graphService.getUserPhotoByOid(
                    x.id,
                    Constants.USER_PHOTO_MINIATURE_SIZE
                  )
                : null,
            })))
        )
    );
    this.subscription.add(
      this.setValue$
        .pipe(
          switchMap((value) =>
            this.userDataService
              .getUserByOid(value)
              .pipe(map((user) => ({ user, value })))
          )
        )
        .subscribe(({ value, user }) => {
          this.selectedUser = user
            ? {
                profile: {
                  ...user,
                  id: user.oid,
                },
                photo: user?.oid
                  ? this.graphService.getUserPhotoByOid(
                      user.oid,
                      Constants.USER_PHOTO_MINIATURE_SIZE
                    )
                  : null,
              }
            : {
                profile: { displayName: value, id: value },
                photo: null,
              };
          this.users = [this.selectedUser];
        })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.userId) {
      if (this.userId) {
        this.setValueSubject.next(this.userId);
      } else {
        this.clearSearch(false);
      }
    }
  }

  onKeyUp() {
    this.searchTermSubject.next(this.searchText);
  }

  onClose() {
    this.searchText = '';
    if (this.selectedUser == null) {
      this.searchTermSubject.next(this.searchText);
    } else {
      this.users = [this.selectedUser];
    }
    this.onTouched();
  }
  onSelectionChanged(value: string) {
    this.searchText = '';
    this.selectedUser = this.users.find((x) => x.profile?.id === value);
    this.selectionChanged.emit(this.selectedUser?.profile);
  }

  clearSearch(emitSelectionChanged = true) {
    this.searchText = '';
    this.selectedUser = null;
    this.users = [];
    if (emitSelectionChanged) {
      this.selectionChanged.emit(this.selectedUser);
    }
  }

  registerOnTouched(fn: () => {}) {
    this.onTouchedEmitter = fn;
  }

  onTouched() {
    this.onTouchedEmitter?.();
  }
}
