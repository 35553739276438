import { ValidationErrors } from '@angular/forms';

/**
 * The default pattern validator in ngx store forms
 * does not include form fields that have the number type.
 *
 * This validator takes care of this case
 * @param pattern
 * @param message
 * @returns
 */
export const patternForNumbers: (
  pattern: string | RegExp,
  message?: string
) => <T extends number>(inputNumber: T | null) => ValidationErrors =
  (pattern: string | RegExp, message?: string) =>
  <T extends number>(inputNumber: T | null) => {
    const errors: ValidationErrors = {};
    let regex: RegExp;
    let defaultMessage: string;
    if (typeof pattern === 'string') {
      regex = new RegExp(pattern);
      defaultMessage = `Pattern ${pattern} is not matched by value: ${inputNumber}`;
    } else {
      regex = pattern;
      defaultMessage = `Pattern ${pattern.source} is not matched by value: ${inputNumber}`;
    }

    if (!regex.test(inputNumber?.toString() ?? '')) {
      errors[patternForNumbersError] =
        message === undefined ? defaultMessage : message;
    }
    return errors;
  };

const patternForNumbersError = 'patternForNumbers';

// Add the available patterns here
export const NonNegativeFloat2DecimalsOrIntegerPattern =
  '^(?![-]+$)\\d+(\\.\\d{1,2})?$';
const NonNegativeFloat2DecimalsOrIntegerMessage =
  'must be a number greater or equal than 0 with up to 2 decimals';

export function NonNegativeFloat2DecimalsOrInteger() {
  return patternForNumbers(
    NonNegativeFloat2DecimalsOrIntegerPattern,
    NonNegativeFloat2DecimalsOrIntegerMessage
  );
}
