import { createAction, props } from '@ngrx/store';
import {
  BusinessUnitViewModel,
  ProjectViewModel,
} from 'src/app/shared/models/autogenerated';

export const createNewProject = createAction('[NewProject] Create');

export const createNewWorkplanSuccess = createAction(
  '[NewProject] Create Success',
  props<{ project: ProjectViewModel | null }>()
);

export const createNewWorkplanForProject = createAction(
  '[NewProject] Create for Project'
);

export const createNewWorkplanForProjectSuccess = createAction(
  '[NewProject] Create for Project Success',
  props<{ project: ProjectViewModel | null }>()
);

export const clearNewWorkplan = createAction('[NewProject] Clear');

export const loadProjectToNewProjectDialog = createAction(
  '[NewProject] Load Project to use in the new project dialog',
  props<{ projectId: string }>()
);

export const setEditProjectToNewProjectDialog = createAction(
  '[NewProject] Set the edit project to use in the new project dialog',
  props<{ project: ProjectViewModel | null }>()
);

export const setNewProjectAvailablePhases = createAction(
  '[NewProject] Set available phases'
);

export const setNewProjectDALead = createAction(
  '[NewProject] Set the DA Lead',
  props<{ daLead: string | null }>()
);

export const loadBusinessUnits = createAction(
  '[NewProject] Load Business Units'
);

export const setBusinessUnits = createAction(
  '[NewProject] Load Business Units Success',
  props<{ businessUnits: BusinessUnitViewModel[] | null }>()
);

export const loadNewProjectAllPhases = createAction(
  '[NewProject] Load All Phases'
);

export const setNewProjectAllPhases = createAction(
  '[NewProject] Load All Phases Success',
  props<{ allPhases: string[] }>()
);

export const setCurrentPhaseId = createAction(
  '[NewProject] Set Phase ID',
  props<{ phaseId: string }>()
);

export const updateProjectType = createAction(
  '[NewProject] Update Project Type',
  props<{ onShore: boolean; offShore: boolean }>()
);
