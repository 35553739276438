import { Pipe, PipeTransform } from '@angular/core';
import { getDateTimeWithoutTimeOffset } from '../utils/date-utils';
import { getRelativeTime } from '../utils/relative-time-utils';

@Pipe({
  name: 'timeSince',
})
export class TimeSincePipe implements PipeTransform {
  transform(value?: string): string {
    if (value == null) {
      return '';
    }
    const date = new Date(value);
    const dateWithoutOffset = getDateTimeWithoutTimeOffset(date);
    return getRelativeTime(dateWithoutOffset);
  }
}
