import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  BehaviorSubject,
  combineLatest,
  merge,
  Observable,
  Subject,
} from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AppState } from 'src/app/app.state';
import { DeliverableManagerComponent } from 'src/app/roles/da-engineer/screening/pages/deliverable-manager/deliverable-manager.component';
import {
  selectFilterMode,
  selectFilterTags,
  selectFilterText,
} from 'src/app/shared/ag-grid-config/store/ag-grid-config/ag-grid-config.selectors';
import { QUICKFILTER_INPUT_PLACEHOLDER } from 'src/app/shared/utils/constants';
import {
  QuickFilterMode,
  QuickFilterModes,
} from '../../ag-grid-config.constants';
import {
  quickFilterChange,
  quickFilterModeChange,
  quickFilterTagsChange,
} from '../../store/ag-grid-config/ag-grid-config.actions';
@Component({
  selector: 'app-grid-quick-filter',
  templateUrl: './grid-quick-filter.component.html',
  styleUrls: ['grid-quick-filter.component.scss'],
})
export class GridQuickFilterComponent implements OnInit {
  @Output() filterChanged = new EventEmitter<string>();
  @Output() filterTagsChanged = new EventEmitter<Set<string>>();
  isReadOnly: boolean = false;

  @Input() configStoreTableName!: string;
  @Input() configStoreRouteIdName: string | undefined;
  @Input() placeholder: string = QUICKFILTER_INPUT_PLACEHOLDER;
  @Input() tagsMode: boolean = false;

  quickFilterText$!: Observable<string | undefined>;
  quickFilterTags$!: Observable<Set<string>>;
  quickFilterMode$!: Observable<QuickFilterMode>;
  QuickFilterModes = QuickFilterModes;

  private setQuickFilterText$!: Subject<string>;
  private setQuickFilterTags$!: Subject<Set<string>>;

  public constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.quickFilterMode$ = this.store.select(
      selectFilterMode(
        this.configStoreTableName,
        this.configStoreRouteIdName ?? ''
      )
    );
    this.setQuickFilterText$ = new BehaviorSubject<string>('');
    this.setQuickFilterTags$ = new Subject<Set<string>>();

    this.quickFilterText$ = combineLatest([
      this.setQuickFilterText$,
      this.store.select(
        selectFilterText(
          this.configStoreTableName,
          this.configStoreRouteIdName ?? ''
        )
      ),
    ]).pipe(
      map(([text, storeVal]) => {
        if (this.isReadOnly) {
          return text;
        }

        return storeVal == null ? text : storeVal;
      })
    );

    this.quickFilterTags$ = merge(
      this.store
        .select(
          selectFilterTags(
            this.configStoreTableName,
            this.configStoreRouteIdName ?? ''
          )
        )
        .pipe(map((x) => new Set<string>(x))),
      this.setQuickFilterTags$
    ).pipe(startWith(new Set<string>()));
  }

  setFilterText(text: string) {
    this.checkIfReadOnly(text);
    this.setQuickFilterText$.next(text);
  }

  setFilterTags(tags: Set<string>) {
    this.setQuickFilterTags$.next(tags);
  }

  onQuickFilterChanged(filterText: string) {
    this.setFilterText(filterText);
    this.filterChanged.next(filterText);

    this.store.dispatch(
      quickFilterChange({
        tableName: this.configStoreTableName,
        routeIdName: this.configStoreRouteIdName ?? '',
        quickFilterText: filterText,
      })
    );
  }

  onQuickFilterTagsChanged(tags: Set<string>) {
    this.setFilterTags(tags);
    this.filterTagsChanged.next(tags);

    this.store.dispatch(
      quickFilterTagsChange({
        tableName: this.configStoreTableName,
        routeIdName: this.configStoreRouteIdName ?? '',
        quickFilterTags: [...tags],
      })
    );
  }

  toggleFilterMode(mode: QuickFilterMode) {
    const quickFilterMode =
      mode === QuickFilterModes.text
        ? QuickFilterModes.tags
        : QuickFilterModes.text;
    this.store.dispatch(
      quickFilterModeChange({
        tableName: this.configStoreTableName,
        routeIdName: this.configStoreRouteIdName ?? '',
        quickFilterMode,
      })
    );
  }

  private checkIfReadOnly(filterText: string) {
    this.isReadOnly =
      filterText ===
      DeliverableManagerComponent.SHOWING_ONLY_MAPPED_DELIVERABLES;
    if (this.isReadOnly) {
      this.toggleFilterMode(QuickFilterModes.tags);
    }
  }
}
