<div class="line">
  <div
    [routerLinkActive]="navigationLink.routerLink != null ? 'route-active' : ''"
    class="line-text"
  >
    <a
      *ngIf="navigationLink.url; else linkRouter"
      [ngClass]="{
        disabled: navigationLink.disabled
      }"
      href="{{ navigationLink.url }}"
      rel="noopener"
      target="_blank"
      title="{{
        (navigationLink.disabled
          ? navigationLink.disabledTooltip
          : navigationLink.tooltip) ?? navigationLink.title
      }}"
    >
      {{ navigationLink.title }}
    </a>

    <ng-template #linkRouter>
      <a
        [ngClass]="{
          disabled: navigationLink.routerLink == null || navigationLink.disabled
        }"
        [routerLink]="navigationLink.routerLink"
        title="{{
          (navigationLink.disabled
            ? navigationLink.disabledTooltip
            : navigationLink.tooltip) ?? navigationLink.title
        }}"
      >
        {{ navigationLink.title }}
      </a>
    </ng-template>

    <mat-icon
      *ngIf="navigationLink.isReadOnly"
      class="read-only-icon"
      matTooltip="read-only"
      matTooltipPosition="above"
      matTooltipShowDelay="1000"
    >
      edit_off
    </mat-icon>
    <!-- hidden <a> tags with children links are used so that the wrapping div routerLinkActive can detect if any child is active -->
    <ng-container *ngFor="let child of navigationLink.children">
      <a
        *ngIf="child.routerLink != null && !navigationLink.disabled"
        class="hidden-link"
        [routerLink]="child.routerLink"
      ></a>
      <ng-container *ngFor="let other of child.otherActiveRouteLinks">
        <a class="hidden-link" [routerLink]="other"></a>
      </ng-container>
    </ng-container>
    <ng-container *ngFor="let other of navigationLink.otherActiveRouteLinks">
      <a class="hidden-link" [routerLink]="other"></a>
    </ng-container>
  </div>
  <span class="toggle" *ngIf="navigationLink.children != null">
    <mat-icon *ngIf="collapsed" (click)="collapsed = false">add</mat-icon>
    <mat-icon *ngIf="!collapsed" (click)="collapsed = true">remove</mat-icon>
  </span>
</div>
<div @skipInitial class="sub-nav">
  <app-navigation-tree
    *ngIf="navigationLink.children && !collapsed"
    @fadeSlideInOut
    [entries]="navigationLink.children"
  ></app-navigation-tree>
</div>
