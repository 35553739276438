import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.state';
import { selectCurrentPhase } from 'src/app/roles/da-engineer/project-dashboard/store/project.selectors';
import { DeliverableManagerGridCellActionsModel } from '../../../../../shared/models/deliverable-manager-grid-cell-actions.model';

@Component({
  selector: 'app-deliverable-manager-mapping-renderer',
  templateUrl: './deliverable-manager-mapping-renderer.component.html',
  styleUrls: ['./deliverable-manager-mapping-renderer.component.scss'],
})
export class DeliverableManagerMappingRendererComponent {
  totalMappings: number = 0;
  params?: DeliverableManagerGridCellActionsModel;
  currentPhase$ = this.store.select(selectCurrentPhase);

  constructor(private store: Store<AppState>) {}

  // gets called once before the renderer is used
  agInit(params: DeliverableManagerGridCellActionsModel): void {
    this.totalMappings = params.value;
    this.params = params;
  }

  deleteMappings() {
    this.params?.onDeleteClick(this.params.data.id!);
  }

  showMappings() {
    this.params?.onMappingClick(this.params.data);
  }
}
