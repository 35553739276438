import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { NgrxFormsModule } from 'ngrx-forms';
import {
  BusinessUnitDAEngineerClient,
  ProjectDAEngineerClient,
  ProjectPhaseTemplateDAEngineerClient,
  WorkplanDAEngineerClient,
} from '../../../shared/models/autogenerated';
import { SharedModule } from '../../../shared/shared.module';
import { NewProjectDialogEntryComponent } from './components/new-project-dialog-entry/new-project-dialog-entry.component';
import { NewWorkplanRoutingModule } from './new-workplan-routing.module';
import { NewProjectContactsComponent } from './pages/new-project-contacts/new-project-contacts.component';
import { NewProjectOverviewComponent } from './pages/new-project-overview/new-project-overview.component';
import { NewProjectComponent } from './pages/new-project/new-project.component';
import { NewWorkplanEffects } from './store/new-project.effects';
import { newWorkplanReducer as newProjectReducer } from './store/new-project.reducer';
import { IPIMSModule } from '../../_shared-by-role/ipims/ipims.module';

@NgModule({
  declarations: [
    NewProjectComponent,
    NewProjectDialogEntryComponent,
    NewProjectOverviewComponent,
    NewProjectContactsComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    NewWorkplanRoutingModule,
    MatDatepickerModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    MatSelectModule,
    MatCheckboxModule,
    MatTooltipModule,
    SharedModule,
    FormsModule,
    NgrxFormsModule,
    IPIMSModule,
    StoreModule.forFeature('newProject', newProjectReducer),
    EffectsModule.forFeature([NewWorkplanEffects]),
  ],
  exports: [NewProjectComponent, NewProjectDialogEntryComponent],
  providers: [
    WorkplanDAEngineerClient,
    BusinessUnitDAEngineerClient,
    ProjectDAEngineerClient,
    ProjectPhaseTemplateDAEngineerClient,
  ],
})
export class NewWorkplanFlowModule {}
