import {
  Directive,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { skip } from 'rxjs/operators';
import { FullscreenManagerService } from '../services/fullscreen-manager.service';

@Directive({
  selector: '[fullscreenWrapper]',
})
export class FullscreenWrapperDirective implements OnInit, OnDestroy {
  // set this when using multiple toggles in the same view
  @Input() fullScreenId: string = '';
  @Input() defaultFullScreen: boolean = false;
  private subscription = new Subscription();
  constructor(private fullscreenManagerService: FullscreenManagerService) {}

  async ngOnInit(): Promise<void> {
    this.isFullscreen = this.defaultFullScreen;
    const obs = await this.fullscreenManagerService.subscribeToFullScreen(
      this.fullScreenId,
      this.defaultFullScreen
    );
    this.subscription.add(
      obs.pipe(skip(1)).subscribe((state) => {
        this.isFullscreen = state.isFullScreen;
        this.initial = false;
      })
    );
  }

  ngOnDestroy(): void {
    this.fullscreenManagerService.setFullscreen(this.fullScreenId, false, true);
    this.subscription.unsubscribe();
  }

  @HostBinding('class.fullscreen-wrapper') isFullscreen!: boolean;
  @HostBinding('class.fullscreen-initial') initial = true;
}
