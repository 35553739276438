<span>{{ text }} </span>
<ng-container
  *ngIf="{ value: formDialogData?.currentValue$ | async } as currentValue"
>
  <div class="editable-value-container">
    <span class="value">{{ currentValue.value }}</span>
    <button
      class="adap-btn-blank edit-button"
      [matTooltip]="tooltip"
      matTooltipPosition="above"
      (click)="openEditDialog()"
      [disabled]="disabled"
    >
      <mat-icon>edit</mat-icon>
    </button>
  </div>
</ng-container>
