<label>
  <input
    [disabled]="disabled"
    type="checkbox"
    [(ngModel)]="value"
    (ngModelChange)="valueChange.emit($event)"
  />
  <span class="slider"></span>
</label>
<span class="label-wrapper">
  <span [ngClass]="{ visible: onLabel != null && value === true }">{{
    onLabel
  }}</span>
  <span [ngClass]="{ visible: offLabel != null && value === false }">{{
    offLabel
  }}</span>
</span>
