<mat-dialog-content class="adap-dialog-content">
  <app-dialog-header title="load iPIMS document"></app-dialog-header>
  <app-ipims-documents-load-common
    [selection]="selection"
    [rows]="dataSource$ | async"
    [checkboxActions]="checkboxActions"
    [ipimsEventsService]="ipimsEventsService"
    [searchSpinner]="searchSpinner$ | async"
    [resultLimit]="resultLimit"
    [resultsCount]="resultsCount"
    [IPIMSDomain]="config.ipimsDomain"
    (rowsRendered)="onRowsRendered()"
    (onSearch)="search($event)"
    (onClearSelection)="clearSelection()"
  ></app-ipims-documents-load-common>

  <div class="dialog-actions">
    <button class="adap-btn-outline" type="button" (click)="closeWindow()">
      cancel
    </button>
    <button
      *ngIf="loadEnabled$ | async as enabledObject"
      class="adap-btn-filled"
      type="button"
      [disabled]="!enabledObject.enabled"
      (click)="load()"
    >
      load
    </button>
  </div>
</mat-dialog-content>
