<h3>quick links</h3>
<ul>
  <li>
    <a href="https://inside.chevron.com">inside home</a>
</li>
  <li>
    <a href="https://identitymgmt.chevron.net/Users/Search">people finder</a>
  </li>
  <li>
    <a href="https://go.chevron.com/chevronpedia">chevronpedia</a>
  </li>
</ul>
<app-separator></app-separator>
<ul>
  <li>
    <a
      href="https://www.timeanddate.com/worldclock/"
      target="_blank"
      rel="noopener noreferrer"
    >
      world clock
    </a>
  </li>
  <li>
    <a
      href="https://www.wunderground.com/"
      target="_blank"
      rel="noopener noreferrer"
    >
      weather
    </a>
  </li>
  <li>
    <a
      href="https://www.chevron.com/"
      target="_blank"
      rel="noopener noreferrer"
    >
      chevron.com
    </a>
  </li>
</ul>
