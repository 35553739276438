import { Injectable } from '@angular/core';
import {
  ColDef,
  Column,
  CsvExportParams,
  GridApi,
  ProcessCellForExportParams,
  ProcessHeaderForExportParams,
} from 'ag-grid-community';
import {
  isExportableColumnCell,
  isExportableColumnHeader,
  isNotExportable,
} from '../utils/ag-grid-column-utils';

@Injectable()
export class AgGridCsvExportService {
  getExporterFunction(
    gridApi: GridApi,
    separator?: string
  ): (
    columns: Column[],
    exportParams: CsvExportParams,
    fileName?: string
  ) => void {
    return (
      columns: Column[],
      exportParams: CsvExportParams,
      fileName?: string
    ) => {
      const columnDefs = columns
        ?.filter((col) => col.isVisible())
        .map((col) => col.getColDef());

      const columnKeysWithField = columnDefs
        .map((x) => x.field)
        .filter((x) => x !== undefined);
      const exportableColumnKeys = columnDefs
        .filter((x) => x.field === undefined)
        .filter((x) => {
          if (isExportableColumnHeader(x) || isExportableColumnCell(x)) {
            return true;
          }

          if (isNotExportable(x)) {
            return false;
          } else {
            return true;
          }
        })
        .map((x) => x.colId);

      const exportParamsWithCallback = {
        ...exportParams,
        columnKeys: [...columnKeysWithField, ...exportableColumnKeys],
        fileName: fileName ? fileName : exportParams.fileName,
        columnSeparator: separator ? separator : exportParams.columnSeparator,
        processCellCallback: this.csvCellExportCallback(columnDefs).bind(this),
        processHeaderCallback:
          this.csvHeaderExportCallback(columnDefs).bind(this),
      } as CsvExportParams;

      // call the export
      gridApi.exportDataAsCsv(exportParamsWithCallback);
    };
  }

  private csvCellExportCallback(
    colDefs: ColDef[]
  ): (params: ProcessCellForExportParams) => string {
    return (params: ProcessCellForExportParams) => {
      const matchingColDef = colDefs.find(
        (x) => x.colId === params.column.getColId()
      );
      if (params?.value === undefined) {
        return '';
      }

      if (
        isExportableColumnCell(matchingColDef!) &&
        typeof params.value !== 'string'
      ) {
        return matchingColDef?.exportValueGetter?.(params.value);
      }

      return params.value;
    };
  }

  private csvHeaderExportCallback(
    colDefs: ColDef[]
  ): (params: ProcessHeaderForExportParams) => string {
    return (params: ProcessHeaderForExportParams) => {
      const matchingColDef = colDefs.find(
        (x) => x.colId === params.column.getColId()
      );

      if (isExportableColumnHeader(matchingColDef!)) {
        return matchingColDef?.columnExportHeader ?? '';
      }

      return params?.column.getColDef()?.headerName ?? '';
    };
  }
}
