import { Component, EventEmitter, Input, Output } from '@angular/core';
import { User } from '@microsoft/microsoft-graph-types';

@Component({
  selector: 'app-multi-users-search',
  templateUrl: './multi-users-search.component.html',
  styleUrls: ['./multi-users-search.component.scss'],
})
export class MultiUsersSearchComponent {
  selectedUsersIds: (string | null)[] = [];

  @Input() label!: string;
  @Output()
  selectionChanged = new EventEmitter<(string | null)[]>();

  private readonly multiplierUsers = 2;

  constructor() {
    this.addMultiplierUsers(this.multiplierUsers);
  }

  onSelectionChanged(user: User | null, index: number) {
    const updatedArray = [...this.selectedUsersIds];
    updatedArray[index] = user?.id ?? null;
    this.selectedUsersIds = updatedArray;
    this.checkUsersFields();
    this.selectionChanged.emit(this.selectedUsersIds);
  }

  trackUserId(index: number) {
    return index;
  }

  setValue(value: (string | null)[]) {
    this.selectedUsersIds = [...value];
    this.checkUsersFields();
  }

  private checkUsersFields() {
    const totalEmptyFields = this.selectedUsersIds.filter(
      (userId) => userId == null
    );
    if (totalEmptyFields.length === 0) {
      const totalUsersToAdd =
        this.multiplierUsers -
        (this.selectedUsersIds.length % this.multiplierUsers);
      this.addMultiplierUsers(totalUsersToAdd);
    }
  }

  private addMultiplierUsers(numberOfUsers: number) {
    this.selectedUsersIds = [
      ...this.selectedUsersIds,
      ...Array(numberOfUsers).fill(null),
    ];
  }
}
