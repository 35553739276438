<ng-container *ngIf="icon">
  <img title="{{ title }}" [src]="icon" alt="{{ title }}" />
</ng-container>
<div class="title">{{ title }}</div>
<div class="description">{{ description }}</div>
<ng-content></ng-content>
<ng-container *ngIf="buttonCaption">
  <button
    [disabled]="buttonDisabled"
    class="adap-btn-filled"
    (click)="buttonClick.emit()"
  >
    {{ buttonCaption }}
  </button>
</ng-container>
