<div class="container" (click)="$event.stopPropagation()">
  <div class="options">
    <mat-accordion>
      <mat-expansion-panel *ngFor="let group of filterGroups">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ group.title }}
            {{
              groupsFiltersSelectedCount?.get(group.title)
                ? "(" + groupsFiltersSelectedCount!.get(group.title) + ")"
                : ""
            }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div *ngFor="let option of group.options">
          <mat-checkbox
            color="primary"
            [ngModel]="option.selected"
            (change)="filterOptionToggle(option)"
          >
            {{ option.title }}
          </mat-checkbox>
        </div>
        <div class="no-options" *ngIf="group.options.length === 0">
          no options available
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  <div class="actions">
    <button
      class="adap-btn-outline adap-btn-small"
      type="button"
      (click)="clearFilter()"
    >
      clear all
    </button>
    <button
      class="adap-btn-filled adap-btn-small"
      type="button"
      [disabled]="filtersSelectedCount === 0"
      (click)="applyFiltersClick()"
    >
      apply {{ filtersSelectedCount }}
      {{ "filter" | Plural : filtersSelectedCount }}
    </button>
  </div>
</div>
