import { CurrentPhaseSubscriptionWatchConfiguration } from '../_shared-by-role/project-dashboard/models/current-phase-subscription-watch-configuration.model';
import { selectCurrentPhase } from './project-dashboard/store/project.selectors';

export const TOTAL_COST_DATIME: readonly number[] = [
  0, 5, 10, 15, 20, 25, 30, 35, 40, 45,
];

export const CurrentPhaseSubscriptionWatchConfig: CurrentPhaseSubscriptionWatchConfiguration =
  {
    currentPhaseSelector: selectCurrentPhase,
  };

export const DEFAULT_SME_MARGIN = 100;
