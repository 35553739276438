import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-slide-toggle',
  templateUrl: './slide-toggle.component.html',
  styleUrls: ['./slide-toggle.component.scss'],
})
export class SlideToggleComponent {
  @Input() value!: boolean;
  @Input() disabled!: boolean;
  @Output() valueChange = new EventEmitter<boolean>();
  @Input() onLabel?: string;
  @Input() offLabel?: string;
}
